import { BEGIN_LOG_IN, LOG_IN, LOG_OUT, GAME_LOGOUT } from "./types"
import { ADMIN, GAME } from "../constants/general"
import { setAuthData, removeAuthData, getAuthData, removeGameData } from '../utils'
import axios from 'axios'

export const login = (username, password) => dispatch => {
    dispatch({ type: BEGIN_LOG_IN });
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/authorization`, { username, password })
        .then(res => {
            //set auth token in local storage
            setAuthData(res.data)
            //dispatch login
            dispatch({
                type: LOG_IN,
                payload: res.data,
            })
        })
        .catch(err => {
            removeAuthData()
            dispatch({
                type: LOG_IN,
                payload: {
                    username: username,
                    error: 'Invalid login attempt'
                }
            })
        })
}

export const setUserData = () => dispatch => {
    const authData = getAuthData()
    if (!authData) {
        dispatch({
            type: LOG_OUT
        })
    } else {
        dispatch({
            type: LOG_IN,
            payload: authData
        })
    }
}

export const logOut = (userType = ADMIN) => dispatch => {
    if (userType === ADMIN) {
        removeAuthData()
        dispatch({
            type: LOG_OUT
        })
    }
    if (userType === GAME) {
        removeGameData()
        dispatch({
            type: GAME_LOGOUT
        })
    }
}

export const changePassword = (oldPassword, newPassword) => dispatch => {
    
}