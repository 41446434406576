import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Hashtag = (props) => {
  const {
    fill = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30.628}
      height={30.628}
      viewBox="0 0 30.628 30.628"
      className={className}
      {...others}
    >
      <path
        data-name="Icon awesome-hashtag"
        d="M30.127 12.512l.488-2.735a.82.82 0 00-.808-.965h-5.114l1-5.6a.82.82 0 00-.808-.965h-2.778a.82.82 0 00-.808.676l-1.051 5.89H13.5l1-5.6a.82.82 0 00-.8-.963h-2.781a.82.82 0 00-.808.676L9.06 8.813H3.653a.82.82 0 00-.808.676l-.488 2.735a.82.82 0 00.808.965h5.114l-1.563 8.75H1.309a.82.82 0 00-.808.676L.013 25.35a.82.82 0 00.808.965h5.114l-1 5.6a.82.82 0 00.808.965h2.778a.82.82 0 00.808-.676l1.051-5.887h6.743l-1 5.6a.82.82 0 00.808.965h2.778a.82.82 0 00.808-.676l1.051-5.887h5.407a.82.82 0 00.808-.676l.488-2.735a.82.82 0 00-.808-.965h-5.114l1.563-8.751h5.407a.82.82 0 00.808-.676zM17.9 21.939h-6.739l1.563-8.751h6.743z"
        transform="translate(0 -2.25)"
        fill={fill}
      />
    </svg>
  )
}

Hashtag.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
}

export default Hashtag