import {
    DEFAULT_BACKGROUND_COLOR_CODE,
    DEFAULT_PRIMARY_COLOR_CODE,
    DEFAULT_SECONDARY_COLOR_CODE,
    DEFAULT_TEXT_COLOR_CODE,
    DEFAULT_SQUARE_COLOR_CODE,
    DARK_BACKGROUND_COLOR_CODE,
    DARK_PRIMARY_COLOR_CODE,
    DARK_SECONDARY_COLOR_CODE,
    DARK_TEXT_COLOR_CODE,
    DARK_SQUARE_COLOR_CODE
} from '../constants/general'

export const getAuthToken = () => {
    const authData = getAuthData()
    if (authData) {
        return authData.token
    }
    return null
}

export const getAuthData = () => {
    const data = localStorage.getItem('authData')
    if (data) {
        return JSON.parse(data)
    }
    return null
}

export const setAuthData = (data) => {
    return localStorage.setItem('authData', JSON.stringify(data))
}

export const removeAuthData = () => {
    return localStorage.removeItem('authData')
}

export const removeGameData = () => {
    localStorage.removeItem('lastUsername')
    localStorage.removeItem('lastGameCode')

    return null
}


export const setLocalStorageTheme = theme => {
    return localStorage.setItem('theme', JSON.stringify(theme))
}

export const getLocalStorageTheme = () => {
    const themeText = localStorage.getItem('theme')
    if (themeText) {
        return JSON.parse(themeText)
    }
    return null
}

export const getDefaultTheme = () => {
    return {
        name: 'default',
        primaryColor: DEFAULT_PRIMARY_COLOR_CODE,
        secondaryColor: DEFAULT_SECONDARY_COLOR_CODE,
        infoColor: DEFAULT_TEXT_COLOR_CODE,
        backgroundColor: DEFAULT_BACKGROUND_COLOR_CODE,
        squareColor: DEFAULT_SQUARE_COLOR_CODE,
    }
}

export const getDarkTheme = () => {
    return {
        name: 'dark',
        primaryColor: DARK_PRIMARY_COLOR_CODE,
        secondaryColor: DARK_SECONDARY_COLOR_CODE,
        infoColor: DARK_TEXT_COLOR_CODE,
        backgroundColor: DARK_BACKGROUND_COLOR_CODE,
        squareColor: DARK_SQUARE_COLOR_CODE,
    }
}

export const updateHeadMetas = data => {
    if (data && data.name && data.name.length > 0) {
        const name = data.name.charAt(0).toUpperCase() + data.name.slice(1)
        document.querySelector('meta[name="description"]').setAttribute("content", `${name} games. Enjoy!`)
        document.title = name
    } else {
        document.querySelector('meta[name="description"]').setAttribute("content", 'Chambr games. Enjoy!')
        document.title = "Chambr"
    }

    if (data && data.iconData && data.iconData.length > 0) {
        document.querySelector("link[rel~='icon']").href = `data:image/png;base64, ${data.iconData}`
    } else {
        document.querySelector("link[rel~='icon']").href = `${process.env.PUBLIC_URL}/favicon.ico`
    }

    return null
}