import * as actionTypes from '../actions/types';

const initialState = {
    users: [],
    isLoading: false,
    error: '',
}

export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BEGIN_SET_USERS: {
            return {
                ...state,
                users: [],
                isLoading: true,
                error: ''
            }
        }
        case actionTypes.SET_USERS: {
            return {
                ...state,
                users: action.payload.map(d => {
                    return {
                        ...d,
                        id: d.email
                    }
                }),
                isLoading: false,
                error: ''
            }
        }
        case actionTypes.ERROR_GET_USERS: {
            return {
                ...state,
                users: [],
                isLoading: false,
                error: action.payload
            }
        }
        case actionTypes.BEGIN_CREATE_USER: {
            return {
                ...state,
                isLoading: true,
                error: ''
            }
        }
        case actionTypes.CREATE_USER: {
            return {
                ...state,
                users: [{ ...action.payload, id: action.payload.email }, ...state.users],
                isLoading: false,
                error: ''
            }
        }
        case actionTypes.ERROR_CREATE_USER: {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        }
        case actionTypes.BEGIN_DELETE_USER: {
            return {
                ...state,
                isLoading: true,
                error: ''
            }
        }
        case actionTypes.DELETE_USER: {
            return {
                ...state,
                users: state.users.filter(d => d.email !== action.meta),
                isLoading: false,
                error: ''
            }
        }
        case actionTypes.ERROR_DELETE_USER: {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        }
        default:
            return state
    }
}