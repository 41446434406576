import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from "@material-ui/styles"

import Avatar1 from "../Svg/Avatar1"
import Avatar2 from "../Svg/Avatar2"
import Avatar3 from "../Svg/Avatar3"
import Avatar4 from "../Svg/Avatar4"
import Avatar5 from "../Svg/Avatar5"
import Avatar6 from "../Svg/Avatar6"
import Avatar7 from "../Svg/Avatar7"
import Avatar8 from "../Svg/Avatar8"
import Avatar9 from "../Svg/Avatar9"
import Avatar10 from "../Svg/Avatar10"

const PlayerIcon = ({
  index = 0,
  className = ""
}) => {
  const theme = useTheme()

  const avatars = [
    <Avatar1
      fill={theme.palette.primary.main}
      stroke={theme.palette.secondary.main}
      className={className}
    />,
    <Avatar2
      fill={theme.palette.primary.light}
      stroke={theme.palette.secondary.main}
      className={className}
    />,
    <Avatar3
      fill={theme.palette.primary.light}
      stroke={theme.palette.secondary.main}
      className={className}
    />,
    <Avatar4
      fill={theme.palette.primary.dark}
      stroke={theme.palette.secondary.main}
      className={className}
    />,
    <Avatar5
      fillLight={theme.palette.primary.light}
      fillDark={theme.palette.secondary.main}
      stroke={theme.palette.secondary.main}
      className={className}
    />,
    <Avatar6
      fill={theme.palette.primary.light}
      stroke={theme.palette.secondary.main}
      className={className}
    />,
    <Avatar7
      fill={theme.palette.primary.dark}
      stroke={theme.palette.secondary.main}
      className={className}
    />,
    <Avatar8
      fill={theme.palette.primary.dark}
      stroke={theme.palette.secondary.main}
      className={className}
    />,
    <Avatar9
      fill={theme.palette.primary.light}
      stroke={theme.palette.secondary.main}
      className={className}
    />,
    <Avatar10
      fill={theme.palette.primary.main}
      stroke={theme.palette.secondary.main}
      className={className}
    />
  ]

  return (
    avatars.map((avatar, i) => {
      return i === index &&
        <React.Fragment key={`avatar-${i}`}>
          {avatars[index]}
        </React.Fragment>
    })
  )
}

PlayerIcon.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string
}

export default PlayerIcon
