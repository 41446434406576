import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startRound } from '../../../actions/index'
import { gsap, Back } from 'gsap'
import { makeStyles } from '@material-ui/core'
import '../../../App.css'


export default function Round() {
    const dispatch = useDispatch()
    const classes = useStyles()
    const gameState = useSelector(d => d.game)
    const isSpeaker = gameState.game.speaker.player.connectionId === gameState.player.connectionId
    const round = gameState.game.round

    const title = useRef(null)
    let titleAnimation = useRef(null)

    const screen = useRef(null)
    let screenAnimation = useRef(null)

    const number = useRef(null)
    let numberAnimation = useRef(null)

    useEffect(() => {
        if (isSpeaker) {
            setTimeout(() => {
                dispatch(startRound())
            }, 2600)
        }
    }, [dispatch, isSpeaker])

    useEffect(() => {
        screenAnimation.current = gsap.from(screen.current,
            {
                y: '-100vh',
                duration: 0.3,
            })

        numberAnimation.current = gsap.from(number.current,
            {
                scale: 0,
                duration: 0.5,
                ease: Back.easeOut.config(3),
                delay: 0.2,
            }).eventCallback('onComplete', () => {
                setTimeout(() => {
                    numberAnimation.current.reverse()
                }, 1000)

                titleAnimation.current = gsap.to(title.current,
                    {
                        scale: 0,
                        duration: 0.5,
                        ease: Back.easeOut.config(3),
                        delay: 1.2
                    }
                ).eventCallback('onComplete', () => {
                    screenAnimation.current.reverse()
                })
            })

        return () => {
            if (screenAnimation.current) {
                screenAnimation.current.kill()
            }

            if (numberAnimation.current) {
                numberAnimation.current.kill()
            }

            if (titleAnimation.current) {
                titleAnimation.current.kill()
            }
        }
    }, [])



    return (
        <div className={classes["round-page"]} ref={screen}>
            <div className={classes["round-wrapper"]}>
                <div className={classes["round-title"]} ref={title}>Round</div>
                <div className={classes["round-number"]} ref={number}>{round}</div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    'round-page': {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh'
    },
    'round-wrapper': {
        textAlign: 'center'
    },
    'round-title': {
        fontFamily: '"SegoeBlack", sans-serif',
        color: theme.palette.info.main,
        fontSize: '21px'
    },
    'round-number': {
        fontFamily: '"SegoeBlack", sans-serif',
        color: theme.palette.info.main,
        fontSize: '400px',
        lineHeight: '350px'
    }
}))