import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { joinGame } from '../../../actions/index'
import { gsap, Power1 } from 'gsap'
import { useLocation } from 'react-router-dom'
import * as microsoftTeams from "@microsoft/teams-js"
import jwtDecode from 'jwt-decode'

import { Grid, makeStyles } from '@material-ui/core'
import Layout from './Layout'
import FancyButton from '../../General/FancyButton'
import StyledInput from '../../General/StyledInput'

const URL_CODE = '?code='

const getUrlCode = (search) => {
    if (search.startsWith(URL_CODE)) {
        const token = search.slice(URL_CODE.length)
        return token
    }
    return null
}

export default function JoiningGame() {
    const dispatch = useDispatch()
    const classes = useStyles()
    const gameState = useSelector(d => d.game)
    const serverMessage = gameState.player.message
    const [user, setUser] = useState("")
    const [code, setCode] = useState("")
    const [userError, setUserError] = useState(null)
    const [codeError, setCodeError] = useState(null)
    const bottomButton = useRef(null)
    const { search } = useLocation()
    let animation = useRef(null)    

    const handleFormSubmitted = () => {
        dispatch(joinGame(user, code))
    }

    useEffect(() => {
        microsoftTeams.app.initialize().then(() => {
            microsoftTeams.authentication.getAuthToken({
                successCallback: token => {
                    const decoded = jwtDecode(token)
                    setUser(decoded.name)
                    microsoftTeams.app.notifySuccess()
                },
                failureCallback: message => {
                    setUserError(message)
                    microsoftTeams.app.notifyFailure()
                },
                resources: process.env.REACT_APP_AD_RESOURCE_URL
            })
        }).catch(() => {
            console.log("Not in teams or teams error")
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const urlCode = getUrlCode(search)
        if (urlCode) {
            setCode(urlCode)
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        animation.current = gsap.from(bottomButton.current,
            {
                y: '-50vh',
                opacity: 0,
                duration: 0.3,
                ease: Power1.easeOut,
                delay: 0.2
            })

        return () => {
            if (animation.current) {
                animation.current.kill()
            }
        }
    }, [])

    const handleUserChange = e => {
        setUserError(null)
        e.persist()
        setUser(prevState => {
            if (prevState !== e.target.value) {
                return e.target.value
            }
        })
        if (e.target.value.length === 0) {
            setUserError("Username is required")
        }
        if (e.target.value.length > 25) {
            setUserError("Username can have max 25 characters")
        }
    }

    const handleCodeChange = e => {
        setCodeError(null)
        e.persist()
        setCode(prevState => {
            if (prevState !== e.target.value) {
                return e.target.value
            }
        })
        if (e.target.value.length === 0) {
            setCodeError("Room code is required")
        }
    }

    const handleOnKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault()
            handleFormSubmitted()
        }
    }

    return (
        <Layout shouldBackgroundRotateForward={true}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes["joingame-wrapper"]}
            >
                <div className={classes["joingame-form"]}>
                    <div className={classes["joingame-title"]}>
                        Enter your chambr
                    </div>

                    <form>
                        <label className={classes["label-input"]}>
                            <StyledInput
                                onChange={handleUserChange}
                                type="text"
                                placeholder="Name"
                                defaultValue={user}
                            />
                            {userError &&
                                <span className={classes["validation-errors"]}>
                                    {userError}
                                </span>
                            }
                        </label>

                        <label className={classes["label-input"]}>
                            <StyledInput
                                onChange={handleCodeChange}
                                onKeyDown={handleOnKeyDown}
                                type="text"
                                placeholder="Room code"
                                defaultValue={code}
                            />
                            {codeError &&
                                <span className={classes["validation-errors"]}>
                                    {codeError}
                                </span>
                            }
                        </label>

                        <label>
                            {serverMessage &&
                                <>
                                    <span className={classes["validation-errors"]}>
                                        {serverMessage}
                                    </span>
                                    <br></br>
                                    <span className={classes["validation-errors"]}>
                                        For more information, please contact <a href="mailto:gabriela@chambr.games">gabriela@chambr.games</a> or visit our <a href='https://www.playlearn.games/chambr' target="_blank" rel="noopener noreferrer">website</a>
                                    </span>
                                </>
                            }
                        </label>

                        <div className={classes["joingame-button"]}>
                            <FancyButton
                                ref={bottomButton}
                                disabled={!code.length || !user.length || Boolean(userError) || Boolean(codeError)}
                                label="I'm ready"
                                width="100px"
                                height="65px"
                                onClick={handleFormSubmitted}
                            />
                        </div>
                    </form>
                </div>
            </Grid>
        </Layout>
    )
}

const useStyles = makeStyles(theme => ({
    'joingame-wrapper': {
        width: '100%',
        height: '100%',
        maxWidth: 300,
        margin: '0 auto'
    },
    'joingame-title': {
        paddingBottom: '10vh',
        paddingTop: '8vh',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center',
        fontSize: '20px'
    },
    'joingame-form': {
        textAlign: 'center',
        width: '100%',
        maxWidth: 300,
        margin: '0 auto'
    },
    'validation-errors': {
        fontSize: '12px',
        color: '#cf0000'
    },
    'label-input': {
        paddingBottom: '2vh',
        display: 'block'
    },
    'joingame-button': {
        paddingTop: '5vh'
    },
}))