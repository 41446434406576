import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles, Button } from '@material-ui/core'
import OutlinedInput from '../../General/OutlinedInput'
import AddIcon from '@material-ui/icons/Add'

export default function CardForm({
  card,
  handleSave,
  className = ""
}) {
  const classes = useStyles()
  const isAdd = card ? false : true
  const label = isAdd ? "" : card.label
  const challenge = isAdd ? "" : card.challenge
  const modifier = isAdd ? "" : card.modifier

  const [currentLabel, setCurrentLabel] = useState(label)
  const [currentChallenge, setCurrentChallenge] = useState(challenge)
  const [currentModifier, setCurrentModifier] = useState(modifier)
  const [errorChallenge, setErrorChallenge] = useState(null)

  const handleChallengeChange = e => {
    setErrorChallenge(null)
    e.persist()
    setCurrentChallenge(prevState => {
      if (prevState !== e.target.value) {
        return e.target.value
      }
    })
    if (e.target.value.length === 0) {
      setErrorChallenge("Challenge is required")
    }
  }

  const handleLabelChange = e => {
    e.persist()
    setCurrentLabel(prevState => {
      if (prevState !== e.target.value) {
        return e.target.value
      }
    })
  }

  const handleModifierChange = e => {
    e.persist()
    setCurrentModifier(prevState => {
      if (prevState !== e.target.value) {
        return e.target.value
      }
    })
  }

  const handleSubmit = e => {
    e.persist()
    const data = {
      label: currentLabel,
      challenge: currentChallenge,
      modifier: currentModifier
    }

    if (isAdd) {
      handleSave(data)
    } else {
      handleSave({
        ...data,
        id: card.id,
      })
    }

    if (isAdd) {
      setCurrentLabel("")
      setCurrentChallenge("")
      setErrorChallenge(null)
      setCurrentModifier("")
    }
  }

  return (
    <form className={`${classes["card-form"]} ${className}`}>
      <OutlinedInput
        placeholder="Title"
        type="text"
        className={classes["card-form-field"]}
        value={currentLabel || ""}
        onChange={handleLabelChange}
      />

      <OutlinedInput
        placeholder="Write your challenge"
        type="text"
        multiline
        className={`${classes["card-form-field"]} ${classes["card-textarea"]}`}
        value={currentChallenge || ""}
        onChange={handleChallengeChange}
      />

      {errorChallenge &&
        <div className={classes["validation-errors"]}>
          {errorChallenge}
        </div>
      }

      <OutlinedInput
        placeholder="Extra difficulty level"
        type="text"
        className={classes["card-form-field"]}
        value={currentModifier || ""}
        onChange={handleModifierChange}
      />

      <Button
        variant="contained"
        disableElevation
        disableRipple
        onClick={handleSubmit}
        className={classes["card-add"]}
        disabled={!currentChallenge.length}
      >
        <AddIcon />
      </Button>
    </form>
  )
}

const useStyles = makeStyles(theme => ({
  'card-form-field': {
    marginBottom: 20,
    display: 'flex',
    width: 'auto'
  },
  'card-form': {
    width: '100%',
    textAlign: 'center'
  },
  'card-textarea': {
    minHeight: 180,
    maxHeight: 180,
    height: 180,
    borderRadius: 17,
    padding: 20,
  },
  'card-add': {
    backgroundColor: theme.palette.secondary.main,
    padding: 0,
    width: 80,
    height: 80,
    borderRadius: 100,
    marginTop: 5,
    '& .MuiButton-label': {
      width: '100%',
      height: '100%'
    },
    '& svg': {
      color: '#ffffff',
      width: '85%',
      height: '85%'
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  'validation-errors': {
    fontSize: '12px',
    color: '#cf0000',
    marginBottom: 15
  },
}))

CardForm.propTypes = {
  card: PropTypes.object,
  handleSave: PropTypes.func.isRequired,
}