import React from 'react'
import { makeStyles, TextField } from '@material-ui/core'

const OutlinedInput = (props) => {
  const classes = useStyles()

  return (
    <TextField
      classes={{ root: classes.customInputRoot }}
      {...props}
    />
  )
}

const useStyles = makeStyles(theme => ({
  customInputRoot: {
    height: 36,
    textAlign: 'left',
    backgroundColor: '#ffffff',
    padding: '6px 20px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 100,
    WebkitBoxShadow: `9px 8px 0px ${theme.palette.secondary.main}`,
    MozBoxShadow: `9px 8px 0px -2px ${theme.palette.secondary.main}`,
    boxShadow: `9px 8px 0px -2px ${theme.palette.secondary.main}`,
    color: theme.palette.info.main,
    width: '100%',
    margin: '0 auto',
    fontFamily: '"SegoeSemiBold", sans-serif',
    '& ::-webkit-input-placeholder': {
      color: '#D4D4D4',
      textAlign: 'left'
    },
    '& ::-moz-placeholder': {
      color: '#D4D4D4',
      textAlign: 'left'
    },
    '& ::-ms-input-placeholder': {
      color: '#D4D4D4',
      textAlign: 'left'
    },
    '& .MuiInput-root': {
      height: '100%',
      color: theme.palette.info.main,
      fontFamily: '"SegoeSemiBold", sans-serif',
    },
    '& .MuiInputBase-multiline': {
      padding: 0,
      textAlign: 'left',
      height: '100%'
    },
    '& .MuiInputBase-inputMultiline': {
      fontFamily: '"SegoeSemiBold", sans-serif',
      color: theme.palette.info.main,
      border: 'none',
      textAlign: 'left',
      height: '100% !important',
      overflowY: 'auto !important',
      scrollbarWidth: 'none',
      '&-ms-overflow-style': 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    '& .MuiInput-underline:after': {
      border: 'none !important'
    },
    '& .MuiInput-underline:before': {
      border: 'none !important',
    }
  }
}))

export default OutlinedInput