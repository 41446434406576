import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getReplays, deleteReplay } from '../../../actions/replays'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Backdrop,
    CircularProgress
} from '@material-ui/core'
import { downloadFile } from '../../../actions/helper';


const tableStyle = {
    borderCollapse: "collapse",
    border: "1px solid #000000",
    marginTop: "10px"
}
const tableHeaderStyle = {
    border: "1px solid #000000",
    padding: "5px",
    cursor: "pointer"
}
const tableCellStyle = {
    border: "1px solid #000000",
    padding: "5px"
}

export default function ReplaysList() {
    const dispatch = useDispatch()
    const store = useSelector(d => d.replays)
    const [selectedReplayId, setSelectedReplayId] = useState(null)
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
    const [sortingColumn, setSortingColumn] = useState(null)
    const [sortingDirection, setSortingDirection] = useState(1)
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        dispatch(getReplays())
    }, [dispatch])

    const handleDeleteClick = id => {
        setSelectedReplayId(id)
        setIsDeletePopupOpen(true)
    }

    const handleDownloadClick = async replay => {
        await downloadFile(
            `${process.env.REACT_APP_SERVER_URL}/api/replays/pdf/${replay.id}`,
            `${replay.presetName} - ${replay.gameCode}.pdf`)
    }

    const handleDeletePopupClose = () => {
        setIsDeletePopupOpen(false)
    };

    const handleDeleteConfirmed = () => {
        dispatch(deleteReplay(selectedReplayId))
        setIsDeletePopupOpen(false)
        setSelectedReplayId(null)
    };

    const handleSorting = key => {
        if (sortingColumn === key) {
            setSortingDirection(sortingDirection === 1 ? -1 : 1)
        } else {
            setSortingColumn(key)
        }
    }

    // create dataSource
    let replays = store.items.map(d => {
        const utcStart = new Date(d.utcStart)
        const utcEnd = new Date(d.utcEnd)
        const diff = utcEnd - utcStart
        const seconds = Math.floor(diff / 1000)
        const minutes = Math.floor(seconds / 60)
        const hours = Math.floor(minutes / 60)
        
        return {
            ...d,
            utcStartDate: utcStart,
            utcEndDate: new Date(d.utcEnd),
            gameTime: diff,
            gameTimeText: `${hours > 0 ? hours % 24 + 'h' : ''} ${minutes > 0 ? minutes % 60 + 'm' : ''} ${seconds > 0 ? seconds % 60 + 's' : ''}`.trim(),
            hours: hours % 24,
            minutes: minutes % 60,
            seconds: seconds % 60,
        }
    });

    if (searchText) {
        const text = searchText.toLowerCase()
        replays = replays.filter(d => 
            d.gameCode.toLowerCase().includes(text) 
            || d.presetName.toLowerCase().includes(text)
            || d.gameTimeText.toLowerCase().includes(text)
            || d.utcStart.toLowerCase().includes(text));
    }

    //sorting
    if (sortingColumn) {
        switch (sortingColumn) {
            case "code":
                replays.sort((a, b) => (a.gameCode > b.gameCode) ? sortingDirection : -sortingDirection)
                break;
            case "preset":
                replays.sort((a, b) => (a.presetName > b.presetName) ? sortingDirection : -sortingDirection)
                break;
            case "time":
                replays.sort((a, b) => (a.gameTime > b.gameTime) ? sortingDirection : -sortingDirection)
                break;
            case "date":
                replays.sort((a, b) => (a.utcStartDate > b.utcStartDate) ? sortingDirection : -sortingDirection)
                break;
            default:
                break;

        }
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={store.isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="searchText"
                    label="Search"
                    type="text"
                    variant="standard"
                    autoComplete="off"
                    onChange={(e) => { setSearchText(e.target.value) }}
                />
            </div>
            {store.error && <div>
                <div>
                    <b>{store.error}</b>
                </div>
            </div>}
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={tableHeaderStyle} onClick={() => handleSorting("code")}>Code</th>
                        <th style={tableHeaderStyle} onClick={() => handleSorting("preset")}>Preset</th>
                        <th style={tableHeaderStyle} onClick={() => handleSorting("time")}>Game time</th>
                        <th style={tableHeaderStyle} onClick={() => handleSorting("date")}>Date</th>
                        <th style={tableCellStyle}>Download</th>
                        <th style={tableCellStyle}>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {replays && replays.map(item => {
                        return <tr key={item.id}>
                            <td style={tableCellStyle}>{item.gameCode}</td>
                            <td style={tableCellStyle}>{item.presetName}</td>
                            <td style={tableCellStyle}>{item.gameTimeText}</td>
                            <td style={tableCellStyle}>{item.utcStartDate.toLocaleString("ro-RO", {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                timeZone: 'UTC'
                            })}
                            </td>
                            <td style={tableCellStyle}><Button variant="contained" color="primary" onClick={e => handleDownloadClick(item)}>Download</Button></td>
                            <td style={tableCellStyle}><Button variant="contained" color="secondary" onClick={e => handleDeleteClick(item.id)}>Delete</Button></td>
                        </tr>
                    })}
                </tbody>
            </table>
            <Dialog
                open={isDeletePopupOpen}
                keepMounted
                onClose={handleDeletePopupClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Delete is forever"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this replay?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleDeletePopupClose}>NO</Button>
                    <Button onClick={handleDeleteConfirmed}>YES, DELETE!</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}