import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const ListenerIcon = (props) => {
  const {
    fillDark = DEFAULT_SECONDARY_COLOR_CODE,
    fillLight = DEFAULT_PRIMARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={146.199}
      height={131.717}
      viewBox="0 0 146.199 131.717"
      className={className}
      {...others}
    >
      <g
        id="Listener_icon"
        data-name="Listener icon"
        transform="translate(0 -27.482)"
      >
        <g id="Group_79" data-name="Group 79" transform="translate(0 27.482)">
          <path
            id="Path_384"
            data-name="Path 384"
            d="M73.071 27.482a73.415 73.415 0 00-64.2 108.393v-19.408a15.659 15.659 0 013.5-9.741q-.277-2.9-.28-5.837a61.433 61.433 0 0160.98-61.313 61.27 61.27 0 0160.687 67.774 15.511 15.511 0 013.035 9.118v20.392A73.422 73.422 0 0073.071 27.482z"
            transform="translate(0 -27.482)"
            fill={fillDark}
          />
          <path
            id="Path_385"
            data-name="Path 385"
            className="cls-2"
            d="M49.433 319.264a10.7 10.7 0 00-.473 3.134v27.412c0 8.46 10.076 15.319 22.505 15.319h9.476V307.08h-9.476c-10.851 0-19.906 5.227-22.032 12.184z"
            transform="translate(-36.06 -233.413)"
            fill={fillLight}
          />
          <path
            id="Path_386"
            data-name="Path 386"
            className="cls-2"
            d="M414.481 349.811V322.4a10.587 10.587 0 00-.188-1.949c-1.407-7.54-10.859-13.37-22.317-13.37H382.5v58.049h9.476c12.43 0 22.505-6.859 22.505-15.319z"
            transform="translate(-281.72 -233.413)"
            fill={fillLight}
          />
        </g>
      </g>
    </svg>
    //DON'T DELETE
    // BELLOW IS THE EAR ICON

    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={56.371}
    //   height={56.372}
    //   viewBox="0 0 56.371 56.372"
    //   className={className}
    //   {...others}
    // >
    //   <g id="ear" transform="translate(-.005)">
    //     <path
    //       id="Path_595"
    //       data-name="Path 595"
    //       className="cls-1"
    //       d="M19.951.086A18.168 18.168 0 004.7 30.36a6.753 6.753 0 011.793 4.2c-.148 1.057-.5 1.77-1.592 1.77H.005v8.789a11.249 11.249 0 0021.921 3.557 19.28 19.28 0 013.042-5.812l5.262-6.578a27.977 27.977 0 006.108-17.413C36.338 9.325 29.319.98 19.951.086zm7.7 34.141l-5.262 6.578a22.572 22.572 0 00-3.6 6.83 7.946 7.946 0 01-15.481-2.512v-5.486h1.6a4.9 4.9 0 004.9-5.115v-.1a10.06 10.06 0 00-2.645-6.278 14.865 14.865 0 0112.474-24.77c7.513.717 13.4 7.526 13.4 15.5a24.662 24.662 0 01-5.386 15.353z"
    //       fill={fillDark}
    //     />
    //     <path
    //       id="Path_596"
    //       data-name="Path 596"
    //       className="cls-1"
    //       d="M72.712 60.081a11.566 11.566 0 00-11.935 15.664l3.082-1.188a8.264 8.264 0 01-.551-2.983 8.086 8.086 0 01.17-1.638h4.784a4.954 4.954 0 010 9.909v3.3a8.257 8.257 0 100-16.515h-3.273a8.256 8.256 0 017.411-3.26c4.16.4 7.419 4.319 7.419 8.928a18.017 18.017 0 01-3.934 11.222l-8.124 10.157 2.58 2.063 8.123-10.157A21.331 21.331 0 0083.126 72.3c0-6.3-4.572-11.661-10.414-12.219z"
    //       transform="translate(-53.394 -53.42)"
    //       fill={fillDark}
    //     />
    //     <path
    //       id="Path_597"
    //       data-name="Path 597"
    //       className="cls-2"
    //       d="M440.407 144.731l-2.336 2.336a16.515 16.515 0 010 23.356l2.336 2.336a19.818 19.818 0 000-28.027z"
    //       transform="translate(-389.835 -128.796)"
    //       fill={fillLight}
    //     />
    //     <path
    //       id="Path_598"
    //       data-name="Path 598"
    //       className="cls-2"
    //       d="M397.944 187.2l-2.336 2.336a9.89 9.89 0 010 14.005l2.336 2.336a13.192 13.192 0 000-18.676z"
    //       transform="translate(-352.047 -166.585)"
    //       fill={fillLight}
    //     />
    //     <path
    //       id="Path_599"
    //       data-name="Path 599"
    //       className="cls-2"
    //       d="M353.2 231.934a3.3 3.3 0 010 4.668l2.336 2.336a6.6 6.6 0 000-9.339z"
    //       transform="translate(-314.312 -204.319)"
    //       fill={fillLight}
    //     />
    //   </g>
    // </svg>
  )
}

ListenerIcon.propTypes = {
  fillDark: PropTypes.string,
  fillLight: PropTypes.string,
  className: PropTypes.string
}

export default ListenerIcon
