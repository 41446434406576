import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, useTheme } from '@material-ui/core'
import Layout from './Layout'
import Coin from '../../Svg/Coin'

export default function RoundScore({
    currentPlayer = null,
    handleNext,
    score = 0
}) {
    const classes = useStyles()
    const theme = useTheme()

    useEffect(() => {
        setTimeout(() => {
            handleNext()
        }, 5000)
    }, [handleNext])

    return (
        <Layout currentPlayer={currentPlayer} shouldBackgroundRotateForward={true}>
            <div className={classes["scores-result-page"]}>
                <div>
                    <div className={classes["scores-green-title"]}>YOUR SCORE</div>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        className={classes["scores-subtitle"]}
                    >
                        <span className={classes["score-value"]}>
                            {score}
                        </span>
                        <span>
                            <Coin
                                fill={theme.palette.secondary.main}
                                fillInner={theme.palette.primary.main}
                                stroke={theme.palette.secondary.main}
                                className={classes["score-icon"]}
                            />
                            <div className={classes["score-icon-label"]}>chambr points</div>
                        </span>
                    </Grid>
                </div>
            </div>
        </Layout >
    )
}

const useStyles = makeStyles(theme => ({
    'scores-result-page': {
        display: 'flex',
        height: 'calc(100vh - 85px)',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    'scores-green-title': {
        fontFamily: '"SegoeBlack", sans-serif',
        color: theme.palette.primary.main,
        fontSize: '26px',
        lineHeight: '26px',
        textAlign: 'center',
    },
    'scores-subtitle': {
        fontFamily: '"SegoeBold", sans-serif',
        fontSize: '15px',
        color: theme.palette.info.main
    },
    'score-value': {
        fontSize: '95px',
        lineHeight: '90px'
    },
    'score-icon': {
        width: 60,
        display: 'block',
        margin: '0 auto',
        paddingBottom: 5
    },
    'score-icon-label': {
        fontSize: '10px',
        fontFamily: '"SegoeBold", sans-serif',
        color: theme.palette.info.main,
        backgroundColor: theme.palette.primary.main,
        padding: '3px 10px',
        borderRadius: '10px'
    },
    'scores-next-button': {
        paddingTop: 50
    },
}))

RoundScore.propTypes = {
    currentPlayer: PropTypes.object.isRequired,
    score: PropTypes.number.isRequired,
    handleNext: PropTypes.func.isRequired,
}