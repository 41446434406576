import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Timer = (props) => {
  const {
    fill = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28.68}
      height={32.731}
      viewBox="0 0 28.68 32.731"
      className={className}
      {...others}
    >
      <path
        data-name="Icon open-timer"
        d="M8.183 0v4.091h4.091v.123a14.331 14.331 0 1016.365 14.2 13.9 13.9 0 00-1.023-5.114l-3.723 1.555a9.844 9.844 0 01.655 3.6A10.228 10.228 0 1114.32 8.224a10 10 0 013.6.655l1.391-3.846a17.131 17.131 0 00-2.946-.655v-.246h4.091V.041H8.183zm20.456 4.746S13.706 16.243 12.929 17.02a2 2 0 000 2.823 1.93 1.93 0 002.823 0c.818-.818 12.929-15.1 12.929-15.1z"
        fill={fill}
      />
    </svg>
  )
}

Timer.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
}

export default Timer