import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles, useTheme } from '@material-ui/core'
import Vip from '../../Svg/Vip'
import Coin from '../../Svg/Coin'
import PlayerIcon from '../../General/PlayerIcon'

export default function Player({
    index = 0,
    username = "",
    isVip = false,
    score = null,
    place = null
}) {
    const classes = useStyles()
    const theme = useTheme()

    return (
        <div className={classes["player-item"]}>
            <div className={classes["player-main-icon"]}>
                {place &&
                    <span className={classes["player-votes"]}>{place}</span>
                }
                {isVip &&
                    <Vip
                        fill={theme.palette.secondary.main}
                        className={classes["player-vip"]}
                    />
                }
                <PlayerIcon
                    index={index}
                    className={classes["player-icon"]}
                />
            </div>

            <span className={classes["player-user"]}>
                {username}
            </span>

            {score !== null &&
                <>
                    <span className={classes["player-score"]}>
                        {score}
                    </span>
                    <Coin
                        fill={theme.palette.secondary.main}
                        fillInner={theme.palette.primary.main}
                        stroke={theme.palette.secondary.main}
                        className={classes["player-coins"]}
                    />
                </>
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    'player-item': {
        display: 'flex',
        alignItems: 'center',
    },
    'player-main-icon': {
        position: 'relative',
        paddingRight: 20,
    },
    'player-icon': {
        width: 75,
        height: 75,
    },
    'player-votes': {
        fontFamily: '"SegoeBold", sans-serif',
        position: 'absolute',
        top: 5,
        left: 5,
        backgroundColor: theme.palette.secondary.main,
        color: '#fff',
        width: 25,
        height: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%'
    },
    'player-vip': {
        position: 'absolute',
        top: 12,
        left: 5
    },
    'player-user': {
        fontFamily: '"SegoeSemiBold", sans-serif',
        color: theme.palette.info.main,
        width: '100%',
        textAlign: 'left',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
        WebkitHyphens: 'auto',
        MsHyphens: 'auto',
        MozHyphens: 'auto',
        hyphens: 'auto'
    },
    'player-score': {
        fontFamily: '"SegoeBlack", sans-serif',
        color: theme.palette.primary.main,
        fontSize: '20px',
        paddingLeft: '20px'
    },
    'player-coins': {
        width: 30,
        height: 'auto'
    }
}))

Player.propTypes = {
    index: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    isVip: PropTypes.bool,
    score: PropTypes.number,
    place: PropTypes.number
}