import * as actionTypes from './types';

export const joinGame = (username, code) => dispatch => {
    dispatch({
        type: actionTypes.JOIN_GAME,
        payload: { username, code }
    })
}

export const readyUp = () => dispatch => {
    dispatch({
        type: actionTypes.READY_UP,
    })
}

export const startGame = () => dispatch => {
    dispatch({
        type: actionTypes.START_GAME,
    })
}

export const startRound = () => dispatch => {
    dispatch({
        type: actionTypes.START_ROUND,
    })
}

export const nextRound = () => dispatch => {
    dispatch({
        type: actionTypes.NEXT_ROUND,
    })
}

export const speakerReady = () => dispatch => {
    dispatch({
        type: actionTypes.SPEAKER_READY,
    })
}

export const startPerforming = () => dispatch => {
    dispatch({
        type: actionTypes.START_PERFORMING,
    })
}

export const endPerforming = () => dispatch => {
    dispatch({
        type: actionTypes.END_PERFORMING,
    })
}

export const performingExpired = () => dispatch => {
    dispatch({
        type: actionTypes.PERFORMING_EXPIRED,
    })
}

export const giveFeedback = payload => dispatch => {
    dispatch({
        type: actionTypes.GIVE_FEEDBACK,
        payload: payload,
    })
}

export const endFeedbackSession = () => dispatch => {
    dispatch({
        type: actionTypes.END_FEEDBACK_SESSION,
    })
}

export const gameEnded = () => dispatch => {
    dispatch({
        type: actionTypes.GAME_ENDED,
    })
}

export const getGameState = () => dispatch => {
    dispatch({
        type: actionTypes.GET_GAME_STATE,
    })
}

export const chooseDifficulty = data => dispatch => {
    dispatch({
        type: actionTypes.CHOOSE_DIFFICULTY,
        payload: data
    })
}