import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Blocks = (props) => {
  const {
    fill = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17.173}
      height={17.173}
      viewBox="0 0 17.173 17.173"
      className={className}
      {...others}
    >
      <path
        data-name="Dashboard icon"
        d="M4.5 14.04h7.632V4.5H4.5zm0 7.632h7.632v-5.724H4.5zm9.54 0h7.632v-9.54H14.04zm0-17.173v5.724h7.632V4.5z"
        transform="translate(-4.5 -4.5)"
        fill={fill}
      />
    </svg>
  )
}

Blocks.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
}

export default Blocks