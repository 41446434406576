import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startPerforming, endPerforming, performingExpired } from '../../../actions/index'
import { gsap } from 'gsap'
import { Grid, makeStyles, useTheme } from '@material-ui/core'
import Layout from './Layout'
import AccordionPrompt from '../../General/AccordionPrompt'
import FancyButton from '../../General/FancyButton'
import SpeakerIcon from '../../Svg/SpeakerIcon'
import ListenerIcon from '../../Svg/ListenerIcon'


export default function Prompt(props) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const theme = useTheme()
    const gameState = useSelector(d => d.game)
    const [secondsLeft, setSecondsLeft] = useState(null)
    const [startedAt, setStartedAt] = useState(null)
    const [timerText, setTimerText] = useState(null)
    const [timerSet, setTimerSet] = useState(false)

    const speaker = gameState.game.speaker
    const isSpeaker = speaker.player.connectionId === gameState.player.connectionId
    const currentPlayer = gameState.game.players.find(d => d.connectionId === gameState.player.connectionId)
    const card = speaker.card
    const hasTitle = gameState.game.template.hasLabel && card.label

    const isReading = gameState.game.stateName === 'READING_PROMPT'
    const isFinished = gameState.game.stateName === 'GIVING_FEEDBACK'
    const isPerforming = !isReading && !isFinished

    const promptButton = useRef(null)
    let buttonAnimation = useRef(null)

    const readingCard = useRef(null)
    let cardAnimation = useRef(null)

    const readingText = useRef(null)
    let textAnimation = useRef(null)

    useEffect(() => {
        window.scrollTo(0, 0)

        cardAnimation.current = gsap.timeline()
            .from(readingCard.current, {
                height: 30,
                duration: 0.2,
                ease: 'easeInOut',
                delay: 0.5
            })
            .to(readingCard.current, {
                height: 'auto',
                duration: 0.2,
                ease: 'easeInOut',
                delay: 0.5
            })

        textAnimation.current = gsap.from(readingText.current,
            {
                opacity: 0,
                duration: 0.2,
                ease: 'easeInOut',
                delay: 0.8
            })

        buttonAnimation.current = gsap.from(promptButton.current,
            {
                scale: 0.5,
                duration: 0.2,
                ease: 'easeInOut',
                delay: 1
            })

        return () => {
            if (cardAnimation.current) {
                cardAnimation.current.kill()
            }
            if (textAnimation.current) {
                textAnimation.current.kill()
            }
            if (buttonAnimation.current) {
                buttonAnimation.current.kill()
            }
        }
    }, [])

    useEffect(() => {
        if (isReading) {
            let text = card.challenge
            if (speaker.useModifier && card.modifier) {
                text = `${text} ${card.modifier}`
            }
            //4 words per seconds plus 2 for good measure
            let delaySeconds = text.split(' ').length / 4 + 2

            //set min and max values
            delaySeconds = delaySeconds < 7 ? 7 : delaySeconds
            delaySeconds = delaySeconds > 15 ? 15 : delaySeconds

            //TODO: remove this line for dynamic seconds
            delaySeconds = 15

            setTimeout(() => {
                dispatch(startPerforming())
            }, delaySeconds * 1000)
        }
    }, [dispatch, isReading, card.challenge, card.modifier, speaker.useModifier])


    useEffect(() => {
        
        if (isPerforming) {
            if (!secondsLeft) {
                setSecondsLeft(gameState.game.secondsLeft)
                setStartedAt(Date.now())
                setTimerText(gameState.game.timeLeft)
            }

            if (!timerSet && secondsLeft !== null && startedAt !== null) {
                const secondsPassed = (Date.now() - startedAt) / 1000
                let seconds = secondsLeft - secondsPassed + 1
                seconds = seconds < 0 ? 0 : seconds
                const min = Math.floor(seconds / 60)
                const sec = Math.floor(seconds - min * 60)
                const timerMin = min < 10 ? `0${min}` : `${min}`
                const timerSec = sec < 10 ? `0${sec}` : `${sec}`
                setTimerSet(true)
                setTimeout(() => {
                    setTimerText(`${timerMin}:${timerSec}`)
                    if (seconds === 0) {
                        dispatch(performingExpired())
                    } else {
                        setTimerSet(false)
                    }
                }, 250)
            }
        }
    }, [gameState.game.secondsLeft, gameState.game.timeLeft,
        isPerforming, secondsLeft, startedAt, timerSet, dispatch])

    const handleEndPerforming = e => {
        dispatch(endPerforming())
    }

    return (
        <Layout currentPlayer={currentPlayer}>
            <div className={classes["prompt-wrapper"]}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    className={classes["prompt-counter"]}
                >
                    {isSpeaker ?
                        <SpeakerIcon
                            className={classes["prompt-icon"]}
                            fillDark={theme.palette.secondary.main}
                            fillLight={theme.palette.primary.main}
                            stroke={theme.palette.secondary.main}
                        /> :
                        <ListenerIcon
                            className={classes["prompt-icon"]}
                            fillDark={theme.palette.secondary.main}
                            fillLight={theme.palette.primary.main}
                        />
                    }

                    {isSpeaker && isReading &&
                        <div className={classes["prompt-directions"]}>
                            Prepare to speak
                        </div>
                    }
                    {!(isSpeaker && isReading) &&
                        <div className={classes["prompt-timer"]}>
                            {!isPerforming && gameState.game.timeLeft}
                            {isPerforming && timerText}
                        </div>
                    }
                </Grid>

                {card &&
                    <AccordionPrompt
                        panelRef={readingCard}
                        panelTextRef={readingText}
                        title={hasTitle ? card.label : null}
                        text={card.challenge}
                        modifier={speaker.useModifier ? card.modifier : null}
                    />
                }

                <div className={classes["prompt-bottom-actions"]}>
                    {!isSpeaker &&
                        <div className={classes["prompt-directions"]}>
                            Listen to the<br></br> speaker
                        </div>
                    }

                    {isSpeaker && !isReading && !isFinished &&
                        <FancyButton
                            label="End speaking"
                            width="100px"
                            height="65px"
                            onClick={handleEndPerforming}
                        />
                    }

                    {isSpeaker && isFinished &&
                        <div className={classes["prompt-directions"]}>
                            Your roomies<br></br> are giving feedback
                        </div>
                    }
                </div>
            </div>
        </Layout>
    )
}

const useStyles = makeStyles(theme => ({
    'prompt-wrapper': {
        width: '100%',
        maxWidth: 300,
        margin: '0 auto'
    },
    'prompt-counter': {
        padding: '0 0 8vh 0'
    },
    'prompt-icon': {
        width: 40,
        height: 'auto',
        paddingRight: 25
    },
    'prompt-timer': {
        fontFamily: '"SegoeBold", sans-serif',
        color: theme.palette.info.main,
        fontSize: '50px'
    },
    'prompt-bottom-actions': {
        padding: '8vh 0'
    },
    'accordion-title': {

    },
    'prompt-directions': {
        fontSize: '20px',
        lineHeight: '25px',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center'
    }
}))