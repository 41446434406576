import React from "react"
import {
  DEFAULT_PRIMARY_COLOR_CODE,
} from '../../constants/general'

const GreenPath = React.forwardRef((props, ref) => {
  const {
    stroke = DEFAULT_PRIMARY_COLOR_CODE
  } = props
  return (
    <svg
      ref={ref}
      className="green-path"
      xmlns="http://www.w3.org/2000/svg"
      width={189.31}
      height={135.373}
      viewBox="0 0 189.31 135.373"
      {...props}
    >
      <path
        data-name="Path 480"
        d="M176.369 81.35l-73.092 46.125a16.157 16.157 0 01-17.245 0L12.939 81.35a16.157 16.157 0 010-27.328L86.032 7.898a16.157 16.157 0 0117.245 0l73.092 46.124a16.156 16.156 0 010 27.328z"
        fill="none"
        stroke={stroke}
        strokeMiterlimit={10}
        strokeWidth="10.81px"
      />
    </svg>
  )
})

export default GreenPath