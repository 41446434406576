import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const ExpandWheel = (props) => {
  const {
    fillLight = DEFAULT_PRIMARY_COLOR_CODE,
    fillDark = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={86}
      height={86}
      viewBox="0 0 86 86"
      className={className}
      {...others}
    >
      <circle cx={43} cy={43} r={43} fill={fillDark} />
      <path
        d="M60.837 45.352A18.7 18.7 0 0061.005 43a18.7 18.7 0 00-.168-2.352l5.064-3.96a1.211 1.211 0 00.288-1.536l-4.8-8.3a1.206 1.206 0 00-1.464-.528l-5.976 2.396a17.535 17.535 0 00-4.056-2.352l-.912-6.36A1.17 1.17 0 0047.805 19h-9.6a1.17 1.17 0 00-1.176 1.008l-.912 6.36a18.439 18.439 0 00-4.056 2.352l-5.976-2.4a1.171 1.171 0 00-1.464.528l-4.8 8.3a1.183 1.183 0 00.288 1.536l5.064 3.96A19.033 19.033 0 0025.005 43a19.033 19.033 0 00.168 2.352l-5.064 3.96a1.211 1.211 0 00-.288 1.536l4.8 8.3a1.206 1.206 0 001.464.528l5.976-2.4a17.535 17.535 0 004.056 2.352l.912 6.36A1.17 1.17 0 0038.205 67h9.6a1.17 1.17 0 001.176-1.008l.912-6.36a18.439 18.439 0 004.056-2.352l5.976 2.4a1.171 1.171 0 001.464-.528l4.8-8.3a1.211 1.211 0 00-.288-1.536l-5.064-3.96zM43.005 51.4a8.4 8.4 0 118.4-8.4 8.409 8.409 0 01-8.4 8.4z"
        fill={fillLight}
      />
    </svg>
  )
}


ExpandWheel.propTypes = {
  fillLight: PropTypes.string,
  fillDark: PropTypes.string,
  className: PropTypes.string
}


export default ExpandWheel