import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Avatar10 = (props) => {
  const {
    fill = DEFAULT_PRIMARY_COLOR_CODE,
    stroke = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
      className={className}
      {...others}
    >
      <rect
        x={176.92}
        y={176.92}
        width={646.15}
        height={646.15}
        rx={111.77}
        transform="rotate(45 500.004 499.998)"
        fill={fill}
      />
      <path
        className="cls-2"
        d="M507.62 606.61A31.4 31.4 0 10539 575.22M507.62 543.82a31.4 31.4 0 1131.4 31.4M471 442.71a53.48 53.48 0 00-107 0c0 29.54 24 11.76 53.48 11.76S471 472.25 471 442.71zM646 442.71a53.48 53.48 0 00-107 0c0 29.54 23.94 10.36 53.48 10.36S646 472.25 646 442.71z"
        fill="none"
        stroke={stroke}
        strokeWidth="25px"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

Avatar10.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string
}

export default Avatar10
