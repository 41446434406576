import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Coin = React.forwardRef((props, ref) => {
  const {
    fill = DEFAULT_SECONDARY_COLOR_CODE,
    fillInner = DEFAULT_PRIMARY_COLOR_CODE,
    stroke = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props


  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64}
      height={64}
      viewBox="0 0 64 64"
      className={className}
      {...others}
    >
      <g id="Group_233" data-name="Group 233" transform="translate(-205 -393)">
        <circle
          id="Ellipse_139"
          data-name="Ellipse 139"
          cx={30.5}
          cy={30.5}
          r={30.5}
          transform="translate(208 396)"
          fill={fill}
        />
        <g
          id="Group_225"
          data-name="Group 225"
          transform="translate(205.2 393.1)"
        >
          <circle
            id="Ellipse_137"
            data-name="Ellipse 137"
            cx={30.5}
            cy={30.5}
            r={30.5}
            transform="translate(-.2 -.1)"
            fill={fillInner}
          />
          <circle
            id="Ellipse_138"
            data-name="Ellipse 138"
            cx={24.5}
            cy={24.5}
            r={24.5}
            transform="translate(5.8 5.9)"
            fill="none"
            stroke={stroke}
            strokeWidth="1.5px"
          />
          <path
            id="Path_569"
            data-name="Path 569"
            className="cls-4"
            d="M1256.8 1518.762l-9.862 6.223a2.178 2.178 0 01-2.327 0l-9.862-6.223a2.18 2.18 0 010-3.687l9.862-6.224a2.18 2.18 0 012.327 0l9.862 6.224a2.18 2.18 0 010 3.687z"
            transform="translate(-1214.913 -1486.851)"
            fill="none"
            stroke={stroke}
            strokeWidth="1.5px"
            strokeMiterlimit="10"
          />
          <path
            id="Path_571"
            data-name="Path 571"
            className="cls-4"
            d="M1256.8 1518.762l-9.862 6.223a2.178 2.178 0 01-2.327 0l-9.862-6.223a2.18 2.18 0 010-3.687l9.862-6.224a2.18 2.18 0 012.327 0l9.862 6.224a2.18 2.18 0 010 3.687z"
            transform="translate(-1214.913 -1491.302)"
            fill="none"
            stroke={stroke}
            strokeWidth="1.5px"
            strokeMiterlimit="10"
          />
          <path
            id="Path_570"
            data-name="Path 570"
            className="cls-4"
            d="M1256.8 1518.762l-9.862 6.223a2.178 2.178 0 01-2.327 0l-9.862-6.223a2.18 2.18 0 010-3.687l9.862-6.224a2.18 2.18 0 012.327 0l9.862 6.224a2.18 2.18 0 010 3.687z"
            transform="translate(-1214.913 -1482.399)"
            fill="none"
            stroke={stroke}
            strokeWidth="1.5px"
            strokeMiterlimit="10"
          />
        </g>
      </g>
    </svg>
  )
})

Coin.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  fillInner: PropTypes.string,
  className: PropTypes.string,
}

export default Coin