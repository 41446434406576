import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { getDefaultTheme, getDarkTheme, getLocalStorageTheme, setLocalStorageTheme, updateHeadMetas } from './utils'
import * as microsoftTeams from "@microsoft/teams-js"
import {
  DEFAULT_BACKGROUND_COLOR_CODE,
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE,
  DEFAULT_SQUARE_COLOR_CODE,
  DEFAULT_TEXT_COLOR_CODE
} from './constants/general'

import Login from './components/Auth/Login'
import AuthenticatedComponent from './components/Auth/AuthenticatedComponent'
import Game from './components/Game/Game'
import StartGame from './components/Game/StartGame'
import axios from 'axios'
import Privacy from './components/Terms/Policy'
import Terms from './components/Terms/Terms'
import Offer from './components/Marketplace/Offer'
import SidePanelConfig from './components/Teams/SidePanelConfig'


function App() {
  const [colorData, setColorData] = useState(null)

  useEffect(() => {
    const fetchTheme = async (name) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/subdomain/${name}`)
        if (!response.data) {
          setDefaultTheme()
        } else {
          setLocalStorageTheme(response.data)
          setColorData(response.data)
          updateHeadMetas(response.data)
        }
      } catch {
        setDefaultTheme()
      }
    }

    const subdomain = window.location.hostname.split('.')[0]
    if (subdomain === 'www' || subdomain === 'chambr') {
      setDefaultTheme()
    } else {
      const storedTheme = getLocalStorageTheme()
      if (storedTheme && storedTheme.name === subdomain) {
        setColorData(storedTheme)
        updateHeadMetas(storedTheme)
      } else {
        fetchTheme(subdomain)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setDefaultTheme = async () => {
    const shouldUseDark = await shouldUseDarkTheme()
    if (shouldUseDark) {
      setColorData(getDarkTheme())
    } else {
      setColorData(getDefaultTheme())
    }
    updateHeadMetas()
  }

  const shouldUseDarkTheme = async () => {
    try {
      await microsoftTeams.app.initialize()
      const context = await microsoftTeams.app.getContext()
      if (context.app.theme === 'dark') {
        return true
      }
    } catch { }
    return false
  }

  if (!colorData) {
    return <div>Preloading...</div>
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: colorData.primaryColor || DEFAULT_PRIMARY_COLOR_CODE,
      },
      secondary: {
        main: colorData.secondaryColor || DEFAULT_SECONDARY_COLOR_CODE,
      },
      //this is the font color
      info: {
        main: colorData.infoColor || DEFAULT_TEXT_COLOR_CODE
      },
      //this is the background color
      success: {
        main: colorData.backgroundColor || DEFAULT_BACKGROUND_COLOR_CODE
      },
      //this is the square color
      warning: {
        main: colorData.squareColor || DEFAULT_SQUARE_COLOR_CODE
      },
    }
  })
  document.body.style.backgroundColor = colorData.backgroundColor || DEFAULT_BACKGROUND_COLOR_CODE

  return (
    <div style={{ backgroundColor: theme.palette.success.main, height: '100dvh' }}>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/marketplace">
              <Offer />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/game">
              <Game />
            </Route>
            <Route exact path="/terms">
              <Terms />
            </Route>
            <Route exact path="/policy">
              <Privacy />
            </Route>
            <Route exact path="/sidepanelconfig">
              <SidePanelConfig />
            </Route>
            <Route exact path="/">
              <StartGame />
            </Route>
            <AuthenticatedComponent />
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider >
    </div>
  );
}

export default App;