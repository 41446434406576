import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios'
import { setAuthData } from '../../utils';
import { useHistory } from 'react-router-dom';
import GeneralButton from '../Admin/components/GeneralButton';
import { useTheme } from '@material-ui/core';


const MicrosoftLoginButton = (loginRequest) => {
  const theme = useTheme()
  const { instance } = useMsal()
  const history = useHistory()
  const [errorMsg, setErrorMsg] = useState('')

  const handleLogin = async () => {
    try {
      var authResult = await instance.loginPopup(loginRequest);
      //console.log(authResult)
      var res = await axios({
        method: 'POST',
        data: {
          token: authResult.accessToken
        },
        url: `${process.env.REACT_APP_SERVER_URL}/api/Authorization/validateAdToken/`,
      });
      setAuthData(res.data)
      history.push('/presets')
    } catch (error) {
      setErrorMsg("Invalid login attempt. If you've just activated a subscription hold tight. It might take a minute to complete setting it up.")
    }
  };

  return (
    <>
      <GeneralButton fontColor={theme.palette.info.main}
        backgroundColor={theme.palette.primary.main}
        onClick={handleLogin}
      >
        Log in with Microsoft
      </GeneralButton>
      <div>
        <span style={{color: 'red'}}>{errorMsg}</span>
      </div>
    </>
  );
};

export default MicrosoftLoginButton;