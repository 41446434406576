import { LOG_OUT } from './types';
import { getAuthToken, removeAuthData } from '../utils';
import axios from 'axios';


export const handleAxiosError =  (err, dispatch) => {
    try {
        if (err.response.status === 404 || err.response.status === 401) {
            removeAuthData();
            dispatch({ type: LOG_OUT });
        }
    } catch {
        //some error occured
        console.log(err);
    }
};

export const getAxiosConfig = () => {
    const token = getAuthToken()
    return { headers: { "Authorization": `Bearer ${token}` } };
};

export const downloadFile = async (url, fileName) => {
    try {
      const response = await axios({
        method: 'GET',
        url: url,
        responseType: 'blob',
        headers: { "Authorization": `Bearer ${getAuthToken()}` }
      });
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = blobUrl
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error(error);
    }
}