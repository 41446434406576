import * as actionTypes from '../actions/types'

const initialState = {
    isSignalRConnected: false,
    player: {
        username: null,
        connectionId: null,
        message: null,
    },
    game: null,
}

export const gameReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GAME_LOGOUT:
            return {
                ...state,
                player: {
                    username: null,
                    connectionId: null,
                    message: null,
                },
                game: null,
            }
        case actionTypes.SIGNALR_CONNECTED:
            return {
                ...state,
                isSignalRConnected: true,
            }
        case actionTypes.SIGNALR_DISCONNECTED:
            return {
                ...state,
                isSignalRConnected: false,
            }
        case actionTypes.YOU_JOINED:
            return {
                ...state,
                player: {
                    ...state.player,
                    username: action.payload.username,
                    connectionId: action.payload.connectionId,
                    message: null,
                },
            }
        case actionTypes.CANNOT_JOIN:
            return {
                ...state,
                player: {
                    ...state.player,
                    username: null,
                    connectionId: null,
                    message: action.payload,
                },
            }
        case actionTypes.GAME_STATE_CHANGED:
            return {
                ...state,
                game: { ...action.payload },
            }
        case actionTypes.GAME_ENDED:
            return {
                ...state,
                player: {
                    username: null,
                    connectionId: null,
                    message: null,
                },
                game: null,
            }
        default:
            return state
    }
}

export default gameReducer