import React from 'react'
import PropTypes from 'prop-types'
import { } from '@material-ui/styles'
import { makeStyles, useTheme } from '@material-ui/core'

const StyledInput = React.forwardRef((props, ref) => {
  const theme = useTheme()
  const {
    onChange,
    onKeyDown,
    disabled = false,
    type = "",
    placeholder = "",
    className = "",
    height = "35px",
    width = "calc(100% - 24px)",
    fontSize = "16px",
    fontColor = theme.palette.info.main,
    backgroundColor = theme.palette.primary.main,
    ...others
  } = props
  const classes = useStyles({ height, fontSize, fontColor, backgroundColor, width })

  return (
    <input
      ref={ref}
      disabled={disabled}
      onChange={onChange}
      onKeyDown={onKeyDown}
      autoComplete="off"
      className={`${classes["green-input"]} ${className}`}
      type={type}
      placeholder={placeholder}
      {...others}
    />
  )
})

const useStyles = makeStyles(theme => ({
  'green-input': {
    textAlign: 'center',
    fontFamily: '"SegoeSemibold", sans-serif',
    height: ({ height }) => height,
    width: ({ width }) => width,
    border: 'none',
    borderRadius: '100px',
    background: ({ backgroundColor }) => backgroundColor,
    padding: '6px 12px',
    WebkitBoxShadow: '3px 3px 10px -4px rgba(0, 0, 0, 0.2)',
    MozBoxShadow: '3px 3px 10px -4px rgba(0, 0, 0, 0.2)',
    BoxShadow: '3px 3px 10px -4px rgba(0, 0, 0, 0.2)',
    color: ({ fontColor }) => fontColor,
    fontSize: ({ fontSize }) => fontSize,
    '&:focus': {
      outline: 'none',
      border: 'none',
    },
    '&::placeholder': {
      color: ({ fontColor }) => fontColor,
      fontSize: ({ fontSize }) => fontSize,
      textAlign: 'center'
    },
    '&::-webkit-input-placeholder': {
      color: ({ fontColor }) => fontColor,
      fontSize: ({ fontSize }) => fontSize,
      textAlign: 'center'
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0
    },
    '&[type=number]': {
      MozAppearance: 'textfield'
    }
  }
}))

StyledInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default StyledInput