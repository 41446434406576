import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles, useTheme } from '@material-ui/core'
import Layout from './Layout'
import FancyButton from '../../General/FancyButton'
import Feedback from '../../Svg/Feedback'
import ConfirmationPopup from '../../Admin/components/ConfirmationPopup'


export default function TextFeedbacks({
    currentPlayer = null,
    feedbacks = [],
    handleNext,
    hasRating,
}) {
    const classes = useStyles()
    const theme = useTheme()
    const filteredFeedbacks = [...feedbacks].filter(item => item && item.text && item.text.length > 0) || []
    const handleNextClick = e => {
        handleNext(e)
    }
    const [isLeavePopupOpen, setIsLeavePopupOpen] = useState(false)
    const advanceButtonText = hasRating ? "Leaderboard" : "Roomies"

    const handlePopupClose = () => {
        setIsLeavePopupOpen(false)
    }

    return (
        <Layout currentPlayer={currentPlayer} shouldBackgroundRotateForward={true}>
            <div className={classes["text-feedbacks-page"]}>
                <Feedback
                    fillDark={theme.palette.secondary.main}
                    fillLight={theme.palette.primary.main}
                    className={classes["text-feedbacks-icon"]}
                />
                {filteredFeedbacks.length > 0 ?
                    feedbacks.map((item, index) => (
                        <div key={`feedback-${index}`} className={classes["text-feedback-card"]}>
                            {item.text}
                        </div>
                    )) :
                    <div className={classes["text-feedbacks-title"]}>You have no feedback...</div>
                }
                <div className={classes["text-feedbacks-button"]}>
                    <FancyButton
                        label={advanceButtonText}
                        width="63px"
                        height="44px"
                        labelFontSize="14px"
                        onClick={() => {
                            if (filteredFeedbacks.length > 0) {
                                setIsLeavePopupOpen(true)
                            } else {
                                handleNextClick()
                            }
                        }}
                    />
                </div>
                <ConfirmationPopup
                    isOpen={isLeavePopupOpen}
                    onClose={handlePopupClose}
                    onYes={handleNextClick}
                    onNo={handlePopupClose}
                    text="Have you read your feedback?"
                />
            </div>
        </Layout >
    )
}

const useStyles = makeStyles(theme => ({
    'text-feedbacks-page': {
        width: '100%',
        maxWidth: 300,
        margin: '0 auto',
        textAlign: 'center'
    },
    'text-feedback-card': {
        textAlign: 'left',
        backgroundColor: '#eeeeee',
        padding: '30px 20px',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '17px',
        WebkitBoxShadow: `9px 8px 0px -2px ${theme.palette.secondary.main}`,
        MozBoxShadow: `9px 8px 0px -2px ${theme.palette.secondary.main}`,
        boxShadow: `9px 8px 0px -2px ${theme.palette.secondary.main}`,
        marginBottom: 30,
        color: theme.palette.info.main
    },
    'text-feedbacks-icon': {
        width: 45,
        padding: '5vh 0 10vh'
    },
    'text-feedbacks-button': {
        paddingTop: '10vh'
    },
    'text-feedbacks-title': {
        paddingBottom: '3vh',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        fontSize: '20px',
        textAlign: 'center'
    }
}))

TextFeedbacks.propTypes = {
    currentPlayer: PropTypes.object.isRequired,
    feedbacks: PropTypes.array.isRequired,
    handleNext: PropTypes.func.isRequired,
    hasRating: PropTypes.bool.isRequired,
}