import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getPresets,
  templateSelectPreset,
  createTemplate,
  generateCodes
} from '../../../actions/admin'
import {
  Grid,
  makeStyles,
  useTheme,
  IconButton,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import SearchIcon from '@material-ui/icons/Search'
import StyledInput from '../../General/StyledInput'
import SelectablePreset from '../components/SelectablePreset'
import ExpandWheel from '../../Svg/ExpandWheel'
import ExpandCheck from '../../Svg/ExpandCheck'
import ArrowDown from '../../Svg/ArrowDown'
import FancyButton from '../../General/FancyButton'
import Badge from '../../Svg/Badge'
import Hashtag from '../../Svg/Hashtag'
import Pen from '../../Svg/Pen'
import Weight from '../../Svg/Weight'
import Timer from '../../Svg/Timer'
import { useHistory } from 'react-router-dom'

const ColoredArrowDown = () => {
  const theme = useTheme()
  return (
    <ArrowDown fill={theme.palette.primary.main} />
  )
}


const Template = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const templateState = useSelector(d => d.template)
  const history = useHistory()
  const [searchText, setSearchText] = useState("")
  const [isExpanded, setIsExpanded] = useState(false)
  const [isChecked, setIsChecked] = useState(true)
  const [difficultyValue, setDifficultyValue] = useState("")
  const [gameTypeValue, setGameTypeValue] = useState("")
  const [minutes, setMinutes] = useState(1)
  const [seconds, setSeconds] = useState(30)
  const [rounds, setRounds] = useState(1)
  const [error, setError] = useState(null)
  const classes = useStyles({ isExpanded })
  const minutesInput = useRef(null)

  useEffect(() => {
    dispatch(getPresets())

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (minutesInput.current) {
      minutesInput.current.focus()
    }
  }, [isExpanded])

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null)
      }, 4000)
    }
  }, [error])

  const canCreate = templateState.presets.some(item => item.isSelected)

  const validateNumericValue = (value) => {
    const matchFirstCharIsZero = parseInt(value.charAt(0)) === 0

    if (matchFirstCharIsZero) {
      setError('The value can\'t start with 0')
    }
    if (value.length === 0) {
      setError('Required field')
    }
    if (parseInt(value) === 0) {
      setError('The value must be greater than 0')
    }
  }

  const handleSearchChange = e => {
    e.persist()
    setSearchText(e.target.value)
  }

  const handleSelect = id => {
    dispatch(templateSelectPreset(id))
  }

  const handleWheelClick = () => {
    setIsExpanded(!isExpanded)
  }

  const handleMinutesChange = e => {
    setMinutes(e.target.value)
    if (e.target.value.length === 0) {
      setError('Required field')
    }
    if (e.target.value < 0 || e.target.value > 59) {
      setError('Minutes and seconds must have a value between 0-59')
    }
  }

  const handleSecondsChange = e => {
    setSeconds(e.target.value)
    if (e.target.value.length === 0) {
      setError('Required field')
    }
    if (e.target.value < 0 || e.target.value > 59) {
      setError('Minutes and seconds must have a value between 0-59')
    }
  }

  const handleRoundsChange = e => {
    setRounds(e.target.value)
    validateNumericValue(e.target.value)
  }

  const handleCheckbox = () => {
    setIsChecked(!isChecked)
  }

  const hadleDifficultyChange = e => {
    setDifficultyValue(e.target.value)
  }

  const hadleGameTypeChange = e => {
    setGameTypeValue(e.target.value)
  }

  const handleClick = async () => {
    let hasRating, hasFeedback
    switch (gameTypeValue) {
      case 'comp':
        hasRating = true
        hasFeedback = false
        break;
      case 'feedback':
        hasRating = false
        hasFeedback = true
        break;
      case 'both':
        hasRating = true
        hasFeedback = true
        break;
      default:
        hasRating = true
        hasFeedback = true
        break;
    }

    const payload = {
      idPreset: templateState.presets.find(item => item.isSelected).id,
      roundCount: parseInt(rounds),
      seconds: parseInt(minutes) * 60 + parseInt(seconds),
      useModifier: difficultyValue === 'on' ? true : false,
      hasLabel: isChecked,
      hasRating,
      hasFeedback,
    }
    const createdTemplate = await dispatch(createTemplate(payload))
    await dispatch(generateCodes(createdTemplate.id))
    history.push('/chambrs')
  }

  let availablePresets = [...templateState.presets]
  if (searchText) {
    const text = searchText.toLowerCase()
    availablePresets = availablePresets.filter(preset =>
      preset.name.toLowerCase().includes(text)
    )
  }

  return (
    <Grid
      container
      className={`${classes['preset-container']} full-height`}
    >
      <Grid
        item
        xs={12}
        sm={isExpanded ? 12 : 3}
        md={isExpanded ? 12 : 4}
        className="full-height"
      >
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          className={classes["preset-vertical-accordion"]}
          wrap="nowrap"
        >
          <IconButton
            aria-label="expand"
            onClick={handleWheelClick}
            classes={{ root: classes["preset-card-expander"] }}
          >
            {isExpanded ?
              <ExpandCheck
                fillDark={theme.palette.secondary.main}
                fillLight={theme.palette.primary.main}
              /> :
              <ExpandWheel
                fillDark={theme.palette.secondary.main}
                fillLight={theme.palette.primary.main}
              />
            }
          </IconButton>

          <Grid
            container
            alignItems="center"
            className={`${classes["preset-info-wrapper"]} ${classes["preset-info-inner"]}`}
          >
            <Grid
              container
              direction="column"
            >
              <Grid className={classes["preset-card-info"]}>
                <Timer fill={theme.palette.secondary.main} />
                <div className={classes['preset-card-note']}>
                  Timer
                </div>
                <div>{Number(minutes) * 60 + Number(seconds)} sec / turn</div>
              </Grid>
              <Grid className={classes["preset-card-info"]}>
                <Hashtag fill={theme.palette.secondary.main} />
                <div className={classes['preset-card-note']}>
                  Number of rounds
                </div>
                <div>{rounds} {rounds === 1 ? 'round' : 'rounds'}</div>
              </Grid>
              <Grid className={classes["preset-card-info"]}>
                <Pen fill={theme.palette.secondary.main} />
                <div className={classes['preset-card-note']}>
                  Title
                </div>
                <div>{isChecked ? 'Active' : 'Inactive'}</div>
              </Grid>
              <Grid className={classes["preset-card-info"]}>
                <Weight fill={theme.palette.secondary.main} />
                <div className={classes['preset-card-note']}>
                  Extra level of difficulty
                </div>
                <div>{difficultyValue === 'off' ? 'Off' : 'On'}</div>
              </Grid>
              <Grid className={classes["preset-card-info"]}>
                <Badge fill={theme.palette.secondary.main} />
                <div className={classes['preset-card-note']}>
                  Game type
                </div>
                <div>
                  {(!gameTypeValue || gameTypeValue === 'both') && 'Comp & Feedback'}
                  {(gameTypeValue && gameTypeValue === 'comp') && 'Competitive'}
                  {(gameTypeValue && gameTypeValue === 'feedback') && 'Feedback'}
                </div>
              </Grid>
            </Grid>
          </Grid>

          {isExpanded &&
            <Grid
              container
              alignItems="center"
              className={`${classes["preset-card-revealed"]} ${classes["preset-info-inner"]}`}
            >
              <Grid className={classes["revealed-wrapper"]}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  className={classes["revealed-row"]}
                >
                  <Grid item xs={12} sm={5} md={5} className={classes["revealed-upper-col"]}>
                    <StyledInput
                      ref={minutesInput}
                      onChange={handleMinutesChange}
                      type="number"
                      height="38px"
                      width="100px"
                      fontSize="30px"
                      className={classes["revealed-input"]}
                      backgroundColor={theme.palette.secondary.main}
                      fontColor={theme.palette.primary.main}
                      value={minutes}
                    />
                    <span className={classes["revealed-label"]}>min</span>
                  </Grid>

                  <Grid item xs={12} sm={1} md={1}>
                    <span className={classes["revealed-label"]}>/</span>
                  </Grid>

                  <Grid item xs={12} sm={5} md={5} className={classes["revealed-upper-col"]}>
                    <StyledInput
                      onChange={handleSecondsChange}
                      type="number"
                      height="38px"
                      width="100px"
                      fontSize="30px"
                      className={classes["revealed-input"]}
                      backgroundColor={theme.palette.secondary.main}
                      fontColor={theme.palette.primary.main}
                      value={seconds}
                    />
                    <span className={classes["revealed-label"]}>sec</span>
                  </Grid>
                </Grid>

                <Grid container alignItems="center" justifyContent="center" className={classes["revealed-row"]}>
                  <StyledInput
                    onChange={handleRoundsChange}
                    type="number"
                    height="38px"
                    width="100px"
                    fontSize="30px"
                    className={classes["revealed-input"]}
                    backgroundColor={theme.palette.secondary.main}
                    fontColor={theme.palette.primary.main}
                    value={rounds}
                  />
                  <span className={classes["revealed-label"]}>{rounds === 1 ? 'round' : 'rounds'}</span>

                  {error &&
                    <Grid item xs={12}>
                      <span className={classes["validation-errors"]}>{error}</span>
                    </Grid>
                  }
                </Grid>

                <Grid container alignItems="center" justifyContent="center" className={classes["revealed-row"]}>
                  <IconButton
                    aria-label="check"
                    onClick={handleCheckbox}
                    className={classes["revealed-checkbox"]}
                  >
                    {isChecked ?
                      <CheckCircleOutlineIcon className={classes["revealed-checkbox-icon"]} />
                      :
                      <HighlightOffIcon className={classes["revealed-checkbox-icon"]} />
                    }
                  </IconButton>
                </Grid>

                <Grid container alignItems="center" justifyContent="center" className={classes["revealed-row"]}>
                  <FormControl className={classes["revealed-dropdown"]}>
                    <Select
                      displayEmpty
                      labelId="difficulty-select-label"
                      value={difficultyValue || ""}
                      onChange={hadleDifficultyChange}
                      IconComponent={ColoredArrowDown}
                      MenuProps={{
                        classes: { paper: classes["dropdown-style"] },
                        variant: 'menu'
                      }}
                    >
                      <MenuItem value="" disabled>Choose extra difficulty option</MenuItem>
                      <MenuItem value={"on"}>On</MenuItem>
                      <MenuItem value={"off"}>Off</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid container alignItems="center" justifyContent="center" className={classes["revealed-row"]}>
                  <FormControl className={classes["revealed-dropdown"]}>
                    <Select
                      displayEmpty
                      labelId="difficulty-select-label"
                      value={gameTypeValue || ""}
                      onChange={hadleGameTypeChange}
                      IconComponent={ColoredArrowDown}
                      MenuProps={{
                        classes: { paper: classes["dropdown-style"] },
                        variant: 'menu'
                      }}
                    >
                      <MenuItem value="" disabled>Choose game type</MenuItem>
                      <MenuItem value={"comp"}>Competitive</MenuItem>
                      <MenuItem value={"feedback"}>Feedback</MenuItem>
                      <MenuItem value={"both"}>Competitive &amp; Feedback</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>

      {
        !isExpanded &&
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          className="full-height"
        >
          <label className={classes["create-template-search"]}>
            <SearchIcon classes={{ root: classes["search-icon"] }} />
            <StyledInput
              onChange={handleSearchChange}
              type="text"
              placeholder="Search preset.."
              height="38px"
              backgroundColor={theme.palette.secondary.main}
              fontColor="#ffffff"
              className={classes["create-preset-input"]}
            />
          </label>

          <div className={classes["preset-card-list"]}>
            {availablePresets.map(preset => (
              <SelectablePreset
                key={preset.id}
                preset={preset}
                handleSelect={handleSelect}
              />
            ))}
          </div>

          <FancyButton
            label="Generate chambr"
            width="100px"
            height="65px"
            disabled={!canCreate}
            onClick={handleClick}
          />
        </Grid>
      }
    </Grid >
  )
}

const useStyles = makeStyles(theme => ({
  'preset-container': {
    [theme.breakpoints.between('sm', 'sm')]: {
      justifyContent: 'space-between',
    },
  },
  'create-template-search': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 50
  },
  'search-icon': {
    position: 'absolute',
    color: '#ffffff',
    left: 10,
    fontSize: '30px'
  },
  'create-preset-input': {
    paddingLeft: 40,
    paddingRight: 40
  },
  'preset-vertical-accordion': {
    transition: 'all 0.3s ease',
    maxWidth: ({ isExpanded }) => isExpanded ? '98%' : 250,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.info.main,
    height: '100%',
    borderRadius: '150px',
    position: 'relative',
    [theme.breakpoints.between('sm', 'sm')]: {
      padding: '0 30px',
    },
    [theme.breakpoints.between('md', 'md')]: {
      maxWidth: ({ isExpanded }) => isExpanded ? '98%' : 200,
    },
  },
  'preset-card-info': {
    fontFamily: '"SegoeBlack", sans-serif',
    textAlign: 'center',
    overflow: 'hidden',
    marginBottom: '5vh',
    minWidth: 140,
    width: '100%',
    [theme.breakpoints.between('sm', 'md')]: {
      minWidth: 'auto',
    },
    '@media (max-height:800px)': {
      minWidth: 'auto',
      marginBottom: '2vh'
    },
    '& div': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '100%',
      [theme.breakpoints.between('sm', 'sm')]: {
        fontSize: 12
      },
    },
    '& svg': {
      height: 40,
      width: 'auto',
      [theme.breakpoints.between('sm', 'sm')]: {
        height: 20
      },
      '@media (max-height:800px)': {
        height: 25
      },
    },
    '&:last-child': {
      marginBottom: 0
    }
  },
  'preset-card-note': {
    fontSize: '10px',
    fontFamily: '"SegoeSemiBold", sans- serif'
  },
  'preset-card-expander': {
    position: 'absolute',
    right: -43,
    zIndex: 5,
    [theme.breakpoints.between('sm', 'sm')]: {
      right: -35,
      '& svg': {
        width: 60,
        height: 60
      }
    },
    '&:after': {
      transition: 'background-color 0.3s ease, opacity 0.5s ease',
      content: '""',
      opacity: 0,
    },
    '&:hover:after': {
      content: ({ isExpanded }) => isExpanded ? '""' : '"Edit Settings"',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '100px',
      padding: '5px 10px',
      color: theme.palette.info.main,
      position: 'absolute',
      right: '-50%',
      fontSize: '10px',
      fontFamily: '"SegoeBold", sans-serif',
      opacity: 1,
      transition: 'background-color 0.3s ease, opacity 0.5s ease',
    },
  },
  'preset-info-wrapper': {
    padding: '0 62px',
    maxWidth: 250,
    [theme.breakpoints.between('sm', 'sm')]: {
      padding: '0 ',
      maxWidth: 170,
    },
    [theme.breakpoints.between('md', 'md')]: {
      padding: '0 42px',
    },
  },
  'revealed-wrapper': {
    width: '50vw',
    maxWidth: 500,
    margin: '0 auto',
    [theme.breakpoints.between('sm', 'sm')]: {
      width: '55vw',
    },
    [theme.breakpoints.between('md', 'md')]: {
      maxWidth: 300,
    },
  },
  'revealed-row': {
    marginBottom: '5vh',
    minHeight: 80,
    '@media (max-height:800px)': {
      marginBottom: '2vh',
      minHeight: 'auto',
      height: 66
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      minHeight: 'auto',
      height: 57
    },
    '&:last-child': {
      marginBottom: 0,
    }
  },
  'revealed-label': {
    fontFamily: '"SegoeBlack", sans-serif',
    fontSize: '30px',
    marginLeft: 10,
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '20px'
    },
  },
  'revealed-checkbox': {
    color: theme.palette.info.main
  },
  'revealed-checkbox-icon': {
    fontSize: '50px',
    cursor: 'pointer'
  },
  'revealed-dropdown': {
    '& .MuiInputBase-root': {
      height: 50,
      width: '50vw',
      maxWidth: 500,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      fontFamily: '"SegoeBlack", sans-serif',
      borderRadius: '100px',
      [theme.breakpoints.between('md', 'md')]: {
        maxWidth: 300,
      },
    },
    '& .MuiInput-underline:before': {
      content: 'none'
    },
    '& .MuiInput-underline:after': {
      content: 'none'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '0 20px',
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    '& svg': {
      top: 'calc(50% - 10px)',
      right: 15,
      position: 'absolute',
      pointerEvents: 'none'
    }
  },
  'dropdown-style': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    borderRadius: '30px',
    '& .MuiMenuItem-root': {
      fontFamily: '"SegoeBlack", sans-serif',
      justifyContent: 'center'
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    }
  },
  'preset-card-revealed': {
    width: 'calc(100% - 255px)',
    [theme.breakpoints.between('sm', 'sm')]: {
      width: '100%',
    },
  },
  'revealed-input': {
    fontFamily: '"SegoeBlack", sans-serif',
  },
  'preset-card-list': {
    height: 'calc(100% - 275px)',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    marginBottom: 30,
    scrollbarWidth: 'none',
    '&-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  'validation-errors': {
    fontSize: '14px',
    color: '#cf0000',
    display: 'block',
    marginTop: 10,
    textAlign: 'center'
  },
  'preset-info-inner': {
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    overflowX: 'hidden',
    height: '90%',
    scrollbarWidth: 'none',
    '&-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  'revealed-upper-col': {
    [theme.breakpoints.between('sm', 'md')]: {
      textAlign: 'center'
    },
  }
}))

export default Template