import React, { useState, useRef, useEffect } from 'react'
import { gsap, Power1 } from 'gsap'
import * as microsoftTeams from "@microsoft/teams-js";

import { Grid, makeStyles } from '@material-ui/core'
import Layout from '../Game/Components/Layout'
import StyledInput from '../General/StyledInput'
import axios from 'axios';

export default function SidePanelConfig() {
    const classes = useStyles()
    const [codeError, setCodeError] = useState(null)
    const bottomButton = useRef(null)
    let animation = useRef(null)

    useEffect(() => {
        animation.current = gsap.from(bottomButton.current,
            {
                y: '-50vh',
                opacity: 0,
                duration: 0.3,
                ease: Power1.easeOut,
                delay: 0.2
            })

        return () => {
            if (animation.current) {
                animation.current.kill()
            }
        }
    }, [])

    useEffect(() => {
        setupConfig(`${window.location.origin}/game`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setupConfig = url => {
        microsoftTeams.app.initialize().then(() => {
            microsoftTeams.pages.config.registerOnSaveHandler(function (saveEvent) {
                microsoftTeams.pages.config.setConfig({
                    suggestedDisplayName: "Chambr",
                    contentUrl: url,
                });
                saveEvent.notifySuccess();
            });

            microsoftTeams.pages.config.setValidityState(true);
        });
    }

    const handleCodeChange = e => {
        setCodeError(null)
        e.persist()
        if (e.target.value.length === 0) {
            setupConfig(`${window.location.origin}/game`)
        } else if (e.target.value.length === 6) {
            axios.get(`${process.env.REACT_APP_SERVER_URL}/api/Code/${e.target.value}/`)
                .then(() => setupConfig(`${window.location.origin}/game?code=${e.target.value}`))
                .catch(() => {
                    microsoftTeams.pages.config.setValidityState(false)
                    setCodeError("Invalid code.")
                })
        } else {
            microsoftTeams.pages.config.setValidityState(false);
        }
    }

    return (
        <Layout shouldBackgroundRotateForward={true}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes["joingame-wrapper"]}
            >
                <div className={classes["joingame-form"]}>
                    <div className={classes["joingame-title"]}>
                        You can pre-type in the code for everybody
                    </div>

                    <form>
                        <label className={classes["label-input"]}>
                            <StyledInput
                                onChange={handleCodeChange}
                                type="text"
                                placeholder="Room code"
                            />
                            {codeError &&
                                <span className={classes["validation-errors"]}>
                                    {codeError}
                                </span>
                            }
                        </label>
                    </form>
                </div>
            </Grid>
        </Layout>
    )
}

const useStyles = makeStyles(theme => ({
    'joingame-wrapper': {
        width: '100%',
        height: '100%',
        maxWidth: 300,
        margin: '0 auto'
    },
    'joingame-title': {
        paddingBottom: '10vh',
        paddingTop: '8vh',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center',
        fontSize: '20px'
    },
    'joingame-form': {
        textAlign: 'center',
        width: '100%',
        maxWidth: 300,
        margin: '0 auto'
    },
    'validation-errors': {
        fontSize: '12px',
        color: '#cf0000'
    },
    'label-input': {
        paddingBottom: '2vh',
        display: 'block'
    },
    'joingame-button': {
        paddingTop: '5vh'
    },
}))