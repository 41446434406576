import React from 'react'
import PropTypes from 'prop-types'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import AddIcon from '@material-ui/icons/Add'


const SelectablePreset = ({
  preset = null,
  handleSelect
}) => {
  const classes = useStyles({ preset })

  return (
    <div className={classes["preset-item"]}>
      <Grid
        container
        className={classes["preset-inner"]}
        alignItems="center"
      >
        <Grid
          item
          xs={10}
          container
          alignItems="center"
        >
          <b className={classes["preset-overflow"]}>
            {preset.name}
          </b>
        </Grid>

        <Grid
          item
          xs={2}
          container
          justifyContent="flex-end"
          alignItems="center"
        >

          {preset.isSelected &&
            <CheckIcon classes={{ root: classes["preset-selected"] }} />
          }

          {!preset.isSelected &&
            <IconButton
              aria-label="add"
              onClick={() => handleSelect(preset.id)}
              classes={{ root: classes["preset-button"] }}
            >
              <AddIcon classes={{ root: classes["preset-icon"] }} />
            </IconButton>
          }
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  'preset-item': {
    fontFamily: '"SegoeBold", sans-serif',
    marginBottom: 15,
    '&:last-child': {
      marginBottom: 0
    }
  },
  'preset-inner': {
    transition: 'all 0.2s ease',
    backgroundColor: ({ preset }) => preset.isSelected ? theme.palette.primary.main : theme.palette.secondary.main,
    height: 40,
    padding: '3px 20px',
    borderRadius: '100px',
    color: ({ preset }) => preset.isSelected ? theme.palette.info.main : '#ffffff',
  },
  'preset-overflow': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingRight: 10,
    display: 'inline-block',
    fontSize: '12px'
  },
  "preset-challenge": {
    fontSize: '12px'
  },
  'preset-button': {
    padding: 0,
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    }
  },
  'preset-icon': {
    color: '#ffffff'
  },
  'preset-selected': {
    color: ({ preset }) => preset.isSelected ? theme.palette.info.main : theme.palette.primary.main
  }
}))

SelectablePreset.propTypes = {
  preset: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
}

export default React.memo(SelectablePreset)
