import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Weight = (props) => {
  const {
    fill = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={31.524}
      height={31.524}
      viewBox="0 0 31.524 31.524"
      className={className}
      {...others}
    >
      <path
        data-name="Icon awesome-weight-hanging"
        d="M31.418 27.452l-4.5-17.987a2.006 2.006 0 00-1.9-1.584h-3.71a5.911 5.911 0 10-11.1 0H6.5a2.006 2.006 0 00-1.9 1.584L.106 27.452a3.176 3.176 0 002.851 4.072h25.61a3.176 3.176 0 002.851-4.072zM15.762 7.881a1.97 1.97 0 111.97-1.97 1.973 1.973 0 01-1.97 1.97z"
        transform="translate(0 -.001)"
        fill={fill}
      />
    </svg>
  )
}

Weight.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
}

export default Weight