import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Avatar4 = (props) => {
  const {
    fill = DEFAULT_PRIMARY_COLOR_CODE,
    stroke = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
      className={className}
      {...others}
    >
      <rect
        x={176.92}
        y={176.92}
        width={646.15}
        height={646.15}
        rx={111.77}
        transform="rotate(45 500.004 499.998)"
        fill={fill}
      />
      <path
        className="cls-2"
        d="M580.81 570.43a29.81 29.81 0 01-59.61 0M467.83 461.89a29.81 29.81 0 01-59.61 0M408.22 461.89a29.81 29.81 0 1159.61 0M532.35 461.89a29.81 29.81 0 1159.61 0M592 461.89a29.81 29.81 0 01-59.61 0"
        fill="none"
        stroke={stroke}
        strokeWidth="25px"
        strokeMiterlimit="10"
      />
      <path
        className="cls-2"
        d="M367.97 562.81L625.17 562.81"
        fill="none"
        stroke={stroke}
        strokeWidth="25px"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

Avatar4.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string
}

export default Avatar4
