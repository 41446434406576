import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Approve = (props) => {
  const {
    fillDark = DEFAULT_SECONDARY_COLOR_CODE,
    fillLight = DEFAULT_PRIMARY_COLOR_CODE,
    stroke = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={118}
      height={117}
      viewBox="0 0 118 117"
      className={className}
      {...others}
    >
      <g data-name="Approve button" transform="translate(-138 -311)">
        <rect
          data-name="Rectangle 165"
          width={111}
          height={111}
          rx={13}
          transform="translate(145 317)"
          fill={fillDark}
        />
        <rect
          data-name="Rectangle 163"
          width={111}
          height={111}
          rx={13}
          transform="translate(138 311)"
          fill={fillLight}
        />
        <path
          data-name="Path 531"
          d="M168 366.486l15.575 15.574 36.554-37.158"
          fill="none"
          stroke={stroke}
          strokeWidth="15px"
        />
      </g>
    </svg>
  )
}

Approve.propTypes = {
  fillDark: PropTypes.string,
  fillLight: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string
}

export default Approve
