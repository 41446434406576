import React from 'react'
import { NavLink as Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

import Vip from '../../Svg/Vip'
import Blocks from '../../Svg/Blocks'
import CreateIcon from '@material-ui/icons/Create'
import AssignmentIcon from '@material-ui/icons/Assignment'

const Menu = () => {
  const classes = useStyles()

  return (
    <>
      <Link
        to="/preset"
        className={classes["menu-item"]}
        activeClassName="active-link"
      >
        <CreateIcon className={classes["menu-icon"]} />
        Create preset
      </Link>
      <Link
        to="/presets"
        className={classes["menu-item"]}
        activeClassName="active-link"
      >
        <AssignmentIcon className={classes["menu-icon"]} />
        Preset library
      </Link>
      <Link
        to="/chambr"
        className={classes["menu-item"]}
        activeClassName="active-link"
      >
        <Vip className={classes["menu-icon"]} />
        Create chambr
      </Link>
      <Link
        to="/chambrs"
        className={classes["menu-item"]}
        activeClassName="active-link"
      >
        <Blocks className={classes["menu-icon"]} />
        Chambrs
      </Link>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  'menu-item': {
    color: theme.palette.info.main,
    fontFamily: '"SegoeBlack", sans-serif',
    fontSize: '14px',
    lineHeight: '14px',
    textAlign: 'center',
    textDecoration: 'none',
    height: 70,
    width: '95%',
    maxWidth: 300,
    padding: '0 5px',
    backgroundColor: '#E4E4E4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    marginBottom: '5vh',
    transform: 'scale(1)',
    transition: 'font-size 0.1s linear, transform 0.1s linear',
    '&:last-child': {
      marginBottom: 0,
      [theme.breakpoints.between('sm', 'sm')]: {
        marginRight: '0',
      },
    },
    '&.active-link': {
      transition: 'font-size 0.1s linear, transform 0.1s linear',
      fontSize: '17px',
      backgroundColor: theme.palette.primary.main,
      transform: 'scale(1.1)'
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      margin: '0 15px 0 0',
      flex: 1
    },
    [theme.breakpoints.between('sm', 'md')]: {
      border: '1px solid #eeeeee'
    },
  },
  'menu-icon': {
    width: 30,
    marginRight: 10,
    fill: theme.palette.secondary.main,
    '& path': {
      fill: theme.palette.secondary.main,
    },
  }

}))

export default Menu