import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { chooseDifficulty } from '../../../actions/index'

import { Box, makeStyles, useTheme } from '@material-ui/core'
import Layout from './Layout'
import Roles from './Roles'
import SpeakerIcon from '../../Svg/SpeakerIcon'
import Approve from '../../Svg/Approve'
import Disapprove from '../../Svg/Disapprove'


export default function Difficulty() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const classes = useStyles()
    const gameState = useSelector(d => d.game)
    const speaker = gameState.game.speaker
    const isSpeaker = speaker.player.connectionId === gameState.player.connectionId
    const currentPlayer = gameState.game.players.find(d => d.connectionId === gameState.player.connectionId)
    const hasRating = gameState.game.template.hasRating
    const hasFeedback = gameState.game.template.hasFeedback

    const handleYes = e => {
        dispatch(chooseDifficulty(true))
    }

    const handleNo = e => {
        dispatch(chooseDifficulty(false))
    }

    if (!isSpeaker) {
        return <Roles />
    }

    return (
        <Layout
            currentPlayer={currentPlayer}
            shouldBackgroundRotateBackward={true}
        >
            <div className={classes["prompt-wrapper"]}>
                <Box textAlign="center">
                    <SpeakerIcon
                        className={classes["top-icon"]}
                        fillDark={theme.palette.secondary.main}
                        fillLight={theme.palette.primary.main}
                        stroke={theme.palette.secondary.main}
                    />

                    <div className={classes["difficulty-title"]}>
                        {hasRating && "For bonus Chambr points do you want to increase the difficulty?"}
                        {!hasRating && hasFeedback && "For extra glory and good feedback, do you want to increase the difficulty?"}
                    </div>

                    <div>
                        <button onClick={handleYes} className={classes["action-button"]}>
                            <Approve
                                fillDark={theme.palette.secondary.main}
                                fillLight={theme.palette.primary.main}
                                stroke={theme.palette.secondary.main}
                                className={classes["difficulty-icon"]}
                            />
                            <p className={classes["difficulty-label"]}>Yes</p>
                        </button>
                    </div>

                    <div>

                        <button onClick={handleNo} className={classes["action-button"]}>
                            <Disapprove
                                fillDark={theme.palette.secondary.main}
                                fillLight={theme.palette.primary.main}
                                stroke={theme.palette.primary.main}
                                className={classes["difficulty-icon"]}
                            />
                            <p className={classes["difficulty-label"]}>No</p>
                        </button>
                    </div>
                </Box>
            </div>
        </Layout>
    )
}

const useStyles = makeStyles(theme => ({
    'prompt-wrapper': {
        width: '100%',
        maxWidth: 300,
        margin: '0 auto'
    },
    'top-icon': {
        width: 40,
        padding: '5vh 0',
        height: 'auto'
    },
    'difficulty-title': {
        fontSize: '16px',
        lineHeight: '20px',
        paddingBottom: '5vh',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center'
    },
    'difficulty-label': {
        fontSize: '23px',
        textTransform: 'uppercase',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center'
    },
    'difficulty-icon': {
        width: 80,
        height: 'auto'
    },
    'action-button': {
        paddingBottom: '5vh',
        background: 'none',
        border: 'none',
        cursor: 'pointer'
    }
}))
