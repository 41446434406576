import React from 'react'
import {
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const BluePath = React.forwardRef((props, ref) => {
  const {
    stroke = DEFAULT_SECONDARY_COLOR_CODE
  } = props
  return (
    <svg
      ref={ref}
      className="blue-path"
      xmlns="http://www.w3.org/2000/svg"
      width={189.31}
      height={135.373}
      viewBox="0 0 189.31 135.373"
      {...props}
    >
      <path
        data-name="Path 479"
        d="M176.369 81.353l-73.092 46.124a16.158 16.158 0 01-17.245 0L12.939 81.353a16.158 16.158 0 010-27.329L86.032 7.9a16.158 16.158 0 0117.245 0l73.092 46.124a16.157 16.157 0 010 27.329z"
        fill="none"
        stroke={stroke}
        strokeMiterlimit={10}
        strokeWidth="10.81px"
      />
    </svg>
  )
})

export default BluePath