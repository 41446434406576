import React from "react";
import { useHistory } from "react-router-dom";
import { Button, makeStyles } from "@material-ui/core";

export default function Terms() {
  const classes = useStyles()
  const history = useHistory();
  return (
    <>
      <section className={classes['policyContent']}>
        <nav>
          <Button className={classes['backBtn']} onClick={() => history.goBack()}><b>← back to chambr</b></Button>
        </nav>
        <br />
        <br />
        <h3>Chambr Terms & Conditions</h3>
        <h4>Last updated on November 18th 2022</h4>
        <p>Thank you for choosing Chambr! These are our general terms and conditions. Please read them carefully. </p>
        <br />
        <p><b>1. Acceptance</b></p>
        <p>By  installing,  accessing  and/or  using  this  software,  you  agree  to  be  bound  by  this Agreement. If you are accepting these terms on behalf of another person or company or other  legal  entity,  you  represent  and  warrant  that  you  have  full  authority  to  bind  that person, company or legal entity to these terms. If you do not agree to these terms do not install,  access  and/or  use  the  software  and/or  stop  using  the Software and destroy any copies of the Software in your possession or control.</p>
        <br />
        <p><b>2. Definitions</b></p>
        <p>When  used  in  this  Agreement,  the  following  terms  shall  have  the  respective  meanings indicated,  such  meanings  to  be  applicable  to  both  the  singular  and  plural  forms  of the terms defined in this Agreement:</p>
        <p><b>"Admin Account"</b> means the account from which the End User can customize the settings of each gameplay session in Chambr Learning Platform.</p>
        <p><b>"Agreement"</b> means this end user licence agreement, including any amendments to this end  user  licence  agreement  from  time  to  time;  also,  the  Product  Entitlement  and  any Software Documentation shall be considered parts of this Agreement;</p>
        <p><b>"Content"</b> means the learning content that is uploaded inside the platform.</p>
        <p><b>"Data Protection Laws"</b> means any Laws applicable to End User and Playlearn Concept SRL, relating to data security, data protection, and/or privacy, including Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to processing of Personal Data and the free movement of that data (“GDPR”) if applicable.</p>
        <p><b>"Documentation"</b> means the documentation for the Software produced by the Licensor and delivered or made available by the Licensor to the User, if necessary;</p>
        <p><b>"Force Majeure Event"</b> means an event, or a series of related events, that is outside the reasonable  control  of  the  party  affected  (including  failures  of the internet or any public telecommunications  network,  hacker  attacks,  denial  of  service  attacks,  virus  or  other malicious  software attacks or infections, power failures, industrial disputes affecting any third  party,  changes to the law, disasters, explosions, fires, floods, riots, disease or medical epidemics, pandemics or outbreaks, terrorist attacks and wars and others);</p>
        <p><b>"Intellectual  Property  Rights"</b> means  all  intellectual  property  rights  wherever  in  the world,  whether  registrable  or  unregistrable,  registered  or  unregistered,  including  any application or right of application for such rights (and these "intellectual property rights" include  copyright  and  related  rights,  database  rights,  confidential  information,  trade secrets, know-how, business names, trade names, trademarks, service marks, passing off rights,  unfair  competition  rights,  patents,  petty  patents,  utility  models,  semi-conductor topography rights and rights in designs);</p>
        <p><b>"Licensor"</b> means Playlearn Concept SRL, a Romanian company, registered with the Trade  Registry  Office  attached  to  Bucharest  under  no.   No. J40/18258/30.10.2017,  having  sole registration  code  38420290, headquartered in Bucharest, Nicolae Constantinescu Street, No 5.</p>
        <p><b>"License  fee"</b> means the amount that the User shall pay to the Licensor in respect with the license granted under the terms of this Agreement.</p>
        <p><b>"Personal Data"</b> means any information relating to an identified or identifiable natural person, i.e., one who can be identified directly or indirectly by referencing an identifier such as a name, an identification number, location data, an online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person.</p>
        <p><b>"Services"</b> means   any   services   that   the   Licensor  provides  to  the  User,  or  has  an obligation to provide to the User, under this Agreement;</p>
        <p><b>"Software"</b> means the learning platform, Chambr. </p>
        <p><b>"Software   Specification"</b> means   the   specification   for   the   Software  set  out  in  the Documentation;</p>
        <p><b>"Source  Code"</b> means  all  executable  and  object  code,  program  files,  software  and programme     modules,     and     associated    documentation    (including    any    images, photographs,  library  objects  and  texture  files,  animations,  video,  audio, music, and text incorporated into the Software)</p>
        <p><b>"Update"</b> means a hotfix, patch or minor version update to the Software; <b>"Upgrade"</b> means a major version upgrade of the Software;</p>
        <p><b>"End  User"</b>and <b>"you"</b> means the person or entity that has purchased the Software and to whom the Licensor grants a right to use the Software under this Agreement;</p>
        <br />
        <p><b>3. License Grant</b></p>
        <p>Subject to the terms and conditions of this Agreement and the payment to any applicable License  fee,  Licensor  hereby  grants  to  you a revocable, non-exclusive, non-transferable, limited right to use the Software for your own internal business operations.</p>
        <p>The use of the Software is subject to the limitations and prohibitions set out and referred to in this Agreement and the Product Entitlement.</p>
        <p>For the avoidance of any doubt, this Agreement confers no title or ownership and is not a sale  of  any  rights  in  the  Software.  All  rights  not  expressly  granted  to  you  are  reserved solely to the Licensor and/or his partners.</p>
        <br />
        <p><b>4. Term and territory</b></p>
        <p>This  License  shall  be  effective  for  a  limited  period  of  time  (“Term”),  if  such  Term  is  set forth in the Product Entitlement; otherwise this License shall be considered perpetual. In the  situation  that  the  License  is  effective  for  a  limited  period  of  time,  the  Term  will commence within the date you purchase the Software.</p>
        <p>The  License  shall  be  effective  for  the  territories  (“Territory”)  stipulated  in  the  Product Entitlement,  if  such  Territory  is  stipulated,  otherwise  the  License  shall  be  applicable worldwide.</p>
        <br />
        <p><b>5. Restrictions on Transfer</b></p>
        <p>You  may  not  assign  your  rights  and  obligations  under  this  Agreement,  or  redistribute, encumber, sell, rent, lease, sublicense or otherwise transfer your rights to the Software.</p>
        <br />
        <p><b>6. License terms and limitations of use</b></p>
        <p><b>Product entitlement:</b> The use of the Software depends on the license you purchased, as specified  in  the  Product  Entitlement  letter  that  you  received,  and  is  subject  to  this Agreement and any other documents related.</p>
        <p><b>Multiple admin accounts:</b> If you purchased a license which supports multiple admin accounts, you will be able to use the Software within the limits  of  the  number  of  admin accounts  your  license  has  been purchased for.</p>
        <p><b>Copies of  the software:</b> You  may  create  one  copy  of  the  Software  as  reasonably necessary for backup, archival or disaster recovery purposes.</p>
        <p>You shall be responsible for the security of copies of the Software supplied to you under this  Agreement  (including  the  backup  copy  created  from  such  copies).  You  shall use all reasonable endeavours (including all reasonable security measures) to ensure that access to such copies is restricted to persons authorised to use them under this Agreement.</p>
        <p><b>Software Updates and Upgrades: </b> We have the right but not the obligation to offer you access  to  the  Software`s  Updates  and  Upgrades.  Usually  we  will  grant  you  the right to use  the  updated  and/or  upgraded  version  of  the  Software,  by  request,  but  only  if  the updated  and/or  upgraded  version  is  compatible  with  the  version  of  Software  you  have the right to use.</p>
        <p><b>Software's  Source  code: </b> Under no circumstances, you are not granted rights regarding the Software’s Source Code (such as: right to access, use, modify, copy or sell, resell, rent, lease, loan, license, supply, publish, distribute the Software’s Source Code).</p>
        <p><b>Limitations of use:</b></p>
        <p>You  must  not  sell,  resell,  rent,  lease,  loan,  supply, publish, distribute or redistribute the Software;</p>
        <p>You  must  not  sub-license and must not purport to sub-license any rights granted under this Agreement;</p>
        <p>You    must   not   make   any   modification,   adaptation,   improvement,   enhancement, translation and/or derivative work from the Software;</p>
        <p>You   must   not   decompile,  disassemble,  reverse  engineer,  or  attempt  to  decompile, disassemble or reverse engineer, the Software;</p>
        <p>You  must  not  remove  any  product  identification  or  proprietary  rights  notices  of  the Software or Documentation (including any notice of copyright or trademark);</p>
        <p>Except with Licensor prior written permission, you must not publish any performance or benchmark tests or analysis relating to the Software;</p>
        <p>You  must  not  otherwise  use  or  copy  the  Software  except  as  expressly  provided  herein and you must use the Software only for the purposes that were designed and intended.</p>
        <p>You shall avoid using the Software for any illegal activity. You shall respect any applicable law, rule or regulation in connection with your access and/or use of the Software.</p>
        <p>You  must  not  use  the  Software  in order to create a product, service or software that is, directly  or  indirectly,  competitive  with  or  in  any  way  a  substitute  for  any  products, services and/or software offered by the Licensor.</p>
        <p>You must not use, reproduce nor alter any logos or trademarks regarding the Software or any   materials   issued,   downloaded   or   distributed   in   conjunction  with  the  Software (whether  or  not  such  logos  or  trademarks  are  the  property  of  Licensor),  without  the Licensor'’'s prior written approval.</p>
        <p>You  must  not take any action that might diminish or cancel any Licensor rights over the Software.</p>
        <p>You  must  not,  nor  allow  and/or  request  any  third  party  to  take  any  action  stipulated above.</p>
        <p><b>The use of the Software by third parties:</b></p>
        <p>You may not permit the use of the Software by third parties, unless:</p>
        <p>a) The   License   was   purchased   by  your  company  and  you  grant  access  to  the Software to your personnel (employees and/or independent contractors) and </p>
        <p>b) You don't exceed your Product entitlement; or</p>
        <p>c) You  permit third parties to access some functions of the Software, namely the functions  that  were  designed  in order to permit you to use the Software in collaboration with other persons.</p>
        <p>If  the  situation  ruled  under  letter  c above shall be applicable, the third party that might access  and  use  the  Software:  a)  shall  respect  the provisions of this Agreement but b) is not allowed to claim any rights under this Agreement.</p>
        <br />
        <p><b>7. Support Services</b></p>
        <p>Licensor and/or his partners will provide you Support Services only if you purchased such services.</p>
        <p>The  Support  Services  will  be  provided  with  reasonable  skill and care and in accordance with  the  conditions  stipulated  in  the  Product  Entitlement  and/or  the  Support  Services Agreement, if applicable.</p>
        <br />
        <p><b>8. Intellectual Property Rights</b></p>
        <p><b>8.1. Software</b></p>
        <p>Licensor  owns  all  the  intellectual  Property  Rights  over  the  Software.  Nothing  in  this Agreement  shall  operate  to  assign  or  transfer  any  Intellectual  Property  Rights from the Licensor to you, or from you to the Licensor.</p>
        <p>The  structure,  organization  and  code  of  the  Software  are  valuable  trade  secrets  and confidential information of the Licensor. The Software, including the Software’s logos and trademarks,  are  proprietary  to  the  Licensor  and  protected  by  law,  including  without limitation the copyright laws of Romania and other countries, and by international treaty provisions.</p>
        <p>You  shall  respect  the  Licensor's  Intellectual  Property  Rights,  as  stipulated  under  this Agreement and the applicable laws.</p>
        <p><b>Third-party  claims;  Remedies  and  liability:</b> Licensor  warrants  you  that  the  Software, when  used  in  accordance with this Agreement, will not infringe the Intellectual Property Rights  of  any  person.  If  the  Licensor  reasonably  determines,  or  any  third  party alleges, that  the  use  of  the  Software  in  accordance  with  this  Agreement  infringes  any  person's Intellectual Property Rights, the Licensor may at its own cost and expense and at its own discretion:</p>
        <p>a.procure  for  you the right to continue to use the Software in accordance with this Agreement;</p>
        <p>b.modify   the   Software  in  such  a  way  that  it  no  longer  infringes  the  relevant Intellectual Property Rights;</p>
        <p>c.replace  the  Software  with  another software that is similar in functionality and performance or</p>
        <p>d.refund the purchase price paid by you for the infringing Software.</p>
        <p>You  understand  and  accept  that  this  clause  states  the  Licensor’s  sole  liability  and  your exclusive remedy for any Intellectual Property Rights infringement claims.</p>
        <p>The  Licensor’s  obligations,  as  stipulated  above,  are  not  applicable  if  the  Software  is provided to you without a Licence Fee and/or you use the Software during a “trial period”.</p>
        <p><b>8.2. Content</b></p>
        <p>a.You have usage rights over the content included inside your Software subscription. The intellectual properties of that content stays with Playlearn Concept SRL.</p>
        <p>b.If you do derivative work from the content inside your Software subscription, you own the intellectual property, but that does not limit our rights to use the initial content and does not alter the intellectual property rights we have over that initial content. </p>
        <p>c.You have exclusive intellectual property rights on the content that you create inside Chambr. </p>
        <p>d.If you purchase custom content developed based on your company data, the intellectual property stays with you. </p>
        
        <br />
        <p><b>9. Data</b></p>
        <p>For the avoidance of any doubt, you retain all rights to materials or information, including your Data, Trademarks and all Intellectual Property Rights related to any of the foregoing, provided to Licensor in the performance of this Agreement. Nothing in this Agreement shall be construed to grant the Licensor any license or other right in regard to the materials or information, including your Data, Trademarks and all Intellectual Property Rights related to any of the foregoing. Licensor shall not store or transfer for storage any of your Data without your prior written consent. Licensor has no Intellectual Property Rights or other claim to your Data and will cooperate with you to protect your Intellectual Property Rights and Data.</p>
        <p>Each party will comply with Data Protection Laws (as defined herein). Without limiting the foregoing, each party will:</p>
        <p>a.not use or share Personal Data received from the other party (or its employees) for any purpose for which it has not obtained consent; </p>
        <p>b.establish independent procedures for managing and responding to any communication from a customer seeking to exercise its rights under Data Protection Laws; </p>
        <p>c.provide reasonable assistance to the other in responding to any requests, investigation, consultation, or claims from a customer, regulator, or supervisory authority concerning Data Protection Law;</p>
        <p>d.take appropriate security measures that are required by Data Protection Laws, and in accordance with good industry practice relating to data security; and</p>
        <p>e.take- refrain from transmitting unsolicited commercial communications in any manner that would violate applicable Laws. </p>
        <p>Licensor will comply with Data Protection Laws and enter into such additional agreements as may be needed relative to any use, sharing, and processing of End User data required to operationalize the software and related requirements.</p>

        <br />
        <p><b>10. Warranties</b></p>
        <p>Licensor  warrants  to  you  that:  a)  it  has  the  legal  right  and  authority  to  enter  into  this Agreement   and   to   perform   its   obligations   under   the   Agreement;   b)   the   Software incorporates security features reflecting the requirements of good industry practice.</p>
        <p>Also,  to  the  best  Licensor  knowledge  and  beliefs,  the  Software  is  supplied  free  from viruses,   worms,   Trojan   horses,   ransomware,   spyware,   adware   and  other  malicious software programs.</p>
        <p>You  warrant  to  the  Licensor  that:  a)  you have the legal right and authority to enter into this Agreement and to perform your obligations under the Agreement; b) you will use the Software  in  accordance  with  the  provisions  of  this  Agreement  and  the  applicable  laws, rules and regulations, for the purpose that it has been developed.</p>
        
        <br />
        <p><b>11. Acknowledges and warranty limitations</b></p>
        <p><b>Acknowledges: </b> You acknowledge that software is never wholly free from defects, errors and  bugs;  and  subject  to  the  other  provisions  of  this  Agreement,  Licensor  gives  no warranty or representation that the Software will be wholly free from defects, errors and bugs. Licensor does not warrant that the Software will operate uninterrupted.</p>
        <p>You  acknowledge  that  software  is  never  entirely  free  from  security  vulnerabilities;  and subject   to   the   other   provisions   of   this   Agreement,   Licensor   gives  no  warranty  or representation that the Software will be entirely secure.</p>
        <p>You acknowledge that the Software is only designed to be compatible with that software specified  as  compatible  in  the  Documentation;  and  the  Licensor  does  not  warrant  or represent that the Software will be compatible with any other software.</p>
        <p>Chambr is never liable for your Internet connection and the network that is being used to gain access to our services. It is your responsibility to fulfil the technical requirements needed to use our services and ensure that you have the equipment and software needed to ensure the performance of our platform. </p>
        <p><b>Limited  Warranty: </b> Licensor  warrants  that  the  Software  will  perform  substantially  in accordance  with  the  Documentation  for  the  entire  period for which you purchased the Support Services.</p>
        <p><b>Exclusion of Warranty: </b> The above Limited Warranty will not apply if:</p>
        <p>a.the    Software    is    not    used    in    accordance    with    this    Agreement    or    the Documentation;</p>
        <p>b.the  Software  or  any  part  thereof has been modified by any entity other than the Licensor or without Licensor’s prior written consent;</p>
        <p>c.a malfunction in the Software has been caused by any equipment or software not supplied by the Licensor or;</p>
        <p>d.The  Software  is  provided  to  you  without  a  Licence  Fee  and/or  you  use  the Software during a “trial period”.</p>
        <p>Additionally, under no circumstance, the Licensor will not be liable to you:</p>
        <p>a.in respect of any losses arising out of a Force Majeure Event;</p>
        <p>b.in  respect  of  any  loss  of  profits,  anticipated  savings,  revenue,  income,  business, contracts or opportunities;</p>
        <p>c.in respect of any loss of personnel (employees and/or independent contractors) or work stoppage;</p>
        <p>d.in  respect  of  any  loss  or  corruption  of  any  data,  database  or  software  and/or computer   failure   or   malfunction   and/or   costs   of   procuring   substitute   software   or services;</p>
        <p>e.in respect of any loss or damage produced to any third party;</p>
        <p>f.in respect of any special, indirect or consequential loss or damage.</p>

        <br />
        <p><b>12. Exclusive Remedies</b></p>
        <p><b>Licensor's remedies: </b> In case of any breach of the above limited warranty, Licensor will:</p>
        <p>a.repair or replace the Software or</p>
        <p>b.if   such   repair   or   replacement   would   in   Licensor’s   opinion   be   commercially unreasonable, refund you the purchase price paid for the Software.</p>
        <p>All of the parties' warranties and representations in respect of the subject matter of this</p>
        <p>Agreement are expressly set out in this Agreement. To the maximum extent permitted by applicable  law,  no  other warranties or representations concerning the subject matter of this Agreement will be implied into the Agreement or any related contract.</p>
        <p>Therefore, you acknowledge that the above warranties are your exclusive warranties and replace all other warranties or conditions, express or implied, including but not limited to warranties or conditions of merchantability, satisfactory quality, fitness for a particular purpose, title and non-infringement.</p>
        <p>Except for the limited warranty set forth above, the software is provided “as is” and the Licensor makes no warranty or guarantee as to its use or performance and does not warrant or guarantee that the operation of the software will be fail safe, uninterrupted or free from errors or defects or that the Software will protect against all possible threats.</p>
        <p><b>Exceptions: </b>Some states or jurisdictions do not allow the exclusion of express or implied warranties, so the above disclaimer may not apply to you. In that event, such express or implied  warranties  shall  be  limited  in  duration  to the warranty period (or the minimum period required by the applicable law).</p>
        <p><b>User's  remedies: </b>You  shall  indemnify  and  shall  keep  indemnified  the  Licensor  against any and all liabilities, damages, losses, costs and expenses (including legal expenses and amounts  reasonably  paid  in  settlement  of  legal  claims)  suffered  or  incurred  by  the Licensor  and  arising  directly  or  indirectly  as  a  result  of  any  of  yours  breach  of  this Agreement.</p>

        <br />
        <p><b>13. Termination</b></p>
        <p>Without  prejudice  to  your  payment  obligations,  you  may  terminate  your  license  at  any time by uninstalling the Software.</p>
        <p>Licensor  may  terminate  this  Agreement  immediately  by  giving  you  written  notice  of termination if:</p>
        <p>a.you   commit   any   material   breach   of   the   Agreement,  and  the  breach  is  not remediable  or  the  breach  is  remediable,  but  you  fail  to  remedy  the  breach  within  the period of 10 (ten) days following the giving of a written notice requiring the breach to be remedied; or</p>
        <p>b.you persistently materially breach the Agreement.</p>
        <p>The  license  granted to you shall terminate upon the termination of this Agreement and, accordingly,  you  must  immediately  cease  to  use  the  Software  upon  the  termination  of this Agreement.</p>
        <p>Within  10  (ten)  days  following  the  termination  of  this  Agreement,  you  must irrevocably delete from all computer systems in its possession or control all copies of the Software.</p>
        
        <br />
        <p><b>14. General Provisions</b></p>
        <p><b>Entire Agreement: </b>This  Agreement  shall  constitute  the  entire  agreement  between  the parties  in  relation  to  the  subject  matter  of  this  Agreement,  and  shall  supersede  all previous  agreements,  arrangements and understandings between the parties in respect of that subject matter.</p>
        <p><b>Applicable law and jurisdiction: </b>This Agreement shall be governed by and construed in accordance  with  Romanian  law.  The  courts  of  Bucharest,  Romania  shall  have  exclusive jurisdiction to adjudicate any dispute arising under or in connection with this Agreement.</p>
        <p><b>Legal effect: </b>This  agreement  describes  certain  legal  rights,  in  accordance  with  the Romanian laws. You may have other rights under the laws of your state or country and/or with   respect   to   the   party   from   whom   you   acquired   the   software.  Therefore,  this Agreement  does  not  change  your  rights  under  the  laws  of  your  state  or  country  if  the laws of your state or country do not permit it to do so.</p>
        <p><b>Agreement assignment: </b>Licensor  may  assign  its  rights  and  obligations  under  this Agreement  to  any  party  for  any  reason  whatsoever.  You  may  assign  your  rights  under this Agreement, in whole or in part, to any party, but only with the prior written consent of the Licensor.</p>
        <p><b>Force Majeure: </b> No party shall be liable for, nor shall any party be considered in breach of this Agreement due to any failure to perform its obligations under this Agreement as a result  of  a  cause  beyond  its  control  (a  Force  Majeure  Event).  In  such a situation,  the applicable party shall immediately notify the other party of such Force Majeure Event.</p>
        <p><b>Open source Software:  </b>The  product  may  include  programs  or  code  that  are  licensed under  an  Open-Source  Software  (“OSS”)  license  model.  OSS  programs  and  code  are subject  to  the  terms,  conditions  and  obligations  of  the  applicable  OSS  license  and  are specifically excluded from all warranty and support obligations described elsewhere in this agreement. </p>
        <br />
        <br />
      </section>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  policyContent: {
    color: theme.palette.info.main,
    display: 'block',
    margin: '0 auto',
    maxWidth: '600px',
    '> p': {
      marginTop: '5px'
    }
  },
  backBtn: {
    color: theme.palette.info.main,
  }
}))