import React from "react"

const SquareBackground = React.forwardRef((props, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={855.51}
      height={597.003}
      viewBox="0 0 855.51 597.003"
      {...props}
    >
      <path
        data-name="Path 502"
        d="M2053.125 1872.508l-350.313 221.062a77.439 77.439 0 01-82.652 0l-350.319-221.062c-48.148-30.387-48.148-100.6 0-130.982l350.319-221.062a77.439 77.439 0 0182.652 0l350.313 221.062c48.153 30.386 48.153 100.595 0 130.982z"
        transform="translate(-1233.73 -1508.515)"
        fill={props.fill}
      />
    </svg>
  )
})

export default SquareBackground
