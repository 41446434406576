import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { getPresets, updatePresetName } from '../../../actions/admin'
import PropTypes from 'prop-types'

import { makeStyles, Tab, IconButton, useTheme } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import StyledInput from '../../General/StyledInput'
import AddIcon from '@material-ui/icons/Add'

const CustomTab = ({
  item,
  canEdit = false,
  ...others
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [tabName, setTabName] = useState('')
  const presetTabInput = useRef(null)

  useEffect(() => {
    if (presetTabInput.current) {
      presetTabInput.current.focus()
    }
    setTabName(item.name)
    //eslint-disable-next-line
  }, [isEditOpen])

  const handleToggleEditTab = () => {
    setIsEditOpen(prevValue => !prevValue)
  }

  const handleChangeTabName = e => {
    e.persist()
    setTabName(prevState => {
      if (prevState !== e.target.value) {
        return e.target.value
      }
    })
  }

  const handleSaveTabName = async () => {
    if (tabName.length > 0 && item.name !== tabName) {
      await dispatch(updatePresetName(item.id, tabName))
      await dispatch(getPresets())
    }
    setIsEditOpen(prevValue => !prevValue)
  }

  return (
    <>
      {isEditOpen ?
        <div className={classes["presets-tab-wrapper"]}>
          <StyledInput
            ref={presetTabInput}
            onChange={handleChangeTabName}
            type="text"
            height="38px"
            width="calc(100% - 100px)"
            backgroundColor={theme.palette.primary.main}
            fontColor={theme.palette.info.main}
            value={tabName}
            className={classes["presets-tab-input"]}
          />

          <IconButton
            aria-label="add"
            onClick={handleSaveTabName}
            classes={{ root: classes["presets-edit-button"] }}
          >
            <AddIcon classes={{ root: classes["presets-edit-icon"] }} />
          </IconButton>
        </div> :

        <div className={classes["presets-tab-wrapper"]}>
          <Tab
            key={item.id}
            id={item.id}
            label={item.name}
            className={classes["presets-tab"]}
            {...others}
          />

          {canEdit &&
            <IconButton
              aria-label="edit"
              onClick={handleToggleEditTab}
              classes={{ root: classes["presets-edit-button"] }}
            >
              <EditIcon classes={{ root: classes["presets-edit-icon"] }} />
            </IconButton>
          }
        </div>
      }
    </>
  )
}

const useStyles = makeStyles(theme => ({
  'presets-tab': {
    color: theme.palette.info.main,
    textTransform: 'none',
    fontFamily: '"SegoeBlack", sans-serif',
    height: 50,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100px',
    cursor: 'pointer',
    boxShadow: 'none',
    opacity: 1,
    position: 'relative',
    maxWidth: '100%',
    '& span:first-child': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'inline-block',
      width: 'calc(100% - 60px)',
      marginRight: 10
    },
    '&:last-child': {
      marginBottom: 0
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      color: '#ffffff',
      '&+button svg': {
        fill: '#ffffff'
      }
    }
  },
  'presets-tab-input': {
    padding: '6px 50px',
  },
  'presets-edit-icon': {
    color: theme.palette.info.main
  },
  'presets-edit-button': {
    position: 'absolute',
    right: 5,
    zIndex: 1
  },
  'presets-tab-wrapper': {
    position: 'relative',
    marginBottom: 15,
  }
}))

CustomTab.propTypes = {
  item: PropTypes.object.isRequired,
  canEdit: PropTypes.bool
}

export default CustomTab