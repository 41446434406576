import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { giveFeedback } from '../../../actions/index'
import { Grid, LinearProgress, makeStyles, useTheme } from '@material-ui/core'
import Layout from './Layout'
import Coin from '../../Svg/Coin'
import FancyButton from '../../General/FancyButton'
import ListenerIcon from '../../Svg/ListenerIcon'
import OutlinedInput from '../../General/OutlinedInput'


export default function GivingFeedback() {
    const dispatch = useDispatch();
    const theme = useTheme()
    const classes = useStyles()
    const gameState = useSelector(d => d.game)
    const rating = [1, 2, 3, 4, 5]
    const currentPlayer = gameState.game.players.find(d => d.connectionId === gameState.player.connectionId)
    const [note, setNote] = useState(null)
    const [feedback, setFeedback] = useState("")
    const [progress, setProgress] = useState(0)
    const [progressSet, setProgressSet] = useState(false)
    const [seconds, setSeconds] = useState(0)
    const [secondsLeft, setSecondsLeft] = useState(null)
    const [startedAt, setStartedAt] = useState(null)
    const hasRating = gameState.game.template.hasRating
    const hasFeedback = gameState.game.template.hasFeedback

    useEffect(() => {
        if (seconds === 0) {
            if (gameState.game.template.hasFeedback && gameState.game.template.hasRating) {
                setSeconds(95)
            }
            else if (gameState.game.template.hasFeedback) {
                setSeconds(80)
            }
            else {
                setSeconds(15)
            }
        }
        if (!secondsLeft) {
            setSecondsLeft(gameState.game.feedbackSeconds)
            setStartedAt(Date.now())
        }
    }, [seconds, gameState.game.template.hasFeedback, gameState.game.template.hasRating, gameState.game.feedbackSeconds, secondsLeft])

    useEffect(() => {
        if (!progressSet && secondsLeft !== null && startedAt !== null && seconds) {
            const diff = (Date.now() - startedAt) / 1000
            let percent = (secondsLeft + diff) / seconds * 100
            percent = percent > 100 ? 100 : percent
            setProgressSet(true)
            setTimeout(() => {
                setProgress(percent)
                setProgressSet(false)
                if (percent === 100) {
                    const payload = {
                        mark: (note !== null || parseInt(note, 10) !== 0) ? Number(note) : 0,
                        text: (feedback && feedback.length > 0) ? feedback : ""
                    }
                    dispatch(giveFeedback(payload))
                }
            }, 200)
        }
    }, [progress, progressSet, seconds, secondsLeft, startedAt, feedback, note, dispatch])

    const handleFormSubmitted = e => {
        if (note !== null || parseInt(note, 10) !== 0) {
            const payload = {
                mark: Number(note),
                ...(feedback && feedback.length > 0 && { text: feedback })
            }
            dispatch(giveFeedback(payload))
        }
    }

    const handleRatingClick = (e, value) => {
        e.preventDefault()

        setNote(prevState => {
            if (prevState !== value) {
                return value
            }
            return 0
        })
    }

    return (
        <Layout currentPlayer={currentPlayer}>
            <div className="givingfdk-page">
                <ListenerIcon
                    className={classes["givingfdk-icon"]}
                    fillDark={theme.palette.secondary.main}
                    fillLight={theme.palette.primary.main}
                />

                <div className={classes.barWrapper}>
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        classes={{ root: classes.barRoot, bar: classes.barProgress }}
                    />
                </div>

                {hasRating &&
                    <div className={classes["givingfdk-title"]}>
                        Award the speaker<br></br> Chambr points
                    </div>
                }

                <Grid
                    container
                    className={classes["givingfdk-rating"]}
                    direction="column"
                    alignItems="center"
                >
                    {hasRating &&
                        <div className={classes["givingfdk-coins"]}>
                            <Grid container justifyContent="center">
                                {rating.map((number, index) => (
                                    <div
                                        className={classes["givingfdk-circle-wrapper"]}
                                        key={index}
                                        onClick={e => handleRatingClick(e, number)}
                                    >
                                        <Coin
                                            fill={note && (index + 1) <= note
                                                ? theme.palette.secondary.main
                                                : '#979797'
                                            }
                                            fillInner={note && (index + 1) <= note
                                                ? theme.palette.primary.main
                                                : '#e4e4e4'
                                            }
                                            stroke={note && (index + 1) <= note
                                                ? theme.palette.secondary.main
                                                : '#979797'
                                            }
                                        />
                                    </div>
                                ))}
                            </Grid>

                            <span className={classes["validation-errors"]}>
                                {parseInt(note, 10) === 0 ? "A mark is required" : ""}
                            </span>
                        </div>
                    }

                    {hasFeedback &&
                        <OutlinedInput
                            placeholder="Write any feedback here"
                            multiline
                            className={classes.feedbackTextareaRoot}
                            name="feedback"
                            type="text"
                            value={feedback || ""}
                            onChange={(e) => setFeedback(e.target.value)}
                        />
                    }
                </Grid>

                <div className={classes["givingfdk-button"]}>
                    <FancyButton
                        disabled={(hasFeedback && (note === null)) && (hasRating && (parseInt(note, 10) === 0))}
                        label="Done"
                        width="63px"
                        height="44px"
                        labelFontSize="14px"
                        onClick={handleFormSubmitted}
                    />
                </div>
            </div>
        </Layout >)
}

const useStyles = makeStyles((theme) => ({
    barWrapper: {
        margin: '0 auto 15px',
        width: 280,
        height: 32,
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1,
        WebkitMaskImage: '-webkit-radial-gradient(white, black)'
    },
    barRoot: {
        backgroundColor: '#eeeeee',
        boxShadow: `inset 0 0 0 3px ${theme.palette.primary.main}`,
        WebkitBorderRadius: 44,
        MozBorderRadius: 44,
        borderRadius: 44,
        margin: '0 auto 15px',
        height: '100%',
        position: 'relative',
        zIndex: 0,
    },
    barProgress: {
        backgroundColor: theme.palette.primary.main,
    },
    feedbackTextareaRoot: {
        minHeight: 180,
        maxHeight: 180,
        height: 180,
        backgroundColor: '#eeeeee',
        padding: 20,
        borderRadius: 17,
        marginBottom: '5vh',
        width: 250,
    },
    'givingfdk-icon': {
        width: 50,
        height: 'auto',
        display: 'block',
        margin: '0 auto',
        padding: '20px 0 15px 0'
    },
    'givingfdk-title': {
        fontSize: '20px',
        paddingBottom: '5vh',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center'
    },
    'validation-errors': {
        fontSize: '12px',
        color: '#cf0000',
        textAlign: 'center',
        width: '100%',
        minHeight: 16,
        display: 'block',
        padding: '10px 0'
    },
    'givingfdk-circle-wrapper': {
        textAlign: 'center',
        marginRight: 15,
        width: 40,
        height: 40,
        cursor: 'pointer',
        '& svg': {
            transition: 'all 0.3s ease',
            width: '100%',
            height: '100%',
        },
        '& svg *': {
            transition: 'all 0.3s ease'
        },
        '&:last-child': {
            marginRight: 0
        }
    },
    'givingfdk-number': {
        fontFamily: '"SegoeBlack", sans-serif',
        color: theme.palette.info.main,
        fontSize: '25px'
    },
    'givingfdk-button': {},
    'givingfdk-coins': {
        paddingBottom: '3vh'
    }
}));