import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const ExpandCheck = (props) => {
  const {
    fillLight = DEFAULT_PRIMARY_COLOR_CODE,
    fillDark = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={86}
      height={86}
      viewBox="0 0 86 86"
      className={className}
      {...others}
    >
      <g transform="translate(-1743 -523)">
        <circle
          cx={43}
          cy={43}
          r={43}
          transform="translate(1743 523)"
          fill={fillDark}
        />
        <path
          d="M1803.599 542.135l-4.885 5.1-19.691 19.691-5.737-5.525-5.1-5.1-9.987 9.987 5.1 5.1 10.624 10.625 4.887 5.1 5.1-5.1 24.789-24.791 5.1-5.1-10.2-9.987z"
          fill={fillLight}
        />
      </g>
    </svg>
  )
}


ExpandCheck.propTypes = {
  fillLight: PropTypes.string,
  fillDark: PropTypes.string,
  className: PropTypes.string
}


export default ExpandCheck