import React from "react";
import { useHistory } from "react-router-dom";
import { Button, makeStyles } from "@material-ui/core";


export default function Policy() {
  const classes = useStyles()
  const history = useHistory();
  return (
    <>
      <section className={classes['policyContent']}>
        <nav>
          <Button className={classes['backBtn']} onClick={() => history.goBack()}><b>← back to chambr</b></Button>
        </nav>
        <br />
        <br />
        <h3>Chambr Privacy Policy</h3>
        <h4>Last updated on November 18th 2022</h4>
        <br />
        <p>Your privacy is important to us. Chambr is committed to handling your data in a secure and transparent way. This privacy policy explains how we collect personal information through our platform and how we process it.</p>
        <p>If you have any issues, please contact us at <a href="mailto:gabriela@chambr.games">gabriela@chambr.games</a> and we will gladly assist you.</p>
        <br />
        <p><b>About us</b></p>
        <p>Chambr helps learning & development professionals create learning games that increase content retention. We use evidence-based learning methods and blend them with the right game mechanics in order to make learning more effective. Chambr is web-based and is accessed exclusively via web link.</p>
        <p>
          If you are using Chambr, you have one of these 2 roles:
        </p>
        <ol>
          <li>
            'User', meaning you are joining Chambr solely as a player, as a participant.
          </li>
          <li>
            Admin', meaning you own a Chambr admin account where you can create and upload content and create games.
          </li>
        </ol>
        <br />
        <p><b>Data we collect</b></p>
        <ol>
          <li>
            As a user, you are asked to enter your name and a room code. You can use a nickname, there is no need for you to provide your real name. We do not save this data, it is immediately deleted after the end of each game.
          </li>
          <li>
            As an admin, you are asked for an email that you want to associate with your admin account in order to be able to log in. That is the only personal information you as an admin provide.
          </li>
          <li>
            We only save data related to the games played inside Chambr: number of players that joined Chambr, date & time, what room codes were used and how many room codes were used, as well as if there were any connection issues during the game.
          </li>
        </ol>
        <br />
        <p><b>Your Data Protection Rights</b></p>
        <ol>
          <li>
            You have the right to be informed about how we process your information. This Privacy Policy provides you with the information on what type of data we collect. We might update and modify it. As an Admin, you will be informed about any updates via email. As a User, since we do not collect any data from you, you will be able to see the last date we modified our Privacy Policy on this page.
          </li>
          <li>
            You have the right to request a copy of your data via email at gabriela@chambr.games or alex@chambr.games.
          </li>
          <li>
            You have the right to request deletion of your personal data. Send us an email and we will take care of it.
          </li>
          <li>
            If you have given us your consent to process your personal data, you can at any time revoke this consent.
          </li>
          <li>
            You may have the right to issue a complaint regarding our collection and use of your Personal Data. Please contact your local data protection authority for more information.
          </li>
        </ol>
        <br />
        <br />
      </section>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  policyContent: {
    color: theme.palette.info.main,
    display: 'block',
    margin: '0 auto',
    maxWidth: '600px',
    '> p': {
      marginTop: '5px'
    }
  },
  backBtn: {
    color: theme.palette.info.main,
  }
}))