import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const SpeakerIcon = (props) => {
  const {
    fillDark = DEFAULT_SECONDARY_COLOR_CODE,
    fillLight = DEFAULT_PRIMARY_COLOR_CODE,
    stroke = DEFAULT_SECONDARY_COLOR_CODE,
    className = '',
    ...others
  } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={120.341}
      height={201.029}
      viewBox="0 0 120.341 201.029"
      className={className}
      {...others}
    >
      <defs>
        <style>
          {
            ".cls-3{fill:none;stroke-linecap:round;stroke-width:10px}"
          }
        </style>
      </defs>
      <g
        id="Speaker_icon"
        data-name="Speaker icon"
        transform="translate(7.071 5)"
      >
        <g
          id="Group_78"
          data-name="Group 78"
          transform="translate(-78.336 32.17)"
        >
          <g
            id="Group_75"
            data-name="Group 75"
            transform="translate(78.336 78.845)"
          >
            <g id="Group_74" data-name="Group 74">
              <path
                id="Path_382"
                data-name="Path 382"
                d="M183.206 215.191a5.783 5.783 0 10-11.566 0 40.868 40.868 0 01-81.737 0 5.783 5.783 0 10-11.567 0 52.427 52.427 0 0046.652 52.242v15.422h-21.013a5.783 5.783 0 100 11.566h53.592a5.783 5.783 0 000-11.566h-21.013v-15.422a52.427 52.427 0 0046.652-52.242z"
                transform="translate(-78.336 -209.408)"
                fill={fillDark}
              />
            </g>
          </g>
        </g>
        <path
          id="Subtraction_8"
          data-name="Subtraction 8"
          d="M32.194 116.822a32.586 32.586 0 01-22.748-9.542A32.087 32.087 0 010 84.435v-3.864h15.407a4.671 4.671 0 100-9.343H0v-8.493h15.407a4.671 4.671 0 100-9.343H0V44.9h15.407a4.671 4.671 0 100-9.343H0v-3.363A32.094 32.094 0 019.446 9.446a32.184 32.184 0 0154.941 22.748v3.361h-16.7a4.671 4.671 0 000 9.343h16.7v8.493h-16.7a4.671 4.671 0 000 9.343h16.7v8.493h-16.7a4.671 4.671 0 000 9.343h16.7v4.057a32.095 32.095 0 01-9.446 22.748 32.093 32.093 0 01-22.747 9.446z"
          transform="translate(20.241 32.171)"
          fill={fillLight}
          stroke="transparent"
          strokeMiterlimit={10}
        />
        <path
          id="Line_6"
          data-name="Line 6"
          className="cls-3"
          transform="translate(52.199)"
          d="M0 0L0 17"
          stroke={stroke}
        />
        <path
          id="Line_7"
          data-name="Line 7"
          className="cls-3"
          transform="translate(94.199 20)"
          d="M12 0L0 12"
          stroke={stroke}
        />
        <path
          id="Line_8"
          data-name="Line 8"
          className="cls-3"
          transform="translate(0 18.829)"
          d="M0 0L13.199 13.171"
          stroke={stroke}
        />
      </g>
    </svg>
  )
}

SpeakerIcon.propTypes = {
  fillDark: PropTypes.string,
  fillLight: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export default SpeakerIcon
