import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { gsap } from 'gsap'

import { makeStyles, useTheme } from '@material-ui/core'
import ButtonUpperLayer from '../Svg/ButtonUpperLayer'
import ButtonBottomLayer from '../Svg/ButtonBottomLayer'

const FancyButton = React.forwardRef((props, ref) => {
    const {
        onClick,
        disabled = false,
        buttonType = "button",
        width = "",
        height = "",
        label = "",
        labelFontSize = "20px",
    } = props
    const classes = useStyles({ width, height, labelFontSize, disabled })
    const theme = useTheme()
    const upperLayer = useRef(null)
    const animation = useRef(null)
    const [buttonCliked, setButtonCliked] = useState(false)

    useEffect(() => {
        animation.current = gsap.to(upperLayer.current,
            {
                y: 10,
                duration: 0.1,
                paused: true,
                ease: 'none'
            })

        return () => {
            if (animation.current) {
                animation.current.kill()
            }
        }
    }, [])

    useEffect((e) => {
        if (buttonCliked) {
            animation.current.play().eventCallback('onComplete', () => {
                animation.current.reverse().eventCallback('onReverseComplete', () => {
                    onClick()
                    setButtonCliked(false)
                })
            })
        }
        //eslint-disable-next-line
    }, [buttonCliked]);

    const handleButtonClick = e => {
        setButtonCliked(true)
    }

    return (
        <button
            ref={ref}
            disabled={disabled}
            type={buttonType}
            className={`press-button ${classes.buttonRoot}`}
            onClick={handleButtonClick}
        >
            <ButtonUpperLayer
                ref={upperLayer}
                fill={theme.palette.primary.main}
                stroke={theme.palette.primary.main}
                disabled={disabled}
            />
            <ButtonBottomLayer
                stroke={theme.palette.secondary.main}
                disabled={disabled}
            />

            <div className={classes.label}>
                {label}
            </div>
        </button>
    )
})

const useStyles = makeStyles(theme => ({
    buttonRoot: {
        background: 'none',
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        margin: '0 auto',
        cursor: 'pointer',
        '& svg': {
            width: ({ width }) => width.length > 0 ? width : '100px',
            height: ({ height }) => height.length > 0 ? height : '65px',
        },
        '& svg:nth-child(1)': {
            position: 'relative',
            zIndex: 1,
        },
        '& svg:nth-child(2)': {
            transform: ({ height }) => height.length ? `translateY(${(parseInt(height, 10) / 4) - (parseInt(height, 10))}px)` : `${((130 / 4) - 130)}px`,
            zIndex: 0,
        }
    },
    label: {
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        transform: ({ height }) => height.length > 0 ? `translateY(${(parseInt(height, 10) / 4) - (parseInt(height, 10))}px)` : `${((130 / 4) - 130)}px`,
        fontSize: ({ labelFontSize }) => labelFontSize,
        opacity: ({ disabled }) => disabled ? 0.5 : 1
    }
}))

FancyButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    buttonType: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    label: PropTypes.string,
    labelFontSize: PropTypes.string,
    disabled: PropTypes.bool
}

export default FancyButton