import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { gsap, Power1 } from 'gsap'
import { Redirect, useHistory } from 'react-router-dom'
import { getAuthData } from '../../utils'
import { Grid, makeStyles } from '@material-ui/core'
import FancyButton from '../General/FancyButton'
import StyledInput from '../General/StyledInput'
import axios from 'axios'
import { getAuthToken } from '../../utils'


export default function ChangePassword() {
    const classes = useStyles()
    const user = useSelector(state => state.user)
    const history = useHistory();
    const [isRequestRunning, setIsRequestRunning] = useState(false)
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
    const [oldPasswordError, setOldPasswordError] = useState(null)
    const [newPasswordError, setNewPasswordError] = useState(null)
    const [newPasswordConfirmationError, setNewPasswordConfirmationError] = useState(null)
    const bottomButton = useRef(null)
    let loginAnimation = useRef(null)

    const handleFormSubmitted = async () => {
        setIsRequestRunning(true)
        try {
            await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_SERVER_URL}/api/user/changepassword`,
                headers: { "Authorization": `Bearer ${getAuthToken()}` },
                data: { oldPassword: oldPassword, newPassword: newPassword }
            });
            history.push("/presets");
        } catch (ex) {
            setOldPasswordError(ex.response.data)
        } finally {
            setIsRequestRunning(false)
        }
    }

    useEffect(() => {
        if (bottomButton.current) {
            loginAnimation.current = gsap.from(bottomButton.current,
                {
                    y: '-50vh',
                    duration: 0.3,
                    ease: Power1.easeOut,
                    delay: 0.2
                })
        }

        return () => {
            if (loginAnimation.current) {
                loginAnimation.current.kill()
            }
        }
    }, [])

    const handleOldPasswordChange = e => {
        setOldPasswordError(null)
        e.persist()
        const value = e.target.value
        setOldPassword(prevState => {
            if (prevState !== value) {
                return value
            }
        })
        setOldPasswordError(validateOldPassword(value))
    }

    const handleNewPasswordChange = e => {
        setNewPasswordError(null)
        e.persist()
        const value = e.target.value
        setNewPassword(prevState => {
            if (prevState !== value) {
                return value
            }
        })
        setNewPasswordError(validateNewPassword(value))
    }

    const handleNewPasswordConfirmationChange = e => {
        setNewPasswordConfirmationError(null)
        e.persist()
        const value = e.target.value
        setNewPasswordConfirmation(prevState => {
            if (prevState !== value) {
                return value
            }
        })
        setNewPasswordConfirmationError(validateNewPasswordConfirmation(value))
    }

    const validateOldPassword = value => {
        if (value.length === 0) {
            return "Old password is required"
        }
        return null
    }

    const newPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,100}$/;
    const validateNewPassword = value => {
        if (value.length === 0) {
            return "New password is required"
        }
        if (!newPasswordRegex.test(value)) {
            return "New password must contain at least 12 characters, 1 lowercase, 1 uppercase, 1 number and 1 special character @$!%*?&"
        }
        return null
    }

    const validateNewPasswordConfirmation = value => {
        if (value.length === 0) {
            return "New password confirmation is required"
        }
        if (value !== newPassword) {
            return "Confirmation password must match the new password"
        }
    }

    const handleOnKeyDown = e => {
        if (e.keyCode === 13 && canSubmitForm()) {
            e.preventDefault()
            handleFormSubmitted()
        }
    }

    const canSubmitForm = () => {
        return validateOldPassword(oldPassword) == null
            && validateNewPassword(newPassword) == null
            && validateNewPasswordConfirmation(newPasswordConfirmation) == null
            && isRequestRunning === false
    }

    if (!getAuthData() || !user.username) {
        return (
            <Redirect to='/login' />
        )
    }

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes["login-wrapper"]}
        >
            <div className={classes["login-form"]}>
                <form>
                    <label className={classes["label-input"]}>
                        <StyledInput
                            onChange={handleOldPasswordChange}
                            type="password"
                            placeholder="Old Password"
                            height="80px"
                            fontSize="18px"
                        />
                        {oldPasswordError &&
                            <span className={classes["validation-errors"]}>
                                {oldPasswordError}
                            </span>
                        }
                    </label>

                    <label className={classes["label-input"]}>
                        <StyledInput
                            onChange={handleNewPasswordChange}
                            onKeyDown={handleOnKeyDown}
                            type="password"
                            placeholder="New Password"
                            height="80px"
                            fontSize="18px"
                        />
                        {newPasswordError &&
                            <span className={classes["validation-errors"]}>
                                {newPasswordError}
                            </span>
                        }
                    </label>

                    <label className={classes["label-input"]}>
                        <StyledInput
                            onChange={handleNewPasswordConfirmationChange}
                            onKeyDown={handleOnKeyDown}
                            type="password"
                            placeholder="Confirm New Password"
                            height="80px"
                            fontSize="18px"
                        />
                        {newPasswordConfirmationError &&
                            <span className={classes["validation-errors"]}>
                                {newPasswordConfirmationError}
                            </span>
                        }
                    </label>

                    <label>
                        {user.error &&
                            <span className={classes["validation-errors"]}>
                                {user.error}
                            </span>
                        }
                    </label>

                    <div className={classes["login-button"]}>
                        <FancyButton
                            ref={bottomButton}
                            disabled={!canSubmitForm()}
                            label="Confirm"
                            width="120px"
                            height="85px"
                            onClick={handleFormSubmitted}
                        />
                    </div>
                </form>
            </div>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    'login-wrapper': {
        width: '100%',
        height: '100%',
        maxWidth: 500,
        margin: '0 auto'
    },
    'login-title': {
        paddingBottom: '10vh',
        paddingTop: '8vh',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center',
        fontSize: '20px'
    },
    'login-form': {
        textAlign: 'center',
        width: '100%',
        maxWidth: '100%',
        margin: '0 auto',
        paddingTop: '20vh'
    },
    'validation-errors': {
        fontSize: '12px',
        color: '#cf0000'
    },
    'label-input': {
        paddingBottom: '40px',
        display: 'block'
    },
    'login-button': {
        paddingTop: '5vh'
    },
}))