export const DEFAULT_PRIMARY_COLOR_CODE = '#43d978'
export const DEFAULT_SECONDARY_COLOR_CODE = '#2b1e59'
export const DEFAULT_TEXT_COLOR_CODE = '#2b1e59'
export const DEFAULT_BACKGROUND_COLOR_CODE = '#eeeeee'
export const DEFAULT_SQUARE_COLOR_CODE = '#e4e4e4'

export const DARK_PRIMARY_COLOR_CODE = '#43d978'
export const DARK_SECONDARY_COLOR_CODE = '#7f85f5'
export const DARK_TEXT_COLOR_CODE = '#7f85f5'
export const DARK_BACKGROUND_COLOR_CODE = '#1f1f1f'
export const DARK_SQUARE_COLOR_CODE = '#292929'

export const HIDE_COPY_CONFIRMATION_TIMER = 1000

export const ADMIN = 'admin'
export const GAME = 'game'