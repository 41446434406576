import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { gsap, Back } from 'gsap'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logOut } from '../../../actions/auth'
import { GAME } from '../../../constants/general'

import {
  Button,
  Grid, IconButton, makeStyles, useTheme,
} from '@material-ui/core'
import SquareBackground from '../../Svg/SquareBackground'
import BluePath from '../../Svg/BluePath'
import GreenPath from '../../Svg/GreenPath'
import PlayerIcon from '../../General/PlayerIcon'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ConfirmationPopup from '../../Admin/components/ConfirmationPopup'


const Layout = ({
  children,
  contentClassName = "",
  currentPlayer = null,
  shouldAnimateLogo = false,
  shouldBackgroundRotateForward = false,
  shouldBackgroundRotateBackward = false,
  isAdmin = false
}) => {
  const [isLandscape, setIsLandscape] = useState(false)
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false)
  const dispatch = useDispatch()
  const classes = useStyles({ isAdmin, isLandscape })
  const theme = useTheme()
  const history = useHistory()
  const initialScreenWidth = window.innerWidth
  const customTheme = JSON.parse(localStorage.getItem('theme')) || null
  const hasClientLogo = customTheme &&
    customTheme.imageData &&
    customTheme.imageData.length > 0

  let logoAnimation = useRef(null)
  const firstLayer = useRef(null)
  const secondLayer = useRef(null)
  const thirdLayer = useRef(null)
  const fourthLayer = useRef(null)
  const fifthLayer = useRef(null)

  let forwardBgAnimation = useRef(null)
  let backwardBgAnimation = useRef(null)
  const background = useRef(null)

  const content = useRef(null)
  let contentAnimation = useRef(null)

  useEffect(() => {
    if (content.current) {
      contentAnimation.current = gsap.from(content.current,
        {
          opacity: 0,
          duration: 0.3,
        })
    }

    return () => {
      if (contentAnimation.current) {
        contentAnimation.current.kill()
      }
    }
  }, [])

  //logo layers animation
  useEffect(() => {
    if (firstLayer.current && secondLayer.current && thirdLayer.current && fourthLayer.current && fifthLayer.current) {
      const layers = [
        firstLayer.current,
        secondLayer.current,
        thirdLayer.current,
        fourthLayer.current,
        fifthLayer.current
      ]

      if (shouldAnimateLogo) {
        layers.forEach((elem, index) => {
          logoAnimation.current = gsap.from(elem,
            {
              top: '18px',
              duration: 0.5,
              ease: Back.easeOut.config(3),
              delay: 0.5,
            })
        })
      }
    }

    return () => {
      if (logoAnimation.current) {
        logoAnimation.current.kill()
      }
    }
  }, [shouldAnimateLogo])

  //square background forward animation
  useEffect(() => {
    if (shouldBackgroundRotateForward) {
      forwardBgAnimation.current = gsap.to(background.current,
        {
          rotation: 180,
          duration: 0.3,
          delay: 0.3
        })
    }

    return () => {
      if (forwardBgAnimation.current) {
        forwardBgAnimation.current.kill()
      }
    }
  }, [shouldBackgroundRotateForward])

  //square background backward animation
  useEffect(() => {
    if (shouldBackgroundRotateBackward) {
      backwardBgAnimation.current = gsap.to(background.current,
        {
          rotation: -180,
          duration: 0.3,
          delay: 0.3
        })
    }

    return () => {
      if (backwardBgAnimation.current) {
        backwardBgAnimation.current.kill()
      }
    }
  }, [shouldBackgroundRotateBackward])

  const handleLogoutPopupClose = () => {
    setIsLogoutPopupOpen(false)
  }

  const handleLogoutClicked = () => {
    setIsLogoutPopupOpen(true)
  }

  const handleLogout = () => {

    if (isAdmin) {
      dispatch(logOut())
      // history.push('/login')
      history.push('/')
    } else {
      dispatch(logOut(GAME))
      history.push('/')
    }
  }

  const handleWindowResize = () => {
    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      const orientation = screen && screen.orientation && screen.orientation.angle
        // eslint-disable-next-line no-restricted-globals
        ? screen.orientation.angle
        : window.orientation

      // eslint-disable-next-line no-restricted-globals
      const currentScreenWidth = screen && screen.width
        // eslint-disable-next-line no-restricted-globals
        ? screen.width
        : window.innerWidth

      if (initialScreenWidth !== currentScreenWidth && (orientation === 90 || orientation === -90)) {
        setIsLandscape(true)
      } else {
        setIsLandscape(false)
      }
    }, 50)
  }

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('orientationchange', handleWindowResize, false)
    return () => window.removeEventListener('orientationchange', handleWindowResize)

    //eslint-disable-next-line
  }, [])

  return (
    <Grid
      container
      justifyContent="center"
      className={classes['root-inner']}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        className={classes["header"]}
      >
        {!isAdmin && currentPlayer && <Button
          disableTypography={true}
          onClick={handleLogoutClicked}
          variant="text"
          classes={{ root: classes["game-logout-button"] }}
        >
          Logout
        </Button>}

        <div className={classes["logo"]}>
          <div className={classes["svg-wrapper"]}>
            {hasClientLogo
              ? <img
                src={`data:image/png;base64, ${customTheme.imageData}`}
                alt="client logo"
                className={classes["client-logo"]}
              />
              :
              <>
                <BluePath
                  ref={firstLayer}
                  stroke={theme.palette.secondary.main}
                />
                <GreenPath
                  ref={secondLayer}
                  stroke={theme.palette.primary.main}
                />
                <BluePath
                  ref={thirdLayer}
                  stroke={theme.palette.secondary.main}
                />
                <GreenPath
                  ref={fourthLayer}
                  stroke={theme.palette.primary.main}
                />
                <BluePath
                  ref={fifthLayer}
                  stroke={theme.palette.secondary.main}
                />
              </>
            }
          </div>
        </div>

        {currentPlayer &&
          <div className={classes["user-utils"]}>
            {'index' in currentPlayer &&
              <div className={classes["user-icon-wrapper"]}>
                <PlayerIcon
                  index={currentPlayer.index}
                  className={classes["user-icon"]}
                />
              </div>
            }
            {'username' in currentPlayer &&
              <>
                <span className={classes["user-name"]}>
                  {currentPlayer.username}
                </span>

                {isAdmin && <IconButton
                  aria-label="logout"
                  onClick={handleLogoutClicked}
                  classes={{ root: classes["logout-button"] }}
                >
                  <ExitToAppIcon />
                </IconButton>}
              </>
            }
          </div>
        }
      </Grid>

      <div
        className={`${isAdmin ? classes["square-background-admin"] : classes["square-background"]}`}
        ref={background}
      >
        <SquareBackground fill={theme.palette.warning.main} />
      </div>

      <div className={`${classes["page-content"]} ${contentClassName}`} ref={content}>
        {children}
      </div>

      <ConfirmationPopup
        isOpen={isLogoutPopupOpen}
        onClose={handleLogoutPopupClose}
        onYes={handleLogout}
        onNo={handleLogoutPopupClose}
        title={isAdmin ? "Logging out" : "Leaving this chambr?"}
        text={isAdmin ? "Are you sure you want to log out of the admin panel?" : "Once out you will not be able to rejoin. Are you sure you want to leave?"}
        yesText='YES, LEAVE!'
      />
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  'root-inner': {
    '&:before': {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      width: 'calc(100vw-40px)',
      height: '100vh',
      zIndex: 99999,
      pointerEvents: 'none',
      userSelect: 'none',
      backgroundColor: 'rgba(3, 1, 1, 0.9)',
      color: '#fff',
      fontSize: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '0 20px',
      //next 2 style objects limits the admin to be used only on tablet and computer
      //eslint-disable-next-line
      // ['@media only screen and (max-width:599.95px)']: {
      //   content: ({ isAdmin }) => isAdmin ? '"You can\'t use this app on mobile devices. Please use a tablet or a computer"' : null,
      // },
      //eslint-disable-next-line
      // ['@media only screen and (max-width:900px)']: {
      //   content: ({ isAdmin, isLandscape }) => isAdmin && isLandscape ? '"You can\'t use this app on mobile devices. Please use a tablet or a computer"' : null,
      // },
      //next 2 style objects limits the game to be used only on mobile devices
      //eslint-disable-next-line
      // ['@media only screen and (min-width:600px)']: {
      //   content: ({ isAdmin }) => !isAdmin ? '"You can use this app only on mobile devices in portait mode"' : null,
      // },
      //eslint-disable-next-line
      // ['@media only screen and (max-width:900px)']: {
      //   content: ({ isAdmin, isLandscape }) => !isAdmin && isLandscape ? '"You can use this app only on mobile devices in portait mode"' : null,
      // }
    },
  },
  'header': {
    padding: '10px 0',
    position: 'relative',
    zIndex: 1
  },
  'square-background': {
    borderRadius: '50px',
    top: '40%',
    position: 'fixed',
    zIndex: 0,
  },
  'square-background-admin': {
    top: 0,
    zIndex: 0,
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '100%',
      height: '100%',
      transform: 'translateX(60%) scale(2)'
    }
  },
  'page-content': {
    position: 'relative',
    width: '100%'
  },
  'user-utils': {
    width: 'calc(50vw - 40px)',
    position: 'absolute',
    right: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 1,
  },
  'user-name': {
    color: theme.palette.info.main,
    fontFamily: '"SegoeSemiBold", sans-serif',
    fontSize: ({ isAdmin }) => isAdmin ? '20px' : '13px',
    margin: '0 5px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  'user-icon': {
    width: 33,
    height: 33
  },
  'logo': {
    height: 65,
    width: 60,
    margin: '0 auto',
    '& svg': {
      width: 40,
      height: 40,
      position: 'absolute',
      '&:nth-child(1)': {
        top: 0
      },
      '&:nth-child(2)': {
        top: 6
      },
      '&:nth-child(3)': {
        top: 12
      },
      '&:nth-child(4)': {
        top: 18
      },
      '&:nth-child(5)': {
        top: 24
      }
    }
  },
  'svg-wrapper': {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
  },
  'logout-button': {
    '& svg': {
      color: theme.palette.info.main
    }
  },
  'game-logout-button': {
    position: 'absolute',
    left: '10px',
    fontFamily: '"SegoeSemiBold", sans-serif',
    fontSize: '13px',
    color: theme.palette.info.main,
    textTransform: 'capitalize'
  },
  'client-logo': {
    width: '100%',
    objectFit: 'contain'
  },
}))

Layout.propTypes = {
  shouldAnimateLogo: PropTypes.bool,
  shouldBackgroundRotateForward: PropTypes.bool,
  shouldBackgroundRotateBackward: PropTypes.bool,
  isAdmin: PropTypes.bool,
  contentClassName: PropTypes.string,
  currentPlayer: PropTypes.object
}

export default Layout