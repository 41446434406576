import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Feedback = (props) => {
  const {
    fillDark = DEFAULT_SECONDARY_COLOR_CODE,
    fillLight = DEFAULT_PRIMARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      data-name="Feedback button"
      xmlns="http://www.w3.org/2000/svg"
      width={35.697}
      height={34.847}
      viewBox="0 0 35.697 34.847"
      className={className}
      {...others}
    >
      <path
        data-name="Icon material-feedback"
        d="M32.068 3H6.23a3.225 3.225 0 00-3.214 3.23L3 35.3l6.459-6.459h22.609a3.239 3.239 0 003.23-3.23V6.23A3.239 3.239 0 0032.068 3zm-11.3 19.378h-3.23v-3.23h3.23zm0-6.459h-3.23v-6.46h3.23z"
        transform="translate(.4 -.45)"
        fill={fillLight}
      />
      <path
        data-name="Icon material-feedback"
        d="M32.068 3H6.23a3.225 3.225 0 00-3.214 3.23L3 35.3l6.459-6.459h22.609a3.239 3.239 0 003.23-3.23V6.23A3.239 3.239 0 0032.068 3zm-11.3 19.378h-3.23v-3.23h3.23zm0-6.459h-3.23v-6.46h3.23z"
        transform="translate(-3 -3)"
        fill={fillDark}
      />
    </svg>
  )
}

Feedback.propTypes = {
  fillDark: PropTypes.string,
  fillLight: PropTypes.string,
  className: PropTypes.string
}

export default Feedback
