import React from 'react'
import { Router, Route, Switch, useHistory } from 'react-router-dom'
import { getAuthData } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom' //Redirect
import { setUserData } from '../../actions/auth'

import { Grid, makeStyles } from '@material-ui/core'
import CreatePreset from '../Admin/Presets/CreatePreset'
import PresetList from '../Admin/Presets/PresetList'
import TemplateList from '../Admin/Templates/TemplateList'
import UsersList from '../Admin/Users/UsersList'
import Template from '../Admin/Templates/Template'
import Layout from '../Game/Components/Layout'
import Menu from './components/Menu'
import ReplaysList from '../Admin/Replays/ReplaysList'
import ChangePassword from './ChangePassword'

function AuthenticatedComponent(props) {
    const authData = getAuthData()
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const history = useHistory()
    const classes = useStyles()

    if (!authData) {
        return (
            <Redirect to="/login" />
        )
    }

    if (!user.username) {
        dispatch(setUserData())
        return (
            <div>
                <h2>Loading user data..</h2>
            </div>
        )
    }

    return (
        <Layout currentPlayer={user} isAdmin={true}>
            <Grid
                container
                className={classes["authenticated-wrapper"]}
                spacing={4}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    container
                    alignItems="center"
                    wrap="nowrap"
                    justifyContent="center"
                    className={`${classes["auto-overflow"]} full-height menu-container`}
                >
                    <Menu />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={10}
                    className={`${classes["auto-overflow"]} full-height content-container`}
                >
                    <Router history={history}>
                        <Switch>
                            <Route exact path={'/preset'} component={CreatePreset} />
                            <Route exact path={'/presets'} component={PresetList} />
                            <Route exact path={'/chambr'} component={Template} />
                            <Route exact path={'/chambrs'} component={TemplateList} />
                            <Route exact path={'/users'} component={UsersList} />
                            <Route exact path={'/feedback'} component={ReplaysList} />
                            <Route exact path={'/changepassword'} component={ChangePassword} />
                        </Switch>
                    </Router>
                </Grid>
            </Grid>
        </Layout >
    )
}

const useStyles = makeStyles(theme => ({
    'authenticated-wrapper': {
        padding: '3vh 60px 0 60px',
        height: 'calc(100vh - 110px)',
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.between('sm', 'sm')]: {
            padding: '0 16px',
            alignContent: 'flex-start',
        },
    },
    'auto-overflow': {
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
        flexDirection: 'column',
        '&-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        [theme.breakpoints.between('sm', 'sm')]: {
            flexDirection: 'row',
            height: 'auto',
            '&.content-container': {
                height: 'calc(100vh - 200px)',
            }
        },
    },
}))

export default withRouter(AuthenticatedComponent)