import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../../constants/general'
import { Button, makeStyles } from '@material-ui/core'

const GeneralButton = (props) => {
  const {
    backgroundColor = DEFAULT_PRIMARY_COLOR_CODE,
    fontColor = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    disabled = false,
    children,
    ...others
  } = props
  const classes = useStyles({ backgroundColor, fontColor, disabled })

  return (
    <Button
      disableElevation
      className={`${classes["general-button"]} ${className}`}
      disabled={disabled}
      {...others}
    >
      {children}
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  'general-button': {
    color: ({ fontColor }) => fontColor,
    textTransform: 'none',
    fontFamily: '"SegoeBlack", sans-serif',
    fontSize: '16px',
    lineHeight: '16px',
    height: 50,
    width: '100%',
    backgroundColor: ({ backgroundColor, disabled }) => disabled ? '#d3d3d3' : backgroundColor,
    borderRadius: '100px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: ({ backgroundColor }) => backgroundColor,
    },
  },
}))

GeneralButton.propTypes = {
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

export default GeneralButton