import * as actionTypes from '../actions/types';

const initialState = {
    items: [],
    selectedItem: null,
    presets: [],
}

export const templatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TEMPLATES:
            return {
                ...state,
                items: action.payload,
            }
        case actionTypes.SELECT_TEMPLATE:
            return {
                ...state,
                selectedItem: state.selectedItem &&
                    state.selectedItem.id &&
                    state.selectedItem.id === action.payload.id
                    ? null : action.payload,
            }
        case actionTypes.DELETE_CODE:
            if (state.selectedItem) {
                return {
                    ...state,
                    selectedItem: {
                        ...state.selectedItem,
                        gameCodes: state.selectedItem.gameCodes
                            .filter(code => code.id !== action.payload)
                    },
                }
            }
            return state
        case actionTypes.SET_PRESETS:
            return {
                ...state,
                presets: action.payload.map(preset => {
                    return {
                        ...preset,
                        isSelected: false,
                    }
                }),
            }
        case actionTypes.TEMPLATE_SELECT_PRESET:
            return {
                ...state,
                presets: state.presets.map(preset => {
                    if (preset.id === action.payload.id) {
                        preset.isSelected = true
                    } else {
                        preset.isSelected = false
                    }
                    return preset
                }),
            }
        case actionTypes.DELETE_TEMPLATE:
            return {
                ...state,
                items: state.items.filter(preset => preset.id !== action.payload),
                selectedItem: null,
            }
        case actionTypes.GENERATE_CODES:
            if (state.selectedItem && state.selectedItem.id === action.payload.id) {
                return {
                    ...state,
                    selectedItem: action.payload,
                }
            }
            return state
        default:
            return state
    }
}

export default templatesReducer;