import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import Layout from '../Game/Components/Layout'
import FancyButton from '../General/FancyButton'
import axios from 'axios'
import { gsap, Power1 } from 'gsap'

const URL_TOKEN = '?token='

const getToken = (search) => {
    if (search.startsWith(URL_TOKEN)) {
        const token = search.slice(URL_TOKEN.length)
        return token
    }
    return null
}

export default function Offer() {
    const classes = useStyles()
    const { search } = useLocation()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [subData, setSubData] = useState(null)
    const bottomButton = useRef(null)
    let animation = useRef(null)

    const token = getToken(search)

    useEffect(() => {
        if (token) {
            setIsLoading(true)
            axios.get(`${process.env.REACT_APP_SERVER_URL}/api/AzureMarketplace/${URL_TOKEN}${token}`)
                .then(res => {
                    setSubData(res.data)
                    setIsLoading(false)
                })
                .catch(err => {
                    setErrorMsg("Error in processing your subscription.")
                }).finally(() => {
                    setIsLoading(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        animation.current = gsap.from(bottomButton.current,
            {
                y: '-50vh',
                opacity: 0,
                duration: 0.3,
                ease: Power1.easeOut,
                delay: 0.2
            })

        return () => {
            if (animation.current) {
                animation.current.kill()
            }
        }
    }, [])

    const handleActivation = async () => {
        setIsLoading(true)
        try {
            await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_SERVER_URL}/api/AzureMarketplace/${URL_TOKEN}${token}`,
            });
            history.push("/login");
        } catch (ex) {
            console.log({ ex })
            setErrorMsg("Error processing activation")
        } finally {
            setIsLoading(false)
        }
    }

    const handleLogin = () => {
        history.push('/login')
    }

    return (
        <Layout
            contentClassName={classes["start-page"]}
            shouldAnimateLogo={true}
        >
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes["joingame-wrapper"]}
            >
                <div className={classes["joingame-form"]}>
                    <div className={classes["joingame-title"]}>
                        <span>Marketplace subscription</span>
                    </div>
                    {!token && <div className={classes["error"]}>No token is present.</div>}
                    {errorMsg && <div className={classes["error"]}>{errorMsg}</div>}
                    {isLoading && <CircularProgress />}
                    {subData && <>
                        <div className={classes["sub-data"]}>
                            <div><b>Purchaser: </b> {subData.purchaserEmail}</div>
                            <div><b>Offer: </b> {subData.offerName}</div>
                            <div><b>Subscription: </b> {subData.subscriptionName}</div>
                            <div><b>Plan: </b> {subData.planName}</div>
                            <div><b>Status: </b> {subData.statusName}</div>
                            {subData.canActivate === false && 
                                <div>
                                    <p>There is another subscription associated with your tenant.</p>
                                    <p>We have canceled this subscription.</p>
                                    <p>For more information please contact your tenant or us at <a href="mailto:gabriela@chambr.games">gabriela@chambr.games</a> or visit our <a href='https://www.playlearn.games/chambr' target="_blank" rel="noopener noreferrer">website</a>.</p>
                                </div>}
                        </div>


                        <div className={classes["joingame-button"]}>
                            {(subData.canActivate) && <FancyButton
                                ref={bottomButton}
                                disabled={isLoading}
                                label="Activate"
                                width="100px"
                                height="65px"
                                onClick={handleActivation}
                            />}
                            {/* Subscribed */}
                            {(subData.status === 2) && <FancyButton
                                ref={bottomButton}
                                label="Login"
                                width="100px"
                                height="65px"
                                // onClick={handleActivation}
                                onClick={handleLogin}
                            />}
                        </div>
                    </>
                    }
                </div>
            </Grid>
        </Layout>
    )
}

const useStyles = makeStyles(theme => ({
    'start-page': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
        width: '100vw',
        height: 'calc(100vh - 85px)',
        '& .press-button': {
            transform: 'translateY(-5vh)'
        }
    },
    'joingame-wrapper': {
        width: '100%',
        height: '100%',
        maxWidth: 300,
        margin: '0 auto'
    },
    'joingame-title': {
        paddingTop: '8vh',
        paddingBottom: '2vh',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center',
        fontSize: '20px'
    },
    'sub-data': {
        paddingTop: '2vh',
        paddingBottom: '4vh',
        color: theme.palette.info.main,
        textAlign: 'center',
        fontSize: '16px'
    },
    'joingame-form': {
        textAlign: 'center',
        width: '100%',
        maxWidth: 300,
        margin: '0 auto'
    },
    'joingame-button': {
        paddingTop: '5vh'
    },
    'error': {
        paddingTop: '3vh',
        paddingBottom: '2vh',
        color: 'red',
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center',
        fontSize: '16px'
    }
}))