import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Pen = (props) => {
  const {
    fill = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={300}
      height={300}
      viewBox="0 0 300 300"
      className={className}
      {...others}
    >
      <g>
        <path fill={fill} class="st0" d="M149.1,107.94c0,0-64.44,38.49-68.73,49.78c-12.38,32.63-24.85,70.96-34.48,90.57
		c0.62,0.31,1.21,0.7,1.72,1.21l-5.09,5.09v0l60.34-60.34c-3.18-4.54-2.75-10.84,1.31-14.9c4.55-4.55,11.92-4.55,16.47,0
		l49.93-49.93L149.1,107.94z"/>
        <path fill={fill} class="st0" d="M45.9,248.29c-2.54-1.29-5.71-0.89-7.85,1.24c-2.65,2.65-2.66,6.93-0.03,9.57l4.51-4.51l5.09-5.09
		C47.1,248.99,46.51,248.6,45.9,248.29z"/>
        <path fill={fill} class="st0" d="M120.65,179.35c4.55,4.55,4.55,11.92,0,16.47c-4.06,4.06-10.35,4.49-14.9,1.31L50.5,252.38
		c0.51,0.51,0.9,1.1,1.21,1.72c19.61-9.63,57.95-22.1,90.57-34.48c11.29-4.28,49.78-68.73,49.78-68.73l-21.48-21.48L120.65,179.35z"
        />
        <path fill={fill} class="st0" d="M51.71,254.1c-0.31-0.62-0.7-1.21-1.21-1.72l0,0l-9.59,9.59c2.63,2.63,6.92,2.62,9.57-0.03
		C52.6,259.82,53,256.64,51.71,254.1z"/>
        <path fill={fill} class="st0" d="M239.08,60.91l-24.86-24.86l-65.86,61.25c-1.03,0.89-1.46,2.29-1.11,3.61l1.85,7.02l21.48,21.48L239.08,60.91z
		"/>
        <path fill={fill} class="st0" d="M202.69,151.63l61.25-65.86l-24.86-24.86l-68.5,68.5l21.48,21.48l7.02,1.85
		C200.4,153.09,201.8,152.66,202.69,151.63z"/>
      </g>
    </svg>
  )
}

Pen.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
}

export default Pen