import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gsap, Power1 } from 'gsap'
import { Redirect } from 'react-router-dom'
import { login } from '../../actions/auth'
import { getAuthData } from '../../utils'

import { Grid, makeStyles } from '@material-ui/core'
import Layout from '../Game/Components/Layout'
import FancyButton from '../General/FancyButton'
import StyledInput from '../General/StyledInput'
import MicrosoftLoginButton from './MicrosoftLoginButton'
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';


export default function Login() {
    const dispatch = useDispatch()
    const classes = useStyles()
    const user = useSelector(state => state.user)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    const bottomButton = useRef(null)
    let loginAnimation = useRef(null)

    const handleFormSubmitted = () => {
        dispatch(login(email, password))
    }

    useEffect(() => {
        if (bottomButton.current) {
            loginAnimation.current = gsap.from(bottomButton.current,
                {
                    y: '-50vh',
                    duration: 0.3,
                    ease: Power1.easeOut,
                    delay: 0.2
                })
        }

        return () => {
            if (loginAnimation.current) {
                loginAnimation.current.kill()
            }
        }
    }, [])

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase())
    }

    const handleEmailChange = e => {
        setEmailError(null)
        e.persist()
        setEmail(prevState => {
            if (prevState !== e.target.value) {
                return e.target.value
            }
        })
        if (e.target.value.length === 0) {
            setEmailError("Email is required")
        }
        if (!validateEmail(e.target.value) && e.target.value.length > 0) {
            setEmailError("Email address is not valid")
        }
    }

    const handlePasswordChange = e => {
        setPasswordError(null)
        e.persist()
        setPassword(prevState => {
            if (prevState !== e.target.value) {
                return e.target.value
            }
        })
        if (e.target.value.length === 0) {
            setPasswordError("Password is required")
        }
    }


    const handleOnKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault()
            dispatch(login(email, password))
        }
    }


    if (getAuthData() && user.username) {
        return (
            <Redirect to='/presets' />
        )
    }

    const msalConfig = {
        auth: {
            clientId: process.env.REACT_APP_AD_CLIENT_ID,
            redirectUri: process.env.REACT_APP_AD_REDIRECT_URL
        },
    };
    const msalInstance = new PublicClientApplication(msalConfig);
    return (
        <Layout isAdmin={true}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes["login-wrapper"]}
            >
                <div className={classes["login-form"]}>
                    <form>
                        <label className={classes["label-input"]}>
                            <StyledInput
                                onChange={handleEmailChange}
                                type="email"
                                placeholder="Email"
                                height="80px"
                                fontSize="18px"
                            />
                            {emailError &&
                                <span className={classes["validation-errors"]}>
                                    {emailError}
                                </span>
                            }
                        </label>

                        <label className={classes["label-input"]}>
                            <StyledInput
                                onChange={handlePasswordChange}
                                onKeyDown={handleOnKeyDown}
                                type="password"
                                placeholder="Password"
                                height="80px"
                                fontSize="18px"
                            />
                            {passwordError &&
                                <span className={classes["validation-errors"]}>
                                    {passwordError}
                                </span>
                            }
                        </label>

                        <label>
                            {user.error &&
                                <span className={classes["validation-errors"]}>
                                    {user.error}
                                </span>
                            }
                        </label>

                        <div className={classes["login-button"]}>
                            <FancyButton
                                ref={bottomButton}
                                disabled={!email.length || !password.length || Boolean(emailError) || Boolean(passwordError)}
                                label="Login"
                                width="120px"
                                height="85px"
                                onClick={handleFormSubmitted}
                            />
                        </div>
                    </form>
                    <div className={classes["or"]}>
                        - Or -
                    </div>
                    <div>
                        <MsalProvider instance={msalInstance}>
                            <MicrosoftLoginButton loginRequest={{ scopes: ['user.read'], }} />
                        </MsalProvider>
                    </div>
                </div>
            </Grid>
        </Layout>)
}

const useStyles = makeStyles(theme => ({
    'login-wrapper': {
        width: '100%',
        height: '100%',
        maxWidth: 500,
        margin: '0 auto'
    },
    'login-title': {
        paddingBottom: '10vh',
        paddingTop: '8vh',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center',
        fontSize: '20px'
    },
    'login-form': {
        textAlign: 'center',
        width: '100%',
        maxWidth: '100%',
        margin: '0 auto',
        paddingTop: '20vh'
    },
    'validation-errors': {
        fontSize: '12px',
        color: '#cf0000'
    },
    'label-input': {
        paddingBottom: '40px',
        display: 'block'
    },
    'login-button': {
        paddingTop: '5vh'
    },
    'or': {
        paddingTop: '1vh',
        paddingBottom: '3vh',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center',
        fontSize: '16px'
    },
}))