import React from 'react'
import PropTypes from 'prop-types'

const ArrowDown = (props) => {
  const {
    fill = "#fff",
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={21}
      viewBox="0 0 24 21"
      className={className}
      {...others}
    >
      <path
        d="M14.6 16.442a3 3 0 01-5.209 0L2.561 4.489A3 3 0 015.17 0h13.66a3 3 0 012.6 4.488z"
        fill={fill}
      />
    </svg>
  )
}

ArrowDown.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
}


export default ArrowDown