import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Avatar9 = (props) => {
  const {
    fill = DEFAULT_PRIMARY_COLOR_CODE,
    stroke = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
      className={className}
      {...others}
    >
      <rect
        x={174.78}
        y={173.7}
        width={646.15}
        height={646.15}
        rx={111.77}
        transform="rotate(45 497.854 496.788)"
        fill={fill}
      />
      <ellipse
        className="cls-2"
        cx={439.12}
        cy={434.33}
        rx={27.69}
        ry={27.67}
        fill="none"
        stroke={stroke}
        strokeWidth="25px"
        strokeMiterlimit="10"
      />
      <ellipse
        className="cls-2"
        cx={582.51}
        cy={434.33}
        rx={27.69}
        ry={27.67}
        fill="none"
        stroke={stroke}
        strokeWidth="25px"
        strokeMiterlimit="10"
      />
      <path
        className="cls-2"
        d="M543.83 588.47c0 43.19 27.69 78.21-34 78.21-53.53 0-34.83-35-34.83-78.21s-25.41-78.21 34.83-78.21c59.63 0 34 35.01 34 78.21z"
        fill="none"
        stroke={stroke}
        strokeWidth="25px"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

Avatar9.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string
}

export default Avatar9
