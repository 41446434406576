import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startGame } from '../../../actions/index'

import { makeStyles } from '@material-ui/core'
import Player from './Player'
import Layout from './Layout'
import FancyButton from '../../General/FancyButton'


export default function WaitingForPlayers() {
    const dispatch = useDispatch()
    const classes = useStyles()
    const gameState = useSelector(d => d.game)
    const currentPlayer = gameState.game.players.find(d => d.connectionId === gameState.player.connectionId)
    const playersCount = gameState.game.players.filter(d => d.isConnected).length

    const handleStartGame = e => {
        dispatch(startGame())
    }

    return (
        <Layout
            currentPlayer={currentPlayer}
            shouldBackgroundRotateForward={true}
        >
            <div className={classes["lobby-page"]}>
                <div className={classes["lobby-title"]}>Waiting for players...</div>

                <div className={classes["lobby-list"]}>
                    {gameState.game.players.map(player => {
                        return (
                            <Player
                                key={player.username}
                                index={player.index}
                                username={player.username}
                                isVip={player.isVip}
                            />
                        )
                    })}
                </div>

                {currentPlayer && currentPlayer.isVip &&
                    <FancyButton
                        disabled={playersCount <= 1}
                        label={playersCount > 1 ? "Everybody's in" : "Waiting for more players"}
                        width="100px"
                        height="65px"
                        onClick={handleStartGame}
                    />
                }
            </div>
        </Layout>
    )
}

const useStyles = makeStyles(theme => ({
    'lobby-page': {
        paddingTop: '8vh'
    },
    'lobby-title': {
        paddingBottom: '3vh',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        fontSize: '20px',
        textAlign: 'center'
    },
    'lobby-list': {
        width: '100%',
        maxWidth: 250,
        margin: '0 auto',
        paddingBottom: '10vh'
    }
}))