import * as actionTypes from "./types"
import axios from 'axios'
import { getAxiosConfig } from './helper'

export const getUsers = () => dispatch => {
    dispatch({ type: actionTypes.BEGIN_SET_USERS });
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/user`, getAxiosConfig())
        .then(res => {
            dispatch({
                type: actionTypes.SET_USERS,
                payload: res.data,
            })
        })
        .catch(err => {
            console.log(err);
            dispatch({
                type: actionTypes.ERROR_GET_USERS,
                payload: err.response.data

            })
        })
}

export const createUser = (email, password, isLogActive) => dispatch => {
    dispatch({ type: actionTypes.BEGIN_CREATE_USER });
    const data = { email, password, isLogActive }
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/user/create`, data, getAxiosConfig())
        .then(res => {
            dispatch({
                type: actionTypes.CREATE_USER,
                payload: res.data,
            })
        })
        .catch(err => {
            dispatch({
                type: actionTypes.ERROR_CREATE_USER,
                payload: err.response.data
            })
        })
}

export const deleteUser = email => dispatch => {
    dispatch({ type: actionTypes.BEGIN_DELETE_USER });
    axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/user/${email}`, getAxiosConfig())
        .then(res => {
            dispatch({
                type: actionTypes.DELETE_USER,
                payload: res.data,
                meta: email
            })
        })
        .catch(err => {
            console.log(err);
            dispatch({
                type: actionTypes.ERROR_DELETE_USER,
                payload: err.response.data
            })
        })
}