import React from "react";
import { makeStyles } from '@material-ui/core';
import { Link } from "react-router-dom";


export default function Footer() {
  const classes = useStyles()

  return (
    <footer>
      <Link to="/terms" className={classes['footerLink']}>Terms</Link>
      <span className={classes['separator']}>|</span>
      <Link to="/policy" className={classes['footerLink']}>Policy</Link>
    </footer>
  )
}

const useStyles = makeStyles(theme => ({
  'footerLink': {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    padding: '0 5px',
  },
  'separator': {
    color: theme.palette.secondary.main,
  }
}))