import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { gsap } from 'gsap'

import {
  Accordion,
  AccordionSummary,
  makeStyles
} from '@material-ui/core'


const AccordionPrompt = ({
  title = null,
  text = "",
  modifier = "",
  panelRef,
  panelTextRef
}) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles({ open })
  const textWords = text.split(" ") || []
  const noOfWords = 50
  const bottomModifier = useRef(null)
  let modifierAnimation = useRef(null)

  useEffect(() => {
    return () => {
      if (modifierAnimation.current) {
        modifierAnimation.current.kill()
      }
    }
  }, [])

  useEffect(() => {
    if (modifier) {
      if (open) {
        modifierAnimation.current = gsap.from(bottomModifier.current,
          {
            y: '-30vh',
            opacity: 0,
            duration: 0.3,
            ease: 'easeInOut',
          })
      }
    }

  }, [open, modifier])

  const handleExpandAccordion = e => {
    e.preventDefault()
    if (textWords.length > noOfWords) {
      setOpen(prevValue => !prevValue)
    }
  }

  const truncate = () => {
    let words = [...textWords].slice(0, noOfWords)

    return words.join(" ")
  }

  return (
    <div className={classes["main-accordion-wrapper"]} ref={panelRef}>
      <Accordion
        expanded={open}
        className={classes.accordionRoot}
        onChange={handleExpandAccordion}
      >
        <AccordionSummary
          aria-controls="panel-content"
          id="panel-header"
        >
          <div className={classes["accordion-inner"]} ref={panelTextRef}>
            {title &&
              <div className={classes["accordion-title"]}>
                {title}
              </div>
            }

            {textWords.length > noOfWords && !open ? `${truncate(text)}...` : text}
          </div>
        </AccordionSummary>
      </Accordion>

      {textWords.length > noOfWords &&
        <div
          className={classes["accordion-overflow-label"]}
          onClick={handleExpandAccordion}
        >
          click to expand
        </div>
      }

      {modifier &&
        <div
          className={classes["accordion-modifier"]}
          ref={bottomModifier}
          onClick={handleExpandAccordion}
        >
          {modifier}
        </div>
      }
    </div >
  )
}

const useStyles = makeStyles(theme => ({
  accordionRoot: {
    border: 'none',
    background: 'none',
    color: '#fff',
    borderRadius: '25px !important',
    boxShadow: 'none',
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0
    },
    '&.MuiAccordion-root:before': {
      background: 'none'
    },
    '& .MuiAccordionSummary-content': {
      flexDirection: 'column',
      margin: 0,
    },
    '& .MuiAccordionSummary-root': {
      padding: 0
    },
    '& .MuiAccordionDetails-root': {
      paddingTop: 0
    }
  },
  'accordion-title': {
    paddingBottom: 15,
    fontSize: '18px',
    fontFamily: '"SegoeBold", sans-serif',
    textAlign: 'center'
  },
  'accordion-inner': {
    paddingBottom: 15,
    overflow: 'hidden',
    textAlign: 'center',
    transition: ({ open }) => open ? 'all 0.25s ease' : 'all 0.35s ease'
  },
  'accordion-overflow-label': {
    color: '#ffffff',
    fontSize: '14px',
    textAlign: 'center',
    paddingBottom: 15,
    fontFamily: '"SegoeBlack", sans-serif',
    lineHeight: '14px',
    opacity: ({ open }) => open ? '0' : '1',
    transition: ({ open }) => open ? 'opacity 0.25s ease' : 'opacity 0.3s ease',
  },
  'main-accordion-wrapper': {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '25px',
    padding: '15px 15px 0 15px',
    boxShadow: '5px 5px 30px #00000029',
    overflow: 'hidden',
    cursor: 'pointer'
  },
  'accordion-modifier': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '14px',
    borderRadius: '25px',
    backgroundColor: theme.palette.primary.main,
    minHeight: 30,
    fontFamily: '"SegoeSemiBold", sans-serif',
    color: theme.palette.info.main,
    fontSize: '14px',
    textAlign: 'center',
    padding: 5,
    marginBottom: 15,
    WebkitHyphens: 'auto',
    MsHyphens: 'auto',
    hyphens: 'auto'
  }
}))

AccordionPrompt.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  modifier: PropTypes.string,
}

export default React.memo(AccordionPrompt)