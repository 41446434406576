import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { addPreset, addRemoveCard, getCards } from '../../../actions/admin'
import { Grid, makeStyles, useTheme } from '@material-ui/core'
import CardItem from '../components/CardItem'
import SearchCardList from './components/SearchCardList'
import StyledInput from '../../General/StyledInput'
import GeneralButton from '../components/GeneralButton'


const CreatePreset = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyles()
    const presetState = useSelector(d => d.preset)
    const selectedCards = presetState.cards.filter(card => card.isSelected)
    const [name, setName] = useState("")
    const canSave = name && (selectedCards.length > 0)

    useEffect(() => {
        dispatch(getCards())
    }, [dispatch])

    const handleTextChange = e => {
        e.persist()
        setName(e.target.value)
    }

    const handleCardAddRemove = id => {
        dispatch(addRemoveCard(id))
    }

    const handleSave = async e => {
        await dispatch(addPreset({
            name: name,
            cards: selectedCards,
        }))
        history.push('/presets')
    }

    return (
        <Grid
            container
            spacing={4}
            className="full-height"
        >
            <Grid item xs={12} sm={6} md={6} className="full-height">
                <StyledInput
                    onChange={handleTextChange}
                    type="text"
                    placeholder="Name preset.."
                    height="38px"
                />

                <div className={classes["create-preset-label"]}>
                    You have {selectedCards.length}
                    {selectedCards.length === 1 ? ' card ' : ' cards '}
                    in this preset
                </div>

                <div className={classes["create-preset-selected"]}>
                    {selectedCards.map(card => (
                        <CardItem
                            key={card.id}
                            card={card}
                            textLength={20}
                            canRemove={true}
                            handleRemove={() => handleCardAddRemove(card.id)}
                        />
                    ))}
                </div>
                <GeneralButton
                    fontColor={theme.palette.info.main}
                    backgroundColor={theme.palette.primary.main}
                    onClick={handleSave}
                    disabled={!canSave}
                >
                    Confirm preset
                </GeneralButton>
            </Grid>

            <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className={`${classes["create-preset-left"]} full-height`}
            >
                <SearchCardList />
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    'create-preset-label': {
        color: theme.palette.info.main,
        fontFamily: '"SegoeBold", sans-serif',
        textAlign: 'center',
        fontSize: '15px',
        padding: '15px 0'
    },
    'general-button': {
        color: theme.palette.info.main,
        textTransform: 'none',
        fontFamily: '"SegoeBlack", sans-serif',
        fontSize: '16px',
        height: 50,
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100px',
        cursor: 'pointer',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    'general-button-disabled': {
        backgroundColor: '#d3d3d3'
    },
    'create-preset-selected': {
        marginBottom: 15,
        height: 'calc(100% - 120px)',
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch',
        scrollbarWidth: 'none',
        '&-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    'create-preset-left': {
        position: 'relative'
    },
    "create-preset-search": {
        paddingLeft: 40
    }
}))

export default CreatePreset