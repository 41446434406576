import * as actionTypes from '../actions/types';

const initialState = {
    username: '',
    isLoading: false,
    error: ''
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BEGIN_LOG_IN:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.LOG_IN:
            return {
                ...state,
                isLoading: false,
                username: action.payload.username,
                error: action.payload.error
            }
        case actionTypes.LOG_OUT:
            return {
                ...state,
                username: '',
                isLoading: false,
                error: ''
            }
        case actionTypes.BEGIN_CHANGE_PASSWORD:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.CHANGE_PASSWORD:
            return {
                ...state,
                isLoading: false,
                error: ''
            }
        case actionTypes.ERROR_CHANGE_PASSWORD:
            return {
                ...state,
                username: '',
                isLoading: false,
                error: action.payload.error
            }
        default:
            return state
    }
}