import React from 'react'
import {
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const ButtonLowerLayer = ({
  fill = "none",
  stroke = DEFAULT_SECONDARY_COLOR_CODE,
  disabled = false,
  ...others
}) => {
  return (
    <svg
      className="bottom-layer"
      xmlns="http://www.w3.org/2000/svg"
      width={197.636}
      height={140.939}
      viewBox="0 0 197.636 140.939"
      {...others}
    >
      <g
        id="bottom_group"
        data-name="Group 255"
        style={disabled ? { opacity: 0.5 } : {}}
      >
        <path
          data-name="Button lower layer"
          d="M184.716 84.833l-76.833 48.485a16.981 16.981 0 01-18.127 0L12.921 84.833a16.985 16.985 0 010-28.728L89.756 7.62a16.987 16.987 0 0118.127 0l76.833 48.485a16.984 16.984 0 010 28.728z"
          fill={fill}
          stroke={stroke}
          strokeMiterlimit={10}
          strokeWidth="10px"
        />
      </g>
    </svg>
  )
}

export default ButtonLowerLayer
