import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import Loading from "./Components/Loading"
import JoiningGame from "./Components/JoiningGame"
import WaitingForPlayers from "./Components/WaitingForPlayers"
import Round from "./Components/Round"
import Roles from "./Components/Roles"
import Prompt from "./Components/Prompt"
import Feedback from "./Components/Feedback"
import Scores from "./Components/Scores"
import Difficulty from './Components/Difficulty'


export default function Game() {
    const gameState = useSelector(d => d.game)
    let component
    if (!gameState.player.username) {
        component = <JoiningGame />
    } else if (gameState.game === null) {
        component = <Loading />
    } else {
        switch (gameState.game.stateName) {
            case "PLAYERS_JOINING":
                component = <WaitingForPlayers />
                break
            case "DISPLAYING_ROUND":
                component = <Round />
                break
            case "DISPLAYING_ROLES":
                component = <Roles />
                break
            case "CHOOSING_DIFFICULTY":
                component = <Difficulty />
                break
            case "READING_PROMPT":
                component = <Prompt />
                break
            case "PERFORMING_CHALLENGE":
                component = <Prompt />
                break
            case "GIVING_FEEDBACK":
                component = <Feedback />
                break
            case "DISPLAYING_SCORES":
                component = <Scores isFinal={false} />
                break
            case "GAME_ENDED":
                component = <Scores isFinal={true} />
                break
            default:
                break
        }
    }
    return (
        <div className="Main">
            {!gameState.isSignalRConnected &&
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center" className="reconnecting-overlay">
                    Entering your Chambr...
                </Grid>
            }
            {component}
        </div>
    )
}