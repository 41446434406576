import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { deleteTemplate, generateCodes, getTemplates, selectTemplate } from '../../../actions/admin'
import {
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    useTheme
} from '@material-ui/core'
import Code from '../components/Code'
import GeneralButton from '../components/GeneralButton'
import Badge from '../../Svg/Badge'
import Hashtag from '../../Svg/Hashtag'
import Pen from '../../Svg/Pen'
import Weight from '../../Svg/Weight'
import Timer from '../../Svg/Timer'
import ConfirmationPopup from '../components/ConfirmationPopup'


export default function TemplateList() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()
    const templateState = useSelector(d => d.template)
    const codesListBottom = useRef(null)
    const chambrList = useRef(null)
    const [isTemplateDeletePopupUpen, setIsTemplateDeletePopupUpen] = useState(false)

    useEffect(() => {
        if (codesListBottom.current) {
            setTimeout(() => {
                if (codesListBottom && codesListBottom.current) {
                    codesListBottom.current.scrollIntoView({
                        behavior: "smooth"
                    })
                }
            }, 1000)
        }
    }, [templateState.selectedItem])

    useEffect(() => {
        dispatch(getTemplates())
    }, [dispatch])

    const handleChange = (value, currentItemId) => {
        dispatch(selectTemplate(templateState.items[value].id))
    }

    const handleDeleteTemplate = id => {
        dispatch(deleteTemplate(id))
        handleTemplateDeletePopupClose()
    }

    const handleGenerateCodes = id => {
        dispatch(generateCodes(id))
    }

    const handleTemplateDeletePopupClose = () => {
        setIsTemplateDeletePopupUpen(false)
    }

    // const splitCodesList = (arr, numGroups) => {
    //     const perGroup = Math.ceil(arr.length / numGroups)

    //     return new Array(numGroups)
    //         .fill('')
    //         .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup))
    // }

    const splitCodesList = arr => {
        let array1 = [], array2 = [], array3 = [];
        for (let i = 0; i < arr.length; i++) {
            if (i % 3 === 0) {
                array1.push(arr[i]);
            } else if (i % 3 === 1) {
                array2.push(arr[i]);
            } else {
                array3.push(arr[i]);
            }
        }

        return [array1, array2, array3];
    }

    return (
        <div className={classes["chambr-list"]} ref={chambrList}>
            {templateState.items.length === 0 && <h2 className={classes["none"]}>No chambrs here...</h2>}
            {templateState.items.map((item, index) => (
                <Accordion
                    key={item.id}
                    id={item.id}
                    expanded={templateState &&
                        templateState.selectedItem &&
                        (item.id === templateState.selectedItem.id || false)
                    }
                    onChange={(event, expanded) => handleChange(index, item.id)}
                    className={classes["chambr-card"]}
                >
                    <AccordionSummary id={`panel${item.id}`}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={12} md={12} lg={5}>
                                <div
                                    title={item.preset.name}
                                    className={classes["chambr-card-title"]}
                                >
                                    {item.preset.name}
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={7}>
                                <Grid container spacing={2}>
                                    <Grid item xs className={classes["chambr-card-info"]}>
                                        <Timer fill={theme.palette.secondary.main} />
                                        <div className={classes['chambr-card-note']}>
                                            Timer
                                        </div>
                                        <div>
                                            {`${item.seconds}sec / turn`}
                                        </div>
                                    </Grid>
                                    <Grid item xs className={classes["chambr-card-info"]}>
                                        <Hashtag fill={theme.palette.secondary.main} />
                                        <div className={classes['chambr-card-note']}>
                                            Number of rounds
                                        </div>
                                        <div>
                                            {item.roundCount} {item.roundCount > 1 ? 'rounds' : 'round'}
                                        </div>
                                    </Grid>
                                    <Grid item xs className={classes["chambr-card-info"]}>
                                        <Pen fill={theme.palette.secondary.main} />
                                        <div className={classes['chambr-card-note']}>
                                            Title
                                        </div>
                                        <div>{item.hasLabel ? 'Active' : 'Inactive'}</div>
                                    </Grid>
                                    <Grid item xs className={classes["chambr-card-info"]}>
                                        <Weight fill={theme.palette.secondary.main} />
                                        <div className={classes['chambr-card-note']}>
                                            Extra level of difficulty
                                        </div>
                                        <div>{item.useModifier ? 'On' : 'Off'}</div>
                                    </Grid>
                                    <Grid item xs className={classes["chambr-card-info"]}>
                                        <Badge fill={theme.palette.secondary.main} />
                                        <div className={classes['chambr-card-note']}>
                                            Game type
                                        </div>
                                        <div>
                                            {(item.hasRating && item.hasFeedback) && 'Comp & Feedback'}
                                            {(item.hasRating && !item.hasFeedback) && 'Competitive'}
                                            {(!item.hasRating && item.hasFeedback) && 'Feedback'}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>

                    {templateState.selectedItem && templateState.selectedItem.gameCodes &&
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={4}>
                                    <div className={`${classes["chambr-card-title"]} ${classes["extra-space"]}`}>
                                        Codes generated
                                    </div>

                                    <Grid container spacing={4} className={classes["extra-space"]}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <GeneralButton
                                                fontColor="#ffffff"
                                                backgroundColor={theme.palette.secondary.main}
                                                onClick={() => setIsTemplateDeletePopupUpen(true)}
                                                className={classes["presets-round-btn"]}
                                            >
                                                Delete chambr
                                            </GeneralButton>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6}>
                                            <GeneralButton
                                                fontColor={theme.palette.info.main}
                                                backgroundColor={theme.palette.primary.main}
                                                onClick={() => handleGenerateCodes(templateState.selectedItem.id)}
                                                className={classes["presets-round-btn"]}
                                            >
                                                Generate codes
                                            </GeneralButton>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={8}>
                                    <Grid container spacing={2}>
                                        {splitCodesList(templateState.selectedItem.gameCodes).map((arr, index) => (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                key={`codes-column${index}`}
                                                className={classes["chambr-codes-column"]}
                                            >
                                                {arr.map((code, index) => (
                                                    <Code key={code.id} code={code} />
                                                ))}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {(index === templateState.items.length - 1 && item.id === templateState.selectedItem.id) &&
                                <Grid container ref={codesListBottom} className="codes-list-bottom" />
                            }
                        </AccordionDetails>
                    }
                </Accordion>
            ))}
            <ConfirmationPopup
                isOpen={isTemplateDeletePopupUpen}
                onClose={handleTemplateDeletePopupClose}
                onYes={() => handleDeleteTemplate(templateState.selectedItem.id)}
                onNo={handleTemplateDeletePopupClose}
                title="Deleting chambr"
                text="Are you sure you want to delete this chambr?"
                yesText='YES, DELETE!'
            />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    'none': {
        color: theme.palette.info.main,
        textTransform: 'none',
        fontFamily: '"SegoeBlack", sans-serif',
        padding: '20px 0 0 50px',
    },
    'chambr-card': {
        border: 'none',
        background: 'none',
        color: theme.palette.info.main,
        boxShadow: 'none',
        marginBottom: 15,
        '&:last-child': {
            mrginBottom: 0
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0'
        },
        '&.MuiAccordion-root.Mui-expanded': {
            margin: 0
        },
        '&.MuiAccordion-root:before': {
            background: 'none'
        },
        '& .MuiAccordionSummary-root': {
            padding: '10px 50px',
            height: 100,
            backgroundColor: theme.palette.primary.main,
            borderRadius: '100px !important',
            fontFamily: '"SegoeBlack", sans-serif',
        },
        '& .MuiAccordionSummary-content': {
            flexDirection: 'column',
            margin: 0,
            width: '100%'
        },
        '& .MuiAccordionDetails-root': {
            padding: '30px 16px',
            flexDirection: 'column'
        }
    },
    'chambr-card-title': {
        fontSize: '25px',
        fontFamily: '"SegoeBlack", sans-serif',
        marginBottom: 15,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: 18
        },
    },
    'chambr-card-info': {
        textAlign: 'center',
        overflow: 'hidden',
        '& div': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: 12
            },
        },
        '& svg': {
            height: 40,
            width: 'auto',
            [theme.breakpoints.between('sm', 'md')]: {
                height: 20
            },
        }
    },
    'extra-space': {
        paddingLeft: 60,
        [theme.breakpoints.between('sm', 'md')]: {
            paddingLeft: 0,
        },
    },
    'chambr-codes-column': {
        textAlign: 'right'
    },
    'chambr-card-note': {
        fontSize: '10px',
        fontFamily: '"SegoeSemiBold", sans- serif'
    }
}));