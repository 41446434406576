import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Avatar6 = (props) => {
  const {
    fill = DEFAULT_PRIMARY_COLOR_CODE,
    stroke = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
      className={className}
      {...others}
    >
      <rect
        x={176.92}
        y={176.92}
        width={646.15}
        height={646.15}
        rx={111.77}
        transform="rotate(45 500.004 499.998)"
        fill={fill}
      />
      <circle
        className="cls-2"
        cx={557.68} cy={446.38} r={29.23}
        fill="none"
        stroke={stroke}
        strokeWidth="25px"
        strokeMiterlimit="10"
      />
      <circle
        className="cls-2"
        cx={436.82} cy={446.38} r={48.33}
        fill="none"
        stroke={stroke}
        strokeWidth="25px"
        strokeMiterlimit="10"
      />
      <path
        className="cls-2"
        d="M408.28 575.3c28.62 24 74.88 27.7 104.54-16.12 22.25-32.88 77.32-31.11 89.91-.14"
        fill="none"
        stroke={stroke}
        strokeWidth="25px"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

Avatar6.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string
}

export default Avatar6
