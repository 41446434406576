import React from 'react'
import { useSelector } from 'react-redux'

import Prompt from './Prompt'
import GivingFeedback from './GivingFeedback'
import FeedbackGiven from './FeedbackGiven'


export default function Feedback() {
    const gameState = useSelector(d => d.game)
    const speaker = gameState.game.speaker
    const isSpeaker = speaker.player.connectionId === gameState.player.connectionId

    if (!isSpeaker) {
        var givenFeedback = speaker.player.feedbacks.some(d =>
            (d.playerConnectionId === gameState.player.connectionId)
            && (d.round === gameState.game.round)
        )
    }

    return (
        <>
            {isSpeaker && <Prompt />}
            {!isSpeaker && givenFeedback && <FeedbackGiven />}
            {!isSpeaker && !givenFeedback && <GivingFeedback />}
        </>
    )
}