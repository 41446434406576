import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Badge = (props) => {
  const {
    fill = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25.911}
      height={34.549}
      viewBox="0 0 25.911 34.549"
      className={className}
      {...others}
    >
      <path
        data-name="Icon awesome-award"
        d="M6.553 24.469c-.586-.586-.281-.421-1.7-.8a4.868 4.868 0 01-1.716-.9L.081 30.277a1.08 1.08 0 001.041 1.487l3.555-.136 2.445 2.583a1.079 1.079 0 001.783-.335l3.512-8.611a4.842 4.842 0 01-5.865-.8zm19.277 5.808l-3.061-7.506a4.857 4.857 0 01-1.716.9c-1.422.381-1.11.215-1.7.8a4.843 4.843 0 01-5.866.8L17 33.876a1.079 1.079 0 001.783.335l2.446-2.583 3.555.136a1.08 1.08 0 001.041-1.487zm-8.084-7.335c1.031-1.049 1.149-.959 2.617-1.359a2.742 2.742 0 001.921-1.955c.5-1.916.374-1.685 1.751-3.087a2.8 2.8 0 00.7-2.671c-.5-1.915-.5-1.648 0-3.565a2.8 2.8 0 00-.7-2.671c-1.377-1.4-1.246-1.171-1.751-3.087a2.742 2.742 0 00-1.921-1.955c-1.881-.514-1.655-.379-3.033-1.782a2.685 2.685 0 00-2.623-.716c-1.881.513-1.618.514-3.5 0A2.683 2.683 0 008.58.81C7.2 2.212 7.43 2.078 5.548 2.592a2.742 2.742 0 00-1.921 1.955c-.5 1.916-.374 1.685-1.751 3.087a2.8 2.8 0 00-.7 2.671c.5 1.914.5 1.646 0 3.564a2.8 2.8 0 00.7 2.671c1.377 1.4 1.246 1.171 1.751 3.087a2.742 2.742 0 001.921 1.955c1.51.412 1.623.347 2.617 1.359a2.682 2.682 0 003.356.393 2.677 2.677 0 012.87 0 2.681 2.681 0 003.355-.392zM6.59 11.873a6.367 6.367 0 116.366 6.479 6.423 6.423 0 01-6.366-6.479z"
        transform="translate(0 .001)"
        fill={fill}
      />
    </svg>
  )
}

Badge.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
}

export default Badge