import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Vip = (props) => {
  const {
    fill = DEFAULT_SECONDARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={31.493}
      height={15.469}
      viewBox="0 0 31.493 15.469"
      className={className}
      {...others}
    >
      <path
        data-name="Icon ionic-logo-game-controller-a"
        d="M25.966 10.266H10.034a7.583 7.583 0 00-7.784 7.692 7.649 7.649 0 007.784 7.777h15.925a7.649 7.649 0 007.784-7.777 7.573 7.573 0 00-7.777-7.692zm-11.9 8.487a.383.383 0 01-.366.373h-2.45v2.468a.4.4 0 01-.408.345h-1.5A.324.324 0 019 21.621v-2.5H6.532a.4.4 0 01-.345-.408v-1.5a.324.324 0 01.316-.337H9v-2.448a.342.342 0 01.323-.366h1.554a.383.383 0 01.373.366v2.447h2.45a.342.342 0 01.366.323zm8.423.619a1.35 1.35 0 111.371-1.35 1.364 1.364 0 01-1.374 1.349zm2.988 2.939a1.347 1.347 0 111.371-1.343 1.358 1.358 0 01-1.374 1.342zm0-5.885a1.347 1.347 0 111.371-1.343 1.363 1.363 0 01-1.374 1.342zm3 2.946a1.35 1.35 0 111.371-1.35 1.358 1.358 0 01-1.378 1.349z"
        transform="translate(-2.25 -10.266)"
        fill={fill}
      />
    </svg>
  )
}

Vip.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
}

export default Vip
