import { combineReducers } from 'redux';
import { gameReducer } from './gameReducer';
import { presetsReducer } from './presetsReducer';
import { replaysReducer } from './replaysReducer';
import { templatesReducer } from './templatesReducer';
import { userReducer } from './userReducer';
import { usersReducer } from './usersReducer';

const allReducers = combineReducers({
    game: gameReducer,
    user: userReducer,
    users: usersReducer,
    preset: presetsReducer,
    template: templatesReducer,
    replays: replaysReducer,
});

export default allReducers;