import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { nextRound, gameEnded } from '../../../actions/index'

import { makeStyles } from '@material-ui/core'
import Layout from './Layout'
import Player from './Player'
import FancyButton from '../../General/FancyButton'
import RoundScore from './RoundScore'
import TextFeedbacks from './TextFeedbacks'


export default function Scores({ isFinal = false }) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const gameState = useSelector(d => d.game)
    const history = useHistory()
    const [step, setStep] = useState(0)

    const round = gameState.game.round - 1
    const currentPlayer = gameState.game.players.find(d => d.connectionId === gameState.player.connectionId)
    const sortedPlayers = [...gameState.game.players].sort((a, b) => b.score - a.score)
    const roundFeedbacks = currentPlayer.feedbacks.filter(d => d.round === round)
    const roundScore = roundFeedbacks.reduce((sum, current) => sum + current.mark, 0)
    const hasRating = gameState.game.template.hasRating
    const hasFeedback = gameState.game.template.hasFeedback

    if (isFinal) {
        if (localStorage.getItem('lastUsername')
            && localStorage.getItem('lastGameCode')) {
            localStorage.removeItem('lastUsername')
            localStorage.removeItem('lastGameCode')
        }
    }

    const advanceScreen = e => {
        setStep(step + 1)
    }

    const handleNextRound = e => {
        dispatch(nextRound())
    }

    const handleGameEnded = e => {
        dispatch(gameEnded())
        history.push('')
    }

    return (
        <>
            {hasRating && step === 0 &&
                <RoundScore
                    currentPlayer={currentPlayer}
                    score={roundScore}
                    handleNext={advanceScreen}
                />
            }
            {((hasFeedback && !hasRating && (step === 0)) || (hasFeedback && hasRating && (step === 1))) &&
                <TextFeedbacks
                    currentPlayer={currentPlayer}
                    feedbacks={roundFeedbacks}
                    handleNext={advanceScreen}
                    hasRating={hasRating}
                />
            }
            {((step === 2) || ((step === 1) && !(hasRating && hasFeedback))) &&
                <Layout currentPlayer={currentPlayer} shouldBackgroundRotateForward={true}>
                    <div className={classes["scores-page"]}>
                        {isFinal &&
                            <div
                                className={`${classes["scores-green-title"]} ${classes["scores-upper"]}`}
                            >
                                {hasRating ? "Final scores" : "Thank you for playing"}
                            </div>
                        }

                        {!isFinal &&
                            <div className={classes["scores-upper"]}>
                                {hasRating && <>
                                    <div
                                        className={`${classes["scores-green-title"]} ${classes["title"]}`}
                                    >
                                        Round {round}
                                    </div>
                                    <div className={classes["scores-subtitle"]}>Here are the scores so far</div>
                                </>}
                                {!hasRating && <div
                                    className={`${classes["scores-green-title"]} ${classes["scores-upper"]}`}
                                >
                                    End of round {round}
                                </div>}
                            </div>
                        }

                        <div className={classes["scores-list"]}>
                            {sortedPlayers.map((player, index) => (
                                <Player
                                    key={player.username}
                                    index={player.index}
                                    username={player.username}
                                    score={hasRating ? player.score : null}
                                    place={hasRating ? index + 1 : null}
                                />
                            ))}
                        </div>

                        {currentPlayer && currentPlayer.isVip && !isFinal &&
                            <div className={classes["scores-button"]}>
                                <FancyButton
                                    label="Next Round"
                                    width="63px"
                                    height="44px"
                                    labelFontSize="14px"
                                    onClick={handleNextRound}
                                />
                            </div>
                        }
                        {isFinal &&
                            <div className={classes["scores-button"]}>
                                <FancyButton
                                    label="Start new Chambr"
                                    width="63px"
                                    height="44px"
                                    labelFontSize="14px"
                                    onClick={handleGameEnded}
                                />
                            </div>
                        }
                    </div>
                </Layout >
            }
        </>
    )
}

const useStyles = makeStyles(theme => ({
    'scores-page': {
        paddingTop: '8vh',
        width: '100%',
        maxWidth: 300,
        margin: '0 auto',
        textAlign: 'center'
    },
    'scores-list': {
        width: '100%'
    },
    'scores-center': {
        textAlign: 'center'
    },
    'scores-green-title': {
        fontFamily: '"SegoeBlack", sans-serif',
        color: theme.palette.primary.main,
        fontSize: '26px',
        lineHeight: '26px',
        textAlign: 'center',
    },
    'title': {
        textTransform: 'uppercase'
    },
    'scores-subtitle': {
        fontFamily: '"SegoeBold", sans-serif',
        fontSize: '15px',
        color: theme.palette.info.main
    },
    'scores-upper': {
        paddingBottom: 30,
    },
    'scores-button': {
        paddingTop: '10vh'
    },
    'score-value': {
        fontSize: '95px',
        lineHeight: '90px'
    },
    'score-icon': {
        width: 60,
        display: 'block',
        margin: '0 auto',
        paddingBottom: 5
    },
    'score-icon-label': {
        fontSize: '10px',
        fontFamily: '"SegoeBold", sans-serif',
        color: theme.palette.info.main,
        backgroundColor: theme.palette.primary.main,
        padding: '3px 10px',
        borderRadius: '10px'
    },
    'scores-next-button': {
        paddingTop: '5vh'
    },
    'scores-result-page': {
        display: 'flex',
        height: 'calc(100vh - 85px)',
        flexDirection: 'column',
        justifyContent: 'center'
    }

}))

Scores.propTypes = {
    isFinal: PropTypes.bool.isRequired
}