import React from 'react'
import { Grid } from '@material-ui/core'

export default function JoiningGame() {
    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center" className="reconnecting-overlay">
            Entering Chambr...
        </Grid>)
}