import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { speakerReady } from '../../../actions/index'

import { makeStyles, useTheme } from '@material-ui/core'
import Layout from './Layout'
import FancyButton from '../../General/FancyButton'
import SpeakerIcon from '../../Svg/SpeakerIcon'
import ListenerIcon from '../../Svg/ListenerIcon'

export default function Roles() {
    const dispatch = useDispatch()
    const classes = useStyles()
    const theme = useTheme()
    const gameState = useSelector(d => d.game)
    const isSpeaker = gameState.game.speaker.player.connectionId === gameState.player.connectionId
    const currentPlayer = gameState.game.players.find(d => d.connectionId === gameState.player.connectionId)

    const handleClick = e => {
        dispatch(speakerReady())
    }

    return (
        <Layout
            contentClassName={classes["role-page"]}
            currentPlayer={currentPlayer}
        >
            <div>
                {isSpeaker ?
                    <div className={classes["role-visuals"]}>
                        <div className={classes["role-title"]}>
                            For this turn you are <br></br> the SPEAKER
                        </div>
                        <SpeakerIcon
                            className={`${classes["role-image"]} speaker`}
                            fillDark={theme.palette.secondary.main}
                            fillLight={theme.palette.primary.main}
                            stroke={theme.palette.secondary.main}
                        />
                    </div> :
                    <div className={classes["role-visuals"]}>
                        <div className={classes["role-title"]}>
                            For this turn you are <br></br> the LISTENER
                        </div>
                        <ListenerIcon
                            className={classes["role-image"]}
                            fillDark={theme.palette.secondary.main}
                            fillLight={theme.palette.primary.main}
                        />
                    </div>
                }

                {isSpeaker &&
                    <div className={classes["role-button"]}>
                        <FancyButton
                            label="Start turn"
                            width="100px"
                            height="65px"
                            onClick={handleClick}
                        />
                    </div>
                }
            </div>
        </Layout>
    )
}

const useStyles = makeStyles(theme => ({
    'role-page': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
    },
    'role-title': {
        fontSize: '20px',
        paddingBottom: '5vh',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center'
    },
    'role-image': {
        margin: '0 auto',
        display: 'block',
        width: 105,
        height: 'auto',
        '&.speaker': {
            width: 80
        }
    },
    'role-visuals': {
        paddingTop: '8vh'
    },
    'role-button': {
        paddingTop: '10vh'
    }
}))