import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addRemoveCard, getCards, createCard, deleteCard } from '../../../../actions/admin'
import { makeStyles, useTheme } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CardForm from '../../components/CardForm'
import CardItem from '../../components/CardItem'
import StyledInput from '../../../General/StyledInput'
import GeneralButton from '../../components/GeneralButton'
import ConfirmationPopup from '../../components/ConfirmationPopup'


const SearchCardList = React.memo(() => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const presetState = useSelector(d => d.preset)
  const [isSearch, setIsSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [idCardToDelete, setIdCardToDelete] = useState(null)

  useEffect(() => {
    dispatch(getCards())
  }, [dispatch])

  const handleSearchChange = e => {
    e.persist()
    setSearchText(e.target.value)
  }

  const handleCardAddRemove = id => {
    dispatch(addRemoveCard(id))
  }

  const handleCardDelete = id => {
    dispatch(deleteCard(id))
    handleCardDeletePopupClose()
  }

  const handleSwitch = () => {
    setSearchText("")
    setIsSearch(!isSearch)
  }

  const handleCardSave = async data => {
    await dispatch(createCard(data))
  }

  const handleCardDeletePopupClose = () => {
    setIdCardToDelete(null)
  }

  let availableCards = [...presetState.cards]
  if (searchText) {
    const text = searchText.toLowerCase()
    availableCards = availableCards.filter(card =>
      ((card.label && card.label.toLowerCase().includes(text))) ||
      (card.challenge && card.challenge.toLowerCase().includes(text))
    )
  }

  return (
    <>
      {isSearch ?
        <>
          <label className={classes["create-preset-search"]}>
            <SearchIcon classes={{ root: classes["search-icon"] }} />
            <StyledInput
              onChange={handleSearchChange}
              type="text"
              placeholder="Search card.."
              height="38px"
              backgroundColor={theme.palette.secondary.main}
              fontColor="#ffffff"
              className={classes["create-preset-input"]}
            />
          </label>

          <div className={classes["create-preset-cards"]}>
            {availableCards && availableCards.length > 0 && availableCards.map(card => (
              <CardItem
                key={card.id}
                card={card}
                textLength={40}
                canRemove={true}
                canAdd={!card.isSelected}
                isSelected={card.isSelected}
                handleAdd={() => handleCardAddRemove(card.id)}
                handleRemove={() => setIdCardToDelete(card.id)}
              />
            ))}
          </div>
        </> :
        <>
          <GeneralButton
            fontColor="#ffffff"
            backgroundColor={theme.palette.secondary.main}
            className={classes["create-preset-choose"]}
            onClick={handleSwitch}
          >
            Choose an existing card
          </GeneralButton>

          <div className={`${classes["create-preset-cards"]} create-preset-form`}>
            <CardForm
              handleSave={handleCardSave}
              className={classes["create-preset-form"]}
            />
          </div>
        </>
      }

      <GeneralButton
        fontColor={theme.palette.info.main}
        backgroundColor={theme.palette.primary.main}
        onClick={handleSwitch}
      >
        {isSearch ? 'Create new card' : 'Search card'}
      </GeneralButton>
      <ConfirmationPopup
        isOpen={idCardToDelete != null}
        onClose={handleCardDeletePopupClose}
        onYes={() => handleCardDelete(idCardToDelete)}
        onNo={handleCardDeletePopupClose}
        title="Deleting card"
        text="Are you sure you want to delete this card?"
        yesText='YES, DELETE!'
      />
    </>
  )
})

const useStyles = makeStyles(theme => ({
  'create-preset-search': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 50
  },
  'search-icon': {
    position: 'absolute',
    color: '#ffffff',
    left: 10,
    fontSize: '30px'
  },
  'create-preset-cards': {
    height: 'calc(100% - 120px)',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'none',
    marginBottom: 15,
    '&.create-preset-form': {
      paddingRight: 10
    },
    '&-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  'create-preset-switch': {
    position: 'absolute',
    right: 50,
    zIndex: 1,
    padding: 0,
    top: 40,
    '& .MuiIconButton-label': {
      alignItems: 'center',
      height: 35
    },
    '& svg': {
      color: '#ffffff',
      fontSize: '35px',
    }
  },
  'create-preset-left': {
    position: 'relative'
  },
  'create-preset-choose': {
    marginBottom: 50,
  },
  'create-preset-form': {
    height: 'calc(100% - 120px)',
    marginBottom: 15
  },
  "create-preset-input": {
    paddingLeft: 40,
    paddingRight: 40
  }
}))

export default SearchCardList