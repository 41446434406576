import * as actionTypes from "./types"
import axios from 'axios'
import { getAxiosConfig } from './helper'

export const getReplays = () => dispatch => {
    dispatch({ type: actionTypes.BEGIN_GET_REPLAYS });
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/replays`, getAxiosConfig())
        .then(res => {
            dispatch({
                type: actionTypes.SET_REPLAYS,
                payload: res.data,
            })
        })
        .catch(err => {
            console.log(err);
            dispatch({
                type: actionTypes.ERROR_GET_REPLAYS,
                payload: err

            })
        })
}

export const deleteReplay = id => dispatch => {
    dispatch({ type: actionTypes.BEGIN_DELETE_REPLAY });
    axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/replays/${id}`, getAxiosConfig())
        .then(() => {
            dispatch({
                type: actionTypes.DELETE_REPLAY,
                payload: id,
            })
        })
        .catch(err => {
            console.log(err);
            dispatch({
                type: actionTypes.ERROR_DELETE_REPLAY,
                payload: err
            })
        })
}