import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    getPresets,
    selectPreset,
    removeCardFromPreset,
    createCardForPreset,
    deletePreset,
} from '../../../actions/admin'

import { makeStyles, Grid, useTheme, Tabs } from '@material-ui/core'
import CustomTab from '../components/CustomTab'
import CardItem from '../components/CardItem'
import CardForm from '../components/CardForm'
import GeneralButton from '../components/GeneralButton'
import ConfirmationPopup from '../components/ConfirmationPopup'

export default function PresetList() {
    const dispatch = useDispatch()
    const classes = useStyles()
    const theme = useTheme()
    const presetState = useSelector(d => d.preset)
    const [value, setValue] = useState(0)
    const [isAddingCard, setIsAddingCard] = useState(false)
    const [idCardToRemove, setIdCardToRemove] = useState(null)
    const [isPresetDeletePopupUpen, setIsPresetDeletePopupUpen] = useState(false)

    useEffect(() => {
        async function setData() {
            const items = await dispatch(getPresets())
            if (items && items.length) {
                await dispatch(selectPreset(items[0].id))
            }
        }
        setData()
        //eslint-disable-next-line
    }, [])

    const handleChange = (event, newValue) => {
        if (presetState.items && presetState.items.length) {
            setValue(newValue)
            dispatch(selectPreset(presetState.items[newValue].id))
        }
    };

    const handleCardRemove = id => {
        dispatch(removeCardFromPreset({
            idCard: id,
            idPreset: presetState.selectedItem.id,
        }))
        handleCardRemovePopupClose()
    }

    const handleCardAdd = async data => {
        await dispatch(createCardForPreset(presetState.selectedItem.id, data))
        setIsAddingCard(!isAddingCard)
    }

    const handlePresetDelete = async () => {
        if (presetState.selectedItem) {
            await dispatch(deletePreset(presetState.selectedItem.id))
            if (presetState.items.length) {
                handleChange(null, 0)
            }
        }
        handlePresetDeletePopupClose()
    }

    const handleCardRemovePopupClose = () => {
        setIdCardToRemove(null)
    }

    const handlePresetDeletePopupClose = () => {
        setIsPresetDeletePopupUpen(false)
    }

    return (
        <Grid
            container
            spacing={4}
            className="full-height wrapper-column"
        >
            {(presetState.items.length > 0) &&
                <>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        className="full-height right-column"
                    >
                        <Tabs
                            className={`${classes["presets-tabs-list"]} full-height`}
                            orientation="vertical"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical presets tabs"
                            TabIndicatorProps={{
                                style: {
                                    display: "none"
                                }
                            }}
                        >
                            {presetState.items.map(item => (
                                <CustomTab
                                    item={item}
                                    key={item.id}
                                    canEdit={true}
                                />
                            ))}
                        </Tabs>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={8}
                        className={`${classes["presets-left-section"]} left-column`}
                    >
                        <div className={`${classes["presets-list"]} ${isAddingCard ? 'extra-space' : ''}`}>
                            {presetState && presetState.selectedItem && (
                                isAddingCard ?
                                    <CardForm handleSave={handleCardAdd} />
                                    :
                                    presetState.selectedItem.cards.map(card => (
                                        <CardItem
                                            key={card.id}
                                            card={card}
                                            textLength={50}
                                            canRemove={true}
                                            canEdit={true}
                                            handleRemove={() => setIdCardToRemove(card.id)}
                                            substringCharNo={160}
                                        />
                                    ))
                            )}
                        </div>

                        <Grid container spacing={4} className={classes["bottom-actions"]}>
                            <Grid item xs={12} sm={6} md={6}>
                                <GeneralButton
                                    fontColor="#ffffff"
                                    backgroundColor={theme.palette.secondary.main}
                                    onClick={() => setIsPresetDeletePopupUpen(true)}
                                    className={classes["presets-round-btn"]}
                                >
                                    Delete preset
                                </GeneralButton>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <GeneralButton
                                    fontColor={theme.palette.info.main}
                                    backgroundColor={theme.palette.primary.main}
                                    onClick={() => setIsAddingCard(!isAddingCard)}
                                    className={classes["presets-round-btn"]}
                                >
                                    {isAddingCard ? 'Cancel' : 'Add new card'}
                                </GeneralButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </>}
            {!(presetState.items.length > 0) && <h2 className={classes["none"]}>No presets here...</h2>}
            <ConfirmationPopup
                isOpen={idCardToRemove != null}
                onClose={handleCardRemovePopupClose}
                onYes={() => handleCardRemove(idCardToRemove)}
                onNo={handleCardRemovePopupClose}
                title="Removing card"
                text="Are you sure you want to remove this card from the preset?"
                yesText='YES, REMOVE!'
            />
            <ConfirmationPopup
                isOpen={isPresetDeletePopupUpen}
                onClose={handlePresetDeletePopupClose}
                onYes={handlePresetDelete}
                onNo={handlePresetDeletePopupClose}
                title="Deleting preset"
                text="Are you sure you want to delete this preset?"
                yesText='YES, DELETE!'
            />
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    'none': {
        color: theme.palette.info.main,
        textTransform: 'none',
        fontFamily: '"SegoeBlack", sans-serif',
        padding: '20px 0 0 50px',
    },
    'presets-tabs-list': {
        '& .MuiTabs-flexContainer': {
            height: '100%',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            scrollbarWidth: 'none',
            '&-ms-overflow-style': 'none',
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        },
        '& .MuiTabs-fixed': {
            height: '100%',
            overflow: 'visible !important'
        }
    },
    'presets-list': {
        height: 'calc(100% - 65px)',
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch',
        scrollbarWidth: 'none',
        '&-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '&.extra-space': {
            paddingRight: 10,
        }
    },
    'presets-left-section': {
        position: 'relative',
        height: '100%',
    },
    'bottom-actions': {
        marginTop: '-1px !important'
    },
}));