import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { deleteCode } from '../../../actions/admin'
import { HIDE_COPY_CONFIRMATION_TIMER } from '../../../constants/general'
import PropTypes from 'prop-types'

import { makeStyles, IconButton, Grid } from '@material-ui/core'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import ClearIcon from '@material-ui/icons/Clear'

const Code = ({ code = {} }) => {
  const dispatch = useDispatch()
  const [copyConfirmationText, setCopyConfirmationText] = useState(null)
  const classes = useStyles({ copyConfirmationText })

  useEffect(() => {
    if (copyConfirmationText) {
      setTimeout(() => {
        setCopyConfirmationText(null)
      }, HIDE_COPY_CONFIRMATION_TIMER)
    }
  }, [copyConfirmationText])

  const handleCopy = () => {
    navigator.clipboard.writeText(code.value)
      .then(() => {
        setCopyConfirmationText('Copied')
      })
      .catch(() => {
        setCopyConfirmationText('Failed to copy code')
      })
  }

  const handleRemove = () => {
    dispatch(deleteCode(code.id))
  }

  return (
    <Grid
      container
      alignItems="center"
      className={classes["code-item"]}
    >
      <span
        title={code.value}
        className={classes["code-value"]}
      >
        {code.value}
      </span>

      <IconButton
        title="Copy code"
        disableRipple
        onClick={handleCopy}
        className={classes['copy-btn']}
      >
        <FilterNoneIcon className={classes["copy-icon"]} />
      </IconButton>

      <IconButton
        title="Remove code"
        disableRipple
        onClick={handleRemove}
      >
        <ClearIcon className={classes["remove-icon"]} />
      </IconButton>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  'code-item': {
    color: theme.palette.info.main,
    fontFamily: '"SegoeBlack", sans-serif',
    fontSize: '18px',
    marginBottom: 10,
  },
  'code-value': {
    marginRight: 10,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 'calc(100% - 110px)',
    display: 'inline-block'
  },
  'copy-btn': {
    position: 'relative',
    '&:after': {
      content: ({ copyConfirmationText }) => copyConfirmationText ? `"${copyConfirmationText}"` : '""',
      position: 'absolute',
      top: -5,
      right: -30,
      color: theme.palette.info.main,
      fontSize: 10,
      fontFamily: '"SegoeBlack", sans- serif',
      padding: '3px 10px',
      background: ({ copyConfirmationText }) => copyConfirmationText ? theme.palette.primary.main : 'none',
      borderRadius: 100,
      zIndex: 10
    }
  },
  'copy-icon': {
    fill: theme.palette.primary.main
  },
  'remove-icon': {
    fill: theme.palette.secondary.main,
  }
}))

Code.propTypes = {
  code: PropTypes.object.isRequired,
}

export default Code