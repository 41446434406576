import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUsers, deleteUser, createUser } from '../../../actions/users'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Checkbox,
    Backdrop,
    CircularProgress
} from '@material-ui/core'


const defaultUserData = {
    email: "",
    password: "",
    isLogActive: false
}

const tableStyle = {
    borderCollapse: "collapse",
    border: "1px solid #000000",
    marginTop: "10px"
}
const tableCellStyle = {
    border: "1px solid #000000",
    padding: "5px"
}

export default function UsersList() {
    const dispatch = useDispatch()
    const usersState = useSelector(d => d.users)
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
    const [userData, setUserData] = useState(defaultUserData);

    useEffect(() => {
        dispatch(getUsers())
    }, [dispatch])

    const handleDeleteClick = (e, cellValues) => {
        setSelectedEmail(cellValues.id)
        setIsDeletePopupOpen(true)
    }

    const handleDeletePopupClose = () => {
        setIsDeletePopupOpen(false)
    };

    const handleDeleteConfirmed = () => {
        dispatch(deleteUser(selectedEmail))
        setIsDeletePopupOpen(false)
        setSelectedEmail(null)
    };

    const handleAddPopupClose = () => {
        setIsAddPopupOpen(false)
        setUserData(defaultUserData)
    };

    const handleAdd = () => {
        dispatch(createUser(userData.email, userData.password, userData.isLogActive));
        setIsAddPopupOpen(false)
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={usersState.isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div>
                <Button variant="contained" color="primary" onClick={() => setIsAddPopupOpen(true)}>Add User</Button>
            </div>
            {usersState.error && <div>
                <div>
                    <b>{usersState.error}</b>
                </div>
            </div>}
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={tableCellStyle}>Username</th>
                        <th style={tableCellStyle}>Email</th>
                        <th style={tableCellStyle}>Is Log Active</th>
                        <th style={tableCellStyle}>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {usersState.users && usersState.users.map((item, index) => {
                        return <tr key={index}>
                            <td style={tableCellStyle}>{item.username}</td>
                            <td style={tableCellStyle}>{item.email}</td>
                            <td style={tableCellStyle}>{item.isLogActive ? "✓" : "X"}</td>
                            <td style={tableCellStyle}><Button variant="contained" color="primary" onClick={e => handleDeleteClick(e, item)}>Delete</Button></td>
                        </tr>
                    })}
                </tbody>
            </table>
            <Dialog
                open={isDeletePopupOpen}
                keepMounted
                onClose={handleDeletePopupClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Delete is forever"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete <b>{selectedEmail}</b> and all of his cards, presets and templates?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleDeletePopupClose}>NO</Button>
                    <Button onClick={handleDeleteConfirmed}>YES, DELETE!</Button>
                </DialogActions>
            </Dialog>

            {isAddPopupOpen && <Dialog
                open={isAddPopupOpen}
                keepMounted
                onClose={handleAddPopupClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Add User"}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        autoComplete="off"
                        onChange={(e) => {
                            setUserData({
                                ...userData,
                                email: e.target.value
                            })
                        }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        autoComplete="off"
                        onChange={(e) => {
                            setUserData({
                                ...userData,
                                password: e.target.value
                            })
                        }}
                    />
                    Log Active
                    <Checkbox
                        id="isLogActive"
                        label="Log Active"
                        onChange={(e) => {
                            setUserData({
                                ...userData,
                                isLogActive: e.target.checked
                            })
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddPopupClose}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleAdd}>Create</Button>
                </DialogActions>
            </Dialog>}
        </>
    )
}