import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as microsoftTeams from "@microsoft/teams-js"

import { makeStyles, useTheme } from '@material-ui/core'
import Loading from "./Components/Loading"
import Layout from './Components/Layout'
import FancyButton from '../General/FancyButton'
import Footer from '../Terms/Footer'
import GeneralButton from '../Admin/components/GeneralButton'
import axios from 'axios'
import { setAuthData } from '../../utils'

export default function StartGame() {
    const gameState = useSelector(d => d.game)
    const history = useHistory()
    const classes = useStyles()
    const theme = useTheme()
    const [isInTeams, setIsInTeams] = useState(null)
    const [canGoToAdmin, setCanGoToAdmin] = useState(null)
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(() => {
        microsoftTeams.app.initialize().then(() => {
            setIsInTeams(true)
            setErrorMsg('')
            microsoftTeams.authentication.getAuthToken({
                successCallback: token => {
                    microsoftTeams.app.notifySuccess()
                    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/Authorization/validateAdToken/`, { token })
                        .then(res => {
                            setAuthData(res.data)
                            setCanGoToAdmin(true)
                        }).catch(() => {
                            canGoToAdmin(false)
                        })
                },
                failureCallback: message => {
                    microsoftTeams.app.notifyFailure()
                },
                resources: process.env.REACT_APP_AD_RESOURCE_URL
            })
        }).catch(() => {
            setIsInTeams(false)
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButtonClick = e => {
        history.push('/game')
    }

    const handleGoToAdmin = () => {
        if (isInTeams && canGoToAdmin) {
            history.push('/presets')
        } else {
            setErrorMsg("There is no subscription associated with this account. Please purchase a subscription in order to create a game.")
        }
    }

    return (
        <Layout
            contentClassName={classes["start-page"]}
            shouldAnimateLogo={true}
        >
            {!gameState.isSignalRConnected &&
                <Loading />
            }
            {gameState && gameState.isSignalRConnected &&
                <FancyButton
                    onClick={handleButtonClick}
                    label="Start a game"
                    width="187px"
                    height="130px"
                />
            }
            {isInTeams && <>
                <GeneralButton
                    className={classes["adminBtn"]}
                    fontColor="#ffffff"
                    backgroundColor={theme.palette.secondary.main}
                    onClick={handleGoToAdmin}>
                    Create a game
                </GeneralButton>
                <div>
                    <span style={{ color: 'red' }}>{errorMsg}</span>
                </div>
            </>}
            <Footer />
        </Layout>
    )
}

const useStyles = makeStyles(theme => ({
    'start-page': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column',
        width: '100vw',
        height: 'calc(100vh - 85px)',
        '& .press-button': {
            transform: 'translateY(-5vh)'
        }
    },
    'adminBtn': {
        width: 'auto',
        paddingInline: '30px'
    }
}))