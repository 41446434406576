import React from "react"
import PropTypes from 'prop-types'
import { makeStyles, useTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import GeneralButton from "./GeneralButton"

export default function ConfirmationPopup({ isOpen, title, text, yesText, noText, onYes, onNo, onClose }) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Dialog
      open={isOpen}
      keepMounted
      onClose={onClose}
    >
      {title && <DialogTitle disableTypography={true} classes={{ root: classes['logout-title'] }}>
        {title}
      </DialogTitle>}
      <DialogContent>
        <DialogContentText disableTypography={true} classes={{ root: classes['logout-text'] }}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GeneralButton
          fontColor={theme.palette.info.main}
          backgroundColor={theme.palette.primary.main}
          onClick={onNo}
          className={classes["presets-round-btn"]}
        >
          {noText}
          {!noText && "NO"}
        </GeneralButton>
        <GeneralButton
          fontColor="#ffffff"
          backgroundColor={theme.palette.secondary.main}
          onClick={onYes}
          className={classes["presets-round-btn"]}
        >
          {yesText}
          {!yesText && "Yes"}
        </GeneralButton>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  'logout-title': {
    fontWeight: 'bold',
    fontSize: '20px',
    color: theme.palette.info.main,
    fontFamily: '"SegoeBlack", sans-serif',
    textAlign: 'center'
  },
  'logout-text': {
    color: theme.palette.info.main,
    textAlign: 'center'
  },
}))

ConfirmationPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}