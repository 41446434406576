import React from 'react'
import PropTypes from 'prop-types'
import {
  DEFAULT_PRIMARY_COLOR_CODE,
  DEFAULT_SECONDARY_COLOR_CODE
} from '../../constants/general'

const Disapprove = (props) => {
  const {
    fillDark = DEFAULT_SECONDARY_COLOR_CODE,
    fillLight = DEFAULT_PRIMARY_COLOR_CODE,
    stroke = DEFAULT_PRIMARY_COLOR_CODE,
    className = "",
    ...others
  } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={118}
      height={118}
      viewBox="0 0 118 118"
      className={className}
      {...others}
    >
      <g data-name="Reject button" transform="translate(-138 -499)">
        <rect
          data-name="Rectangle 166"
          width={111}
          height={111}
          rx={13}
          transform="translate(145 506)"
          fill={fillLight}
        />
        <rect
          data-name="Rectangle 164"
          width={111}
          height={111}
          rx={13}
          transform="translate(138 499)"
          fill={fillDark}
        />
        <g data-name="Group 137" fill="none" stroke={stroke} strokeWidth={15}>
          <path
            data-name="Path 532"
            d="M183.575 570.06l36.554-37.158"
            transform="translate(-8.273 3.098)"
          />
          <path
            data-name="Path 533"
            d="M220.431 569.759l-37.158-36.554"
            transform="translate(-8.273 3.098)"
          />
        </g>
      </g>
    </svg>
  )
}

Disapprove.propTypes = {
  fillDark: PropTypes.string,
  fillLight: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string
}

export default Disapprove
