export const SIGNALR_CONNECTED = "SIGNALR_CONNECTED"
export const SIGNALR_DISCONNECTED = "SIGNALR_DISCONNECTED"
export const JOIN_GAME = "JOIN_GAME"
export const YOU_JOINED = "YOU_JOINED"
export const CANNOT_JOIN = "CANNOT_JOIN"
export const PLAYER_JOINED = "PLAYER_JOINED"
export const REJOIN_GAME = "REJOIN_GAME"
export const READY_UP = "READY_UP"
export const START_GAME = "START_GAME"
export const NEXT_ROUND = "NEXT_ROUND"
export const START_ROUND = "START_ROUND"
export const SPEAKER_READY = "SPEAKER_READY"
export const GAME_STATE_CHANGED = "GAME_STATE_CHANGED"
export const START_PERFORMING = "START_PERFORMING"
export const END_PERFORMING = "END_PERFORMING"
export const PERFORMING_EXPIRED = "PERFORMING_EXPIRED"
export const GIVE_FEEDBACK = "GIVE_FEEDBACK"
export const END_FEEDBACK_SESSION = "END_FEEDBACK_SESSION"
export const GAME_ENDED = "GAME_ENDED"
export const GET_GAME_STATE = "GET_GAME_STATE"
export const CHOOSE_DIFFICULTY = "CHOOSE_DIFFICULTY"
export const GAME_LOGOUT = "GAME_LOGOUT"

export const SET_PRESETS = "SET_PRESETS"
export const SELECT_PRESET = "SELECT_PRESET"
export const PRESET_CREATED = "PRESET_CREATED"
export const TEMPLATE_SELECT_PRESET = "TEMPLATE_SELECT_PRESET"
export const DELETE_PRESET = "DELETE_PRESET"
export const UPDATE_PRESET_NAME = 'UPDATE_PRESET_NAME'

export const DELETE_CARD = "DELETE_CARD"
export const REMOVE_CARD_FROM_PRESET = "REMOVE_CARD_FROM_PRESET"
export const SET_CARDS = "SET_CARDS"
export const ADD_REMOVE_CARD = "ADD_REMOVE_CARD"
export const CREATE_CARD = "CREATE_CARD"
export const UPDATE_CARD = "UPDATE_CARD"
export const ADD_CARD_TO_PRESET = "ADD_CARD_TO_PRESET"

export const SET_TEMPLATES = "SET_TEMPLATES"
export const SELECT_TEMPLATE = "SELECT_TEMPLATE"
export const DELETE_CODE = "DELETE_CODE"
export const GENERATE_CODES = "GENERATE_CODES"
export const DELETE_TEMPLATE = "DELETE_TEMPLATE"

export const BEGIN_LOG_IN = "BEGIN_LOG_IN"
export const LOG_IN = "LOG_IN"
export const LOG_OUT = "LOG_OUT"
export const REGISTER = "REGISTER"

export const BEGIN_SET_USERS = "BEGIN_SET_USERS"
export const SET_USERS = "SET_USERS"
export const ERROR_GET_USERS = "ERROR_GET_USERS"

export const BEGIN_CREATE_USER = "BEGIN_CREATE_USER"
export const CREATE_USER = "CREATE_USER"
export const ERROR_CREATE_USER = "ERROR_CREATE_USER"

export const BEGIN_DELETE_USER = "BEGIN_DELETE_USER"
export const DELETE_USER = "DELETE_USER"
export const ERROR_DELETE_USER = "ERROR_DELETE_USER"

export const BEGIN_GET_REPLAYS = "BEGIN_GET_REPLAYS"
export const SET_REPLAYS = "SET_REPLAYS"
export const ERROR_GET_REPLAYS = "ERROR_GET_REPLAYS"

export const BEGIN_DELETE_REPLAY = "BEGIN_DELETE_REPLAY"
export const DELETE_REPLAY = "DELETE_REPLAY"
export const ERROR_DELETE_REPLAY = "ERROR_DELETE_REPLAY"

export const BEGIN_CHANGE_PASSWORD = "BEGIN_CHANGE_PASSWORD"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const ERROR_CHANGE_PASSWORD = "ERROR_CHANGE_PASSWORD"