import * as actionTypes from '../actions/types';

const initialState = {
    items: [],
    isLoading: false,
    error: '',
}

export const replaysReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BEGIN_GET_REPLAYS: {
            return {
                ...state,
                items: [],
                isLoading: true,
                error: ''
            }
        }
        case actionTypes.SET_REPLAYS: {
            return {
                ...state,
                items: action.payload,
                isLoading: false,
                error: ''
            }
        }
        case actionTypes.ERROR_GET_REPLAYS: {
            return {
                ...state,
                items: [],
                isLoading: false,
                error: action.payload
            }
        }
        case actionTypes.BEGIN_DELETE_REPLAY: {
            return {
                ...state,
                isLoading: true,
                error: ''
            }
        }
        case actionTypes.DELETE_REPLAY: {
            return {
                ...state,
                items: state.items.filter(d => d.id !== action.payload),
                isLoading: false,
                error: ''
            }
        }
        case actionTypes.ERROR_DELETE_REPLAY: {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        }
        default:
            return state
    }
}