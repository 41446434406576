import React from 'react'
import { useSelector } from 'react-redux'

import { makeStyles, useTheme } from '@material-ui/core'
import Layout from './Layout'
import ListenerIcon from '../../Svg/ListenerIcon'
import PlayerIcon from '../../General/PlayerIcon'


export default function FeedbackGiven() {
    const classes = useStyles()
    const theme = useTheme()
    const gameState = useSelector(d => d.game)
    const to = gameState.game.speaker.player
    const currentPlayer = gameState.game.players.find(d => d.connectionId === gameState.player.connectionId)

    return (
        <Layout
            currentPlayer={currentPlayer}
            shouldBackgroundRotateForward={true}
        >
            <div className={classes["fdkgiven-wrapper"]}>
                <ListenerIcon
                    className={classes["fdkgiven-icon"]}
                    fillDark={theme.palette.secondary.main}
                    fillLight={theme.palette.primary.main}
                />
                <div className={classes["fdkgiven-title"]}>
                    Thank you for listening<br></br> to
                </div>

                <div className={classes["fdkgiven-user"]}>
                    <PlayerIcon
                        index={to.index}
                        className={classes["fdkgiven-speaker-icon"]}
                    />
                    <div className={classes["fdkgiven-speaker"]}>
                        {to.username}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const useStyles = makeStyles(theme => ({
    'fdkgiven-icon': {
        width: 50,
        height: 'auto',
        display: 'block',
        margin: '0 auto',
        padding: '5vh 0'
    },
    'fdkgiven-title': {
        fontSize: '25px',
        paddingBottom: '5vh',
        color: theme.palette.info.main,
        fontFamily: '"SegoeBlack", sans-serif',
        textAlign: 'center'
    },
    'fdkgiven-speaker-icon': {
        width: 180,
        margin: '0 auto'
    },
    'fdkgiven-speaker': {
        fontSize: '25px',
        fontFamily: '"SegoeBold", sans-serif',
        color: theme.palette.info.main,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textTransform: 'capitalize'
    },
    'fdkgiven-user': {
        textAlign: 'center'
    }
}))