import * as actionTypes from "./types";
import axios from 'axios';
import { handleAxiosError, getAxiosConfig } from './helper'

export const getPresets = () => async dispatch => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/preset`, getAxiosConfig())
        dispatch({
            type: actionTypes.SET_PRESETS,
            payload: res.data,
        })
        return res.data
    } catch (err) {
        handleAxiosError(err)
    }
}

export const selectPreset = id => async dispatch => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/preset/${id}`, getAxiosConfig())
        dispatch({
            type: actionTypes.SELECT_PRESET,
            payload: res.data,
        })
        return res.data
    } catch (err) {
        handleAxiosError(err)
    }
}

//am adaugat aceeasi metoda de mai sus cu alt action type pentru ca 
//ramanea selectat presetul din pagina de presets list si declansa ceva in create chambr
export const templateSelectPreset = id => dispatch => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/preset/${id}`, getAxiosConfig())
        .then(res => {
            dispatch({
                type: actionTypes.TEMPLATE_SELECT_PRESET,
                payload: res.data,
            })
        })
        .catch(err => handleAxiosError(err))
}

export const addPreset = data => async dispatch => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/preset/`,
            data,
            getAxiosConfig()
        )

        dispatch({
            type: actionTypes.PRESET_CREATED,
            payload: res.data,
        })
    } catch (err) {
        handleAxiosError(err)
    }
}

export const updatePresetName = (id, name) => async dispatch => {
    const data = {
        id: id,
        name: name
    }
    try {
        const res = await axios.put(
            `${process.env.REACT_APP_SERVER_URL}/api/preset`,
            data,
            getAxiosConfig()
        )
        dispatch({
            type: actionTypes.UPDATE_PRESET_NAME,
            payload: res.data,
        })
        return res.data
    } catch (err) {
        handleAxiosError(err)
    }
}

export const deleteCard = id => dispatch => {
    axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/cards/${id}`, getAxiosConfig())
        .then(res => {
            dispatch({
                type: actionTypes.DELETE_CARD,
                payload: id,
            })
        })
        .catch(err => handleAxiosError(err))
}

export const removeCardFromPreset = data => dispatch => {
    axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/preset/addRemoveCard`,
        data,
        getAxiosConfig(),
    )
        .then(res => {
            dispatch({
                type: actionTypes.REMOVE_CARD_FROM_PRESET,
                payload: data.idCard,
            })
        })
        .catch(err => handleAxiosError(err))
}

export const getCards = id => dispatch => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/cards/`, getAxiosConfig())
        .then(res => {
            dispatch({
                type: actionTypes.SET_CARDS,
                payload: res.data,
            })
        })
        .catch(err => handleAxiosError(err))
}

export const addRemoveCard = id => dispatch => {
    dispatch({
        type: actionTypes.ADD_REMOVE_CARD,
        payload: id,
    })
}

export const createCard = data => async dispatch => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/cards/`,
            data,
            getAxiosConfig()
        )

        dispatch({
            type: actionTypes.CREATE_CARD,
            payload: res.data,
        })
    } catch (err) {
        handleAxiosError(err)
    }
}

export const createCardForPreset = (idPreset, card) => async dispatch => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/cards/`,
            card,
            getAxiosConfig()
        )

        await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/preset/addRemoveCard`,
            {
                idCard: res.data.id,
                idPreset,
            },
            getAxiosConfig(),
        )

        dispatch({
            type: actionTypes.ADD_CARD_TO_PRESET,
            payload: res.data,
        })
    } catch (err) {
        handleAxiosError(err)
    }
}

export const updateCard = data => async dispatch => {
    try {
        const res = await axios.put(
            `${process.env.REACT_APP_SERVER_URL}/api/cards/`,
            data,
            getAxiosConfig()
        )

        dispatch({
            type: actionTypes.UPDATE_CARD,
            payload: res.data,
        })
    } catch (err) {
        handleAxiosError(err)
    }
}

export const getTemplates = () => dispatch => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/gameTemplate`, getAxiosConfig())
        .then(res => {
            dispatch({
                type: actionTypes.SET_TEMPLATES,
                payload: res.data,
            })
        })
        .catch(err => handleAxiosError(err))
}

export const createTemplate = data => async dispatch => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/gameTemplate`,
            data,
            getAxiosConfig()
        )
        return res.data
    } catch (err) {
        handleAxiosError(err)
    }
}

export const generateCodes = id => async dispatch => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/gameTemplate/generateCodes`,
            {
                idGameTemplate: id,
                codesCount: 9,
            },
            getAxiosConfig()
        )
        dispatch({
            type: actionTypes.GENERATE_CODES,
            payload: res.data,
        })
        return res.data
    } catch (err) {
        handleAxiosError(err)
    }
}

export const selectTemplate = id => dispatch => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/gameTemplate/${id}`, getAxiosConfig())
        .then(res => {
            dispatch({
                type: actionTypes.SELECT_TEMPLATE,
                payload: res.data,
            })
        })
        .catch(err => handleAxiosError(err))
}

export const deleteCode = id => dispatch => {
    axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/code/${id}`, getAxiosConfig())
        .then(res => {
            dispatch({
                type: actionTypes.DELETE_CODE,
                payload: id,
            })
        })
        .catch(err => handleAxiosError(err))
}

export const deletePreset = id => async dispatch => {
    try {
        await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/preset/${id}`, getAxiosConfig())
        dispatch({
            type: actionTypes.DELETE_PRESET,
            payload: id,
        })
    } catch (err) {
        handleAxiosError(err)
    }
}

export const deleteTemplate = id => async dispatch => {
    try {
        await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/gameTemplate/${id}`, getAxiosConfig())
        dispatch({
            type: actionTypes.DELETE_TEMPLATE,
            payload: id,
        })
    } catch (err) {
        handleAxiosError(err)
    }
}