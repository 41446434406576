import { HubConnectionBuilder } from '@aspnet/signalr';
import * as actionTypes from '../actions/types';
import store from '../store';


const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_SERVER_URL}/gamehub`)
    .build();

async function start() {
    try {
        await connection.start();
        store.dispatch({
            type: actionTypes.SIGNALR_CONNECTED
        })
        const lastUsername = localStorage.getItem('lastUsername')
        const lastGameCode = localStorage.getItem('lastGameCode')
        if (lastUsername && lastGameCode) {
            store.dispatch({
                type: actionTypes.REJOIN_GAME,
                payload: {
                    username: lastUsername,
                    code: lastGameCode,
                }
            })
        }
    } catch (err) {
        store.dispatch({
            type: actionTypes.SIGNALR_DISCONNECTED
        })
        setTimeout(start, 2000);
    }
};

connection.onclose(async () => {
    store.dispatch({
        type: actionTypes.SIGNALR_DISCONNECTED
    })
    await start();
});

start();

connection.on('YouJoined', data => {
    store.dispatch({
        type: actionTypes.YOU_JOINED,
        payload: data
    });
})

connection.on('CannotJoin', data => {
    store.dispatch({
        type: actionTypes.CANNOT_JOIN,
        payload: data.message
    });
})

connection.on('CannotRejoin', data => {
    localStorage.removeItem('lastUsername')
    localStorage.removeItem('lastGameCode')
})

connection.on('StateChanged', data => {
    store.dispatch({
        type: actionTypes.GAME_STATE_CHANGED,
        payload: data
    });
})

export function signalRMiddleware() {
    return (next) => (action) => {
        switch (action.type) {
            case actionTypes.JOIN_GAME:
                localStorage.setItem('lastUsername', action.payload.username)
                localStorage.setItem('lastGameCode', action.payload.code)
                connection.invoke('JoinGame', action.payload.username, action.payload.code);
                break;
            case actionTypes.REJOIN_GAME:
                connection.invoke('RejoinGame', action.payload.username, action.payload.code);
                break;
            case actionTypes.READY_UP:
                connection.invoke('ReadyUp');
                break;
            case actionTypes.START_GAME:
                connection.invoke('StartGame');
                break;
            case actionTypes.START_ROUND:
                connection.invoke('StartRound');
                break;
            case actionTypes.NEXT_ROUND:
                connection.invoke('NextRound');
                break;
            case actionTypes.SPEAKER_READY:
                connection.invoke('SpeakerReady');
                break;
            case actionTypes.CHOOSE_DIFFICULTY:
                connection.invoke('ChooseDifficulty', action.payload);
                break;
            case actionTypes.START_PERFORMING:
                connection.invoke('StartPerformingChallenge');
                break;
            case actionTypes.END_PERFORMING:
                connection.invoke('EndPerformingChallenge');
                break;
            case actionTypes.PERFORMING_EXPIRED:
                connection.invoke('EndPerformingChallengeFromTimeExhaustion');
                break;
            case actionTypes.GIVE_FEEDBACK:
                connection.invoke('GiveFeedback', action.payload);
                break;
            case actionTypes.END_FEEDBACK_SESSION:
                connection.invoke('EndFeedbackSession');
                break;
            case actionTypes.GET_GAME_STATE:
                connection.invoke('GetState');
                break;
            case actionTypes.GAME_LOGOUT:
                connection.invoke('LeaveGame');
                break;
            default:
                break;
        }
        return next(action);
    }
}

export default signalRMiddleware;