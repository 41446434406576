import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateCard } from '../../../actions/admin'
import PropTypes from 'prop-types'

import { makeStyles, Grid, IconButton, useMediaQuery } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import CardForm from './CardForm'


const CardItem = ({
  card,
  textLength = 50,
  canEdit = false,
  canAdd = false,
  canRemove = false,
  isSelected = false,
  handleAdd,
  handleRemove,
  substringCharNo = 115
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const isBetweenSmFirst = useMediaQuery('@media(min-width:600px) and (max-width: 767.95px)')
  const isBetweenSmSecond = useMediaQuery('@media(min-width:768px) and (max-width: 959.95px)')
  const isBetweenMd = useMediaQuery('@media(min-width:960px) and (max-width: 1279.95px)')
  const isBetweenLgFirst = useMediaQuery('@media(min-width:1280px) and (max-width: 1459.95px)')
  const [isEditOpen, setIsEditOpen] = useState(false)

  const substringCharNoOverflow = isBetweenSmFirst ? 35
    : isBetweenSmSecond ? 60
      : isBetweenMd ? 45
        : isBetweenLgFirst ? 90
          : substringCharNo


  const handleEdit = () => {
    setIsEditOpen(!isEditOpen)
  }

  const handleCardSave = data => {
    dispatch(updateCard(data))
    setIsEditOpen(!isEditOpen)
  }

  return (
    <div className={classes["card-item"]}>
      <Grid
        container
        className={classes["card-inner"]}
        alignItems="center"
      >
        <Grid
          item
          xs={2}
          container
          alignItems="center"
          className="card-title"
        >
          <b title={card.label} className={classes["card-overflow"]}>
            {card.label}
          </b>
        </Grid>

        <Grid
          item
          xs={8}
          container
          alignItems="center"
          className="card-text"
        >
          <div title={card.challenge} className={classes["card-challenge"]}>
            {card.challenge.length > substringCharNoOverflow
              ? `${card.challenge.substring(0, substringCharNoOverflow)}...`
              : card.challenge
            }
          </div>
        </Grid>

        <Grid
          item
          xs={2}
          container
          justifyContent="flex-end"
          alignItems="center"
          className="card-actions"
        >
          {canEdit &&
            <IconButton
              aria-label="edit"
              onClick={handleEdit}
              classes={{ root: classes["card-button"] }}
            >
              <EditIcon classes={{ root: classes["card-icon"] }} />
            </IconButton>
          }
          {canAdd &&
            <IconButton
              aria-label="add"
              onClick={handleAdd}
              classes={{ root: classes["card-button"] }}
            >
              <AddIcon classes={{ root: classes["card-icon"] }} />
            </IconButton>
          }
          {isSelected &&
            <CheckIcon classes={{ root: classes["card-selected"] }} />
          }
          {canRemove &&
            <IconButton
              aria-label="delete"
              onClick={handleRemove}
              classes={{ root: classes["card-button"] }}
            >
              <CloseIcon classes={{ root: classes["card-icon"] }} />
            </IconButton>
          }
        </Grid>
      </Grid>

      <div
        style={{
          maxHeight: isEditOpen ? '1000px' : 0,
          transition: isEditOpen ? 'all 0.4s ease' : 'all 0.25s ease',
          overflow: isEditOpen ? 'visible' : 'hidden',
          padding: isEditOpen ? '15px 30px 0 30px' : '0 10px'
        }}
      >
        <CardForm card={card} handleSave={handleCardSave} />
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  'card-item': {
    marginBottom: 15,
    '&:last-child': {
      marginBottom: 0
    }
  },
  'card-inner': {
    backgroundColor: theme.palette.secondary.main,
    height: 40,
    padding: '3px 20px',
    borderRadius: '100px',
    color: '#ffffff',
    [theme.breakpoints.between('sm', 'sm')]: {
      '& .card-title': {
        flexBasis: 50,
        maxWidth: 50
      },
      '& .card-text': {
        flexBasis: 'calc(100% - 110px)',
        maxWidth: 'calc(100% - 110px)'
      },
      '& .card-actions': {
        flexBasis: 60,
        maxWidth: 60
      }
    },
    [theme.breakpoints.between('md', 'md')]: {
      '& .card-title': {
        flexBasis: 70,
        maxWidth: 70
      },
      '& .card-text': {
        flexBasis: 'calc(100% - 130px)',
        maxWidth: 'calc(100% - 130px)'
      },
      '& .card-actions': {
        flexBasis: 60,
        maxWidth: 60
      }
    },
  },
  'card-overflow': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingRight: 10,
    display: 'inline-block',
    fontSize: '12px'
  },
  'card-challenge': {
    fontSize: '12px'
  },
  'card-button': {
    padding: 0,
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    }
  },
  'card-icon': {
    color: '#ffffff'
  },
  'card-selected': {
    color: theme.palette.primary.main
  }
}))

CardItem.propTypes = {
  card: PropTypes.object.isRequired,
  textLength: PropTypes.number,
  canEdit: PropTypes.bool,
  canRemove: PropTypes.bool,
  canAdd: PropTypes.bool,
  isSelected: PropTypes.bool,
  handleRemove: PropTypes.func,
  handleAdd: PropTypes.func,
  substringCharNo: PropTypes.number
}

export default React.memo(CardItem)