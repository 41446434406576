import * as actionTypes from '../actions/types';

const initialState = {
    items: [],
    selectedItem: null,
    cards: [],
}

export const presetsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PRESETS:
            return {
                ...state,
                items: action.payload,
            }
        case actionTypes.SELECT_PRESET:
            return {
                ...state,
                selectedItem: action.payload,
            }
        case actionTypes.DELETE_CARD:
            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload)
            }
        case actionTypes.REMOVE_CARD_FROM_PRESET:
            if (state.selectedItem) {
                return {
                    ...state,
                    selectedItem: {
                        ...state.selectedItem,
                        cards: state.selectedItem.cards
                            .filter(card => card.id !== action.payload)
                    },
                }
            }
            return state
        case actionTypes.SET_CARDS:
            return {
                ...state,
                cards: action.payload.map(card => {
                    return {
                        ...card,
                        isSelected: false,
                    }
                })
            }
        case actionTypes.PRESET_CREATED:
            return {
                ...state,
                cards: [],
            }
        case actionTypes.ADD_REMOVE_CARD:
            return {
                ...state,
                cards: state.cards.map(card => {
                    if (card.id === action.payload) {
                        card.isSelected = !card.isSelected
                    }
                    return card
                })
            }
        case actionTypes.UPDATE_CARD: {
            let result = { ...state }
            if (state.selectedItem) {
                result = {
                    ...result,
                    selectedItem: {
                        ...state.selectedItem,
                        cards: state.selectedItem.cards.map(card => {
                            if (card.id === action.payload.id) {
                                return {
                                    ...card,
                                    label: action.payload.label,
                                    challenge: action.payload.challenge,
                                    modifier: action.payload.modifier,
                                }
                            }
                            return card
                        })
                    }
                }
            }
            if (state.cards) {
                result = {
                    ...result,
                    cards: state.cards.map(card => {
                        if (card.id === action.payload.id) {
                            return {
                                ...card,
                                label: action.payload.label,
                                challenge: action.payload.challenge,
                                modifier: action.payload.modifier,
                            }
                        }
                        return card
                    })
                }
            }
            return result
        }
        case actionTypes.CREATE_CARD:
            return {
                ...state,
                cards: [...state.cards, {
                    ...action.payload,
                    isSelected: true,
                }]

            }
        case actionTypes.ADD_CARD_TO_PRESET:
            if (state.selectedItem) {
                return {
                    ...state,
                    selectedItem: {
                        ...state.selectedItem,
                        cards: [...state.selectedItem.cards, action.payload]
                    },
                }
            }
            return state
        case actionTypes.DELETE_PRESET:
            if (state.selectedItem
                && state.selectedItem.id === action.payload) {
                return {
                    ...state,
                    selectedItem: null,
                    items: state.items.filter(d => d.id !== action.payload)
                }
            }
            return state
        default:
            return state;
    }
}

export default presetsReducer;